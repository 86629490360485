import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { apiCall } from "../utils";

// Customizable Area Start
interface BooksData {
  id?: string;
  type?: string;
  attributes?: {
    title?: number;
    description?: string;
    detail?: string;
    price?: number;
    written_by?: string;
    illustrated_by?: string;
    book_image_url?: string;
  };
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  books: BooksData[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ShopController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      books: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getBooksData();
  }

  async getBooksData() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };
    const res = await apiCall(
      configJSON.validationApiMethodType,
      configJSON.getBooksData,
      header
    );
    if (res) {
      this.setState({ books: res });
      return true;
    } else {
      return false;
    }
  }

  // Customizable Area End
}
