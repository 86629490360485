// Customizable Area Start
import React from "react";
import twemoji from "@twemoji/api";
import parse from "html-react-parser";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";
import moment from 'moment'
import 'moment-timezone'

import {
	CometChatMessageActions,
	CometChatThreadedMessageReplyCount,
	CometChatReadReceipt,
	CometChatLinkPreview,
} from "../";
import { CometChatMessageReactions } from "../Extensions";
import { Avatar } from "@material-ui/core"
import { CometChatContext } from "../../../util/CometChatContext";
import {
	linkify,
	checkMessageForExtensionsData,
	countEmojiOccurences,
} from "../../../util/common";
import * as enums from "../../../util/enums.js";

import Translator from "../../../resources/localization/translator";
import { theme } from "../../../resources/theme";

import {
	messageContainerStyle,
	messageWrapperStyle,
	messageTxtWrapperStyle,
	messageTxtStyle,
	messageInfoWrapperStyle,
	messageReactionsWrapperStyle,
} from "./style";
import { chatThumbnailStyle } from "../CometChatMessageHeader/style";
import UnpinModal from '../../../../../../UnpinModal.web'


class CometChatSenderTextMessageBubble extends React.Component {


	static contextType = CometChatContext;

	constructor(props) {
		super(props);

		this.messageTextRef = React.createRef();

		this.state = {
			translatedMessage: "",
			isHovering: false,
			enableLargerSizeEmojis: false,
			open: false,
			selectedMessage: null,
			unpinit: "pinIt",
		};
	}

	handleOpen = (message) => {
		this.setState({ open: true, selectedMessage: message });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handlePinning = () => {
		// Implement the pinning/unpinning logic here.
		// For example, you can call an API to update the pinned status of the selected message.
		// Once the operation is completed, close the modal.
		this.handleClose();
	};

	shouldComponentUpdate(nextProps, nextState) {
		const currentMessageStr = JSON.stringify(this.props.message);
		const nextMessageStr = JSON.stringify(nextProps.message);
		return (
			currentMessageStr !== nextMessageStr ||
			this.state.isHovering !== nextState.isHovering ||
			this.state.translatedMessage !== nextState.translatedMessage ||
			this.state.enableLargerSizeEmojis !== nextState.enableLargerSizeEmojis
		);
	}


	dateShow = (date) => {
		const indianTime = moment.unix(date).tz('Asia/Kolkata');
		const formattedDate = indianTime.format('Do MMM YYYY, h:mm A');
		return <div>{formattedDate}</div>;
	};

	componentDidMount() {
		this.enableLargerSizeEmojis();

	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.message !== this.props.message) {
			this.setState({ translatedMessage: "" });
		}

		this.enableLargerSizeEmojis();
	}

	getMessageText = () => {
		let messageText = this.props.message.text;

		//xss extensions data
		const xssData = checkMessageForExtensionsData(
			this.props.message,
			"xss-filter"
		);
		if (
			xssData &&
			xssData.hasOwnProperty("sanitized_text") &&
			xssData.hasOwnProperty("hasXSS") &&
			xssData.hasXSS === "yes"
		) {
			messageText = xssData.sanitized_text;
		}

		//datamasking extensions data
		const maskedData = checkMessageForExtensionsData(
			this.props.message,
			"data-masking"
		);
		if (
			maskedData &&
			maskedData.hasOwnProperty("data") &&
			maskedData.data.hasOwnProperty("sensitive_data") &&
			maskedData.data.hasOwnProperty("message_masked") &&
			maskedData.data.sensitive_data === "yes"
		) {
			messageText = maskedData.data.message_masked;
		}

		//profanity extensions data
		const profaneData = checkMessageForExtensionsData(
			this.props.message,
			"profanity-filter"
		);
		if (
			profaneData &&
			profaneData.hasOwnProperty("profanity") &&
			profaneData.hasOwnProperty("message_clean") &&
			profaneData.profanity === "yes"
		) {
			messageText = profaneData.message_clean;
		}

		const formattedText = linkify(messageText);

		const emojiParsedMessage = twemoji.parse(formattedText, {
			folder: "svg",
			ext: ".svg",
		});

		let count = countEmojiOccurences(emojiParsedMessage, 'class="emoji"');

		const parsedMessage = parse(emojiParsedMessage);

		let showVariation = true;
		//if larger size emojis feature is disabled
		if (this.state.enableLargerSizeEmojis === false) {
			showVariation = false;
		}

		messageText = (
			<div
				css={messageTxtWrapperStyle(this.context)}
				className='message__txt__wrapper'
			>
				<p
					css={messageTxtStyle(this.props, showVariation, count)}
					className='message__txt'
				>
					{parsedMessage}
					{this.state.translatedMessage}
				</p>
			</div>
		);

		return messageText;
	};

	translateMessage = (message) => {
		const messageId = message.id;
		const messageText = message.text;

		const browserLanguageCode = Translator.getBrowserLanguage().toLowerCase();
		let translateToLanguage = browserLanguageCode;
		if (browserLanguageCode.indexOf("-") !== -1) {
			const browserLanguageArray = browserLanguageCode.split("-");
			translateToLanguage = browserLanguageArray[0];
		}

		let translatedMessage = "";
		CometChat.callExtension("message-translation", "POST", "v2/translate", {
			msgId: messageId,
			text: messageText,
			languages: [translateToLanguage],
		})
			.then((result) => {
				if (
					result.hasOwnProperty("language_original") &&
					result["language_original"] !== translateToLanguage
				) {
					if (
						result.hasOwnProperty("translations") &&
						result.translations.length
					) {
						const messageTranslation = result.translations[0];
						if (messageTranslation.hasOwnProperty("message_translated")) {
							translatedMessage = `\n(${messageTranslation["message_translated"]})`;
						}
					} else {
						this.props.actionGenerated(
							enums.ACTIONS["ERROR"],
							[],
							"SOMETHING_WRONG"
						);
					}
				} else {
					this.props.actionGenerated(
						enums.ACTIONS["INFO"],
						[],
						"SAME_LANGUAGE_MESSAGE"
					);
				}

				this.setState({ translatedMessage: translatedMessage });
			})
			.catch((error) =>
				this.props.actionGenerated(
					enums.ACTIONS["ERROR"],
					[],
					"SOMETHING_WRONG"
				)
			);
	};

	enableLargerSizeEmojis = () => {
		this.context.FeatureRestriction.isLargerSizeEmojisEnabled()
			.then((response) => {
				if (response !== this.state.enableLargerSizeEmojis) {
					this.setState({ enableLargerSizeEmojis: response });
				}
			})
			.catch((error) => {
				if (this.state.enableLargerSizeEmojis !== false) {
					this.setState({ enableLargerSizeEmojis: false });
				}
			});
	};

	handleMouseHover = () => {
		this.setState(this.toggleHoverState);
	};

	toggleHoverState = (state) => {
		return {
			isHovering: !state.isHovering,
		};
	};

	 handleItemClick = (message) => {
		localStorage.setItem("currentPinMessage", JSON.stringify(message));
	  };

	actionHandler = (action, message) => {
		switch (action) {
			case enums.ACTIONS["REACT_TO_MESSAGE"]:
				this.props.actionGenerated(action, message);
				break;
			case enums.ACTIONS["VIEW_THREADED_MESSAGE"]:
				this.props.actionGenerated(action, message);
				break;
			case enums.ACTIONS["DELETE_MESSAGE"]:
				this.props.actionGenerated(action, message);
				break;
			case enums.ACTIONS["EDIT_MESSAGE"]:
				this.props.actionGenerated(action, message);
				break;
			case enums.ACTIONS["TRANSLATE_MESSAGE"]:
				this.translateMessage(message);
				break;
			default:
				break;
		}
	};

	render() {
		const { message } = this.props;
		const pinnedMessages = localStorage.getItem("pinMessages");
		const pinMessages = pinnedMessages ? JSON.parse(pinnedMessages) : null;
		const isPinned = pinMessages?.find((msg) => msg.data.id === message.id);
		let messageText = this.getMessageText();
		const someDate = this.props.message.sentAt
		const formattedDate = this.dateShow(someDate);




		//linkpreview extensions data
		const linkPreviewData = checkMessageForExtensionsData(
			this.props.message,
			"link-preview"
		);
		if (
			linkPreviewData &&
			linkPreviewData.hasOwnProperty("links") &&
			linkPreviewData["links"].length
		) {
			messageText = (
				<CometChatLinkPreview
					message={this.props.message}
					messageText={messageText}
				/>
			);
		}

		//messagereactions extensions data
		let messageReactions = null;
		const reactionsData = checkMessageForExtensionsData(
			this.props.message,
			"reactions"
		);
		if (reactionsData) {
			if (Object.keys(reactionsData).length) {
				messageReactions = (
					<div
						css={messageReactionsWrapperStyle()}
						className='message__reaction__wrapper'
					>
						<CometChatMessageReactions
							message={this.props.message}
							actionGenerated={this.props.actionGenerated}
						/>
					</div>
				);
			}
		}

		let toolTipView = null;
		if (this.state.isHovering) {
			toolTipView = (
				<CometChatMessageActions
					message={this.props.message}
					actionGenerated={this.actionHandler}
				/>
			);
		}
		const name = localStorage.getItem('userDetails')
		const userData = name ? JSON.parse(name) : null
		const adminCondition=  this.props.isAdmin? "A": this.props.message?.sender?.name.charAt(0)
		return (
			<div
				css={messageContainerStyle()}
				className='sender__message__container message__text'
				onMouseEnter={this.handleMouseHover}
				onMouseLeave={this.handleMouseHover}
				style={{ backgroundColor: "#FFF8EB", marginTop: "10px", width: '100%', display: 'block', justifyContent: 'flex-start' }}
			>
				<div style={{ display: "flex", justifyContent: "flex-end", position: "relative", top: "35px", right: "10px" }}>{toolTipView}</div>
				<div style={{ display: "flex", gap: "5px", alignItems: "center", marginTop: "10px" }}>
					<div css={chatThumbnailStyle()} className='chat__thumbnail' style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "white", fontSize: "22px", fontWeight: "bold" }}>
						<Avatar style={{ width: "35px", height: "35px", borderRadius: "50%", backgroundColor: "gray" }}>{userData?.attributes?.image_url && !this.props.isAdmin ? <img src={userData?.attributes?.image_url} /> : adminCondition}</Avatar>
					</div>
					<div style={{ color: "#4C4B48", fontWeight: "bold" }}>{<>{this.props.message?.sender?.name}</>}</div>
					<div style={{ color: "#808080", fontSize: "12px", marginLeft: "10px" }}>{formattedDate}</div>
					{isPinned ? <div onClick={() => {
						this.handleOpen(this.props.message)
						this.handleItemClick(this.props.message)
						}} style={{ background: "#1067B5", borderRadius: "3px", fontWeight: "bold", color: "white", fontSize: "12px", padding: "5px", marginLeft: "20px" }}>Pinned</div> : null}
					{this.state.open ? (
						<UnpinModal   open={this.state.open} onClose={this.handleClose} />
					) : null}
				</div>
				<div
					css={messageWrapperStyle()}
					className='message__wrapper'
					ref={this.messageTextRef}
				>
					<div style={{ position: "relative", left: "60px" }}>{messageText}</div>
				</div>

				{messageReactions}

				<div css={messageInfoWrapperStyle()} className='message__info__wrapper'>
					<CometChatThreadedMessageReplyCount
						message={this.props.message}
						actionGenerated={this.props.actionGenerated}
					/>
					<CometChatReadReceipt message={this.props.message} />
				</div>
			</div>
		);
	}
}

// Specifies the default values for props:
CometChatSenderTextMessageBubble.defaultProps = {
	theme: theme,
	actionGenerated: () => { },
};

CometChatSenderTextMessageBubble.propTypes = {
	theme: PropTypes.object,
	actionGenerated: PropTypes.func.isRequired,
	message: PropTypes.object.isRequired,
};

export { CometChatSenderTextMessageBubble };
// Customizable Area End