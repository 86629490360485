import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

let config = require("../../../../framework/src/config");
import { successfulMan } from '../assets';
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  usersData: any,
  submission: any
  course_feedback: any,
  trainerData: any
  trainer_feedback: any,
  validationError: boolean;
  selectedOption: any;
  selectCourse: any;
  success: boolean;
  trainerName: string;
  courseId: any;
  trainerId: any;
  optionError: boolean;
  feedbackdataData: any;
  message: string;
  placeholder: string;
  optionShow: boolean;
  optionvalue: string;
  resultres: any
  openModal: boolean;
  modalType: "success" | "warning" | "confirm" | "schedule";
  isLoading: boolean;
  statusCheck: string;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FeedBackController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  feedbackCallId: any = "";
  coursefeedbackApiCallId: any = "";
  eventsDataApiCallId: string = "";
  upcomingEventsApiCallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      isLoading: false,
      usersData: [],
      submission: [],
      course_feedback: [],
      trainerData: [],
      trainer_feedback: [],
      selectedOption: "",
      selectCourse: [],
      trainerName: "",
      success: false,
      courseId: [],
      trainerId: [],
      validationError: false,
      optionError: false,
      feedbackdataData: [],
      message: "",
      placeholder: "Please Select Course",
      optionShow: false,
      optionvalue: "",
      resultres: null,
      openModal: false,
      modalType: 'success',
      statusCheck: "",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getCourseFeedbackData();
  }

  handleClearDropDown = () => {
    this.setState({ selectedOption: "" },() => {
      window.location.reload();
    })
  }

  handleClose = () => {
    this.setState({ success: false });
    this.setState({ resultres: null });
  };

  handleCancel = () => {
    this.getCourseFeedbackData();
    this.setState({ trainer_feedback: [], selectedOption: "", trainerName: "", courseId: [], trainerId: [], resultres: null, validationError: false, course_feedback: [] })
  }

  handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.eventsDataApiCallId:
        if (responseJson) {
          this.setState({ usersData: responseJson });
          const courseSelect = this.state.usersData?.courses;
          const modifiedresponse = this.state.usersData?.feedback?.course_feedback?.map((d1: any) => ({ answer: null, ...d1 }))
          const trainerresponse = this.state.usersData?.feedback?.trainer_feedback?.map((d1: any) => ({ answer: null, ...d1 }))
          this.setState({ selectCourse: courseSelect, course_feedback: modifiedresponse, trainer_feedback: trainerresponse });
        }
        break;
      case this.upcomingEventsApiCallId:
        if (responseJson) {
          this.setState({course_feedback: [] , trainer_feedback: []},() => {
          this.setState({ trainerData: responseJson ,course_feedback: responseJson.feedback.course_feedback ,statusCheck : responseJson.status , trainer_feedback:responseJson.feedback.trainer_feedback}, () => {
            if (this.state.statusCheck === "submitted") {
              this.setState({openModal: true , modalType: configJSON.ModalTypes.Warning, message: configJSON.Feedback.FeedbackAlreadySubmitted});
            }
          });
          })
        }
        break;
      case this.coursefeedbackApiCallId:
        this.handleCancel();
        if (responseJson.message === configJSON.Feedback.ThankYouMessage) {
          return this.setState({ openModal: true, modalType: configJSON.ModalTypes.Success, message: configJSON.Feedback.SuccessSubmissionMsg, resultres: successfulMan })
        }
        else if (responseJson.message == configJSON.Feedback.CourseAlreadyTaken) {
          return this.setState({ openModal: true, modalType: configJSON.ModalTypes.Warning, message: configJSON.Feedback.FeedbackAlreadySubmitted })
        }
        else {
          this.setState({ openModal: false, modalType: configJSON.ModalTypes.Warning, message: configJSON.Feedback.SomethingWrong })
        }
        break;
    }
  }

  handleCloseModal = () => {
    this.setState({ openModal: false })
  }

  handleChangeCourse = (event: React.ChangeEvent<{ value: any, name: any }>) => {
    const value = event.target.value;
    const selectedObject = this.state.selectCourse.filter((element: any) => {
      return element.attributes.mind_mastery_course.id === value;
    })
    this.setState({ selectedOption: value, trainerName: selectedObject[0].attributes.mind_mastery_course.trainer_name, validationError: false });
    this.getCoursesubmission(selectedObject[0].attributes.mind_mastery_course.id)
  }

  handleValidation = () => {
    const { selectedOption } = this.state;
    if (selectedOption === '') {
      this.setState({ validationError: true });
      return false
    } else {
      this.setState({ validationError: false });
      return true
    }
  };

  getToken = () => {
    return localStorage.getItem('user_token')
  }

  goTo = (module: string, params: Object = {}) => {
    this.props.navigation.navigate(module, { ...params });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (!responseJson.errors) {
          this.handleApiResponse(apiRequestCallId, responseJson)
        } else {
          this.parseApiErrorResponse(responseJson)
        }
      }
      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse)
      }
    }
  }

  goBack = () => {
    this.props.navigation?.goBack();
  };

  async courseapicall(
    diffrentAPICallId: string,
    method: string,
    endpoint: string,
    headers: any,
    body?: any
  ) {
    let FullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;

    let apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    )

    apiResponseMessage.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      diffrentAPICallId
    )

    try {
      let response = await fetch(FullURL, {
        method: method.toUpperCase(),
        headers: headers,
        body: body
      });
      if (response.status === 401) {
        this.goTo("LoginForm");
      }
      let responseJson = await response.json();

      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      )
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      //setting Error
      console.log("Api Error" + JSON.stringify(error));
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "Please try again later."
      );
    }
    this.send(apiResponseMessage);
  }

  async getCourseFeedbackData() {
    const header = {
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.eventsDataApiCallId = apiRequest.messageId;
    await this.courseapicall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.Feedback.getCourseFeedbackDataEndPoint,
      header
    );
  }

  async getCoursesubmission(courseName: any) {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.upcomingEventsApiCallId = apiRequest.messageId;
    await this.courseapicall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `${configJSON.Feedback.getCoursesubmissionEndPoint}/?mind_mastery_course_id=${courseName}`,
      header
    );
  }

  handleAnswerChange = (id: any, answer: any) => {
    if(!this.state.selectedOption){
      alert("Please select course first")
      return;
    }
    if(this.state.statusCheck === "submitted"){
      alert("Already Submitted")
    }else{
    this.setState((prevState) => {
      const updatedCousr = prevState.course_feedback?.map((item: any) => {
        if (item.question_id === id) {
          return { ...item, answer };
        }
        return item;
      });
      return { course_feedback: updatedCousr };
    });
    const idToRemove = id;
    const updatedIds = this.state.courseId.filter((id: any) => id !== idToRemove);
    this.setState({ courseId: updatedIds })
    this.handleOptionValidation()
  }
  };

  handleOptionValidation = () => {
    const { course_feedback } = this.state;
    course_feedback?.forEach((fruit: any) => {
      if (fruit.answer == null || fruit.answer == "") {
        this.state.courseId?.push(fruit.question_id);
        this.setState({ optionError: true });
      } else {
        this.setState({ optionError: false });
      }
      return this.state.optionError
    });

  }

  handleTrainerChange = (id: any, answer: any) => {
    if(!this.state.selectedOption){
      alert("Please select course first")
      return;
    }
    if(this.state.statusCheck === "submitted"){
      alert("Already Submitted")
    }
    else{
    this.setState((prevState) => {
      const updatedCousr = prevState.trainer_feedback?.map((item: any) => {
        if (item.question_id === id) {
          return { ...item, answer };
        }
        return item;
      });
      return { trainer_feedback: updatedCousr };
    });
    const traineridToRemove = id;
    const TrainerupdatedIds = this.state.trainerId?.filter((id: any) => id !== traineridToRemove);
    this.setState({ trainerId: TrainerupdatedIds })
    this.handleTrainerValidation()
  }
  };

  handleTrainerValidation = () => {
    const { trainer_feedback } = this.state;
    trainer_feedback?.forEach((fruit: any) => {
      if (fruit.answer == null || fruit.answer == "") {
        this.state.trainerId?.push(fruit.question_id);
        this.setState({ optionError: true });
      } else {
        this.setState({ optionError: false });
      }
    });
  }

  handleSubmit = async (event: any) => {
    event.preventDefault();
    this.handleValidation();
    this.handleOptionValidation();
    this.handleTrainerValidation();
    if (this.state.courseId.length !== 0 || this.state.trainerId.length !== 0) {
      return false
    }
    else {
      const header = {
        "Content-Type": configJSON.programSchedulerContentType,
        Authorization: this.getToken()
      };
      const body = JSON.stringify({
        "mind_mastery_course_id": this.state.selectedOption,
        "feedback": {
          "course_feedback": this.state.course_feedback,
          "trainer_feedback": this.state.trainer_feedback
        }
      });
      const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.coursefeedbackApiCallId = apiRequest.messageId;

      await this.courseapicall(
        apiRequest.messageId,
        configJSON.dashboardPostApiMethod,
        configJSON.Feedback.StudentFeedbacksEndPoint,
        header,
        body
      );
    }
    return true
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
