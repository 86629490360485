import React from "react";
// Customizable Area Start
import MindMasteryController from "./MindMasteryController.web";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "./ListItem.web";
import PageContentLayout from "../PageContentLayout.web";
import SidebarLayout from "../SidebarLayout.web";
import Loader from "../../../../components/src/Loader.web";
import CommonPagination from "../../../../components/src/Pagination.web";
import InfoModal from "../../../../components/src/CommonInformationModal.web";
// Customizable Area End
const configJSON = require("../config.js");
// Customizable Area Start
const useStyles: any = makeStyles(() => ({
  pageContentWrapper: {
    padding: "32px 24px"
  }
}));

interface AttachmentListPageProps {
  pageCount: number;
  sessionId: any;
  courseId: any;
  goTo: (module: string, params?: Object) => void;
  page: number;
  handlePage: (page: number) => void;
  list: [{
    id: string;
    type: string;
    attributes: {
      title: string;
      trainer_name: string;
      uploaded_on: any;
      duration: string;
      attachment_type: string;
      thumbnail_url: string;
      attachment_url: string;
    }
  }]
}

export const AttachmentListPage = (props: AttachmentListPageProps) => {
  const classes = useStyles();
  const { list, pageCount } = props;
  return (
    <Box className={classes.pageContentWrapper} data-test-id='box' >
      {list?.map((element: any) => {
        const { attributes } = element;
        const listItemProps = {
          key: attributes?.title,
          courseId: props?.courseId,
          sessionId: props?.sessionId,
          goTo: props?.goTo,
          attributes: attributes,
          id: element?.id
        }
        return (<ListItem {...listItemProps} />)
      })}
      <CommonPagination page={props?.page} pageCount={pageCount} handlePage={props?.handlePage} />
    </Box>
  )
}
// Customizable Area End
export default class SessionAttachmentList extends MindMasteryController {
  // Customizable Area Start
  getAttachmentPageData = (page: number) => {
    const sessionId = Number(this.props.navigation.getParam("sessionId"));
    const courseId = Number(this.props.navigation.getParam("courseId"));
    const attachmentType = this.props.navigation.getParam("listType");
    this.getSessionAttachmentList(courseId, sessionId, attachmentType, page);
  };
  async componentDidMount(): Promise<void> {
    window.scrollTo(0, 0);
    this.getAttachmentPageData(this.state.page);
    const courseId = Number(this.props.navigation.getParam("courseId"));
    this.setState({courseId:courseId})
    this.handleAllPrePostDetails();
  }

  componentDidUpdate(prevProps: any, prevState: any): void {
    if (this.state.page !== prevState.page) {
      this.getAttachmentPageData(this.state.page);
    }
  }

  renderAttachmentType = (attachmentType: string) => {
    switch (attachmentType) {
      case configJSON.MindMasteryTexts.IceBreaker: return configJSON.MindMasteryTexts.Engage;
      case configJSON.MindMasteryTexts.WorkSheet: return configJSON.MindMasteryTexts.Ponder;
      default: return configJSON.MindMasteryTexts.Enhance;
    }
  }

  renderSubtitles = (attachmentType: string) => {
    switch (attachmentType) {
      case configJSON.MindMasteryTexts.IceBreaker: return configJSON.MindMasteryTexts.ReadMe;
      case configJSON.MindMasteryTexts.WorkSheet: return configJSON.MindMasteryTexts.WorkWithMe;
      default: return configJSON.MindMasteryTexts.WatchMe;
    }
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const sessionAttchmentList = this.state.sessionAttachmentPageData.data;
    const sessionId = Number(this.props.navigation.getParam(configJSON.MindMasteryTexts.SessionId))
    const courseId = Number(this.props.navigation.getParam(configJSON.MindMasteryTexts.CourseId))
    const attachmentType = this.props.navigation.getParam(configJSON.MindMasteryTexts.ListType)
    const pageCount = this.state.sessionAttachmentPageData.page_options?.total_pages

    const attachmentProps = {
      pageCount: pageCount,
      goTo: this.goTo,
      page: this.state.page,
      handlePage: this.handlePage,
      sessionId: sessionId,
      courseId: courseId,
      list: sessionAttchmentList
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <SidebarLayout {...this.props}>
        <PageContentLayout goBack={this.goBack} title={`${this.renderAttachmentType(attachmentType)} - ${sessionAttchmentList?.[0]?.attributes?.session_name}`} subtitle={this.renderSubtitles(attachmentType)} >
          {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
          <AttachmentListPage {...attachmentProps} />
          <InfoModal
            data-test-id='sucessModalTestId'
            info="Complete the pre program first, then you can start the course"
            modalType="warning"
            open={this.state.courseCompletion}
            closeModal={this.handleCloseCourseStatus} />
        </PageContentLayout>
      </SidebarLayout>
      // Customizable Area End
    );
  }
}
