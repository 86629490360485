import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";
import { apiCall } from "../../../../../../../../../../framework/src/apicall";

import * as enums from "../../../util/enums.js";
import { CometChatContext } from "../../../util/CometChatContext";

import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";

import {
	messageActionStyle,
	actionGroupStyle,
	groupButtonStyle,
} from "./style";

import pathIcon from "./resources/Path.png";
import pinIcon from "./resources/pin copy.png";
import reactIcon from "./resources/reactions.svg";
import translateIcon from "./resources/message-translate.svg";
import sendMessageInPrivateIcon from "./resources/send-message-in-private.svg";
import UnpinModal from '../../../../../../UnpinModal.web'

class CometChatMessageActions extends React.PureComponent {
	static contextType = CometChatContext;

	constructor(props, context) {
		super(props, context);

		this.state = {
			loggedInUser: null,
			enableMessageReaction: false,
			enableThreadedChats: false,
			enableDeleteMessage: false,
			enableEditMessage: false,
			enableTranslateMessage: false,
			enableMessageInPrivate: false,
			enableDeleteMessageForModerator: false,
			open: false,
			selectedMessage: null,
			unpinit: "pinIt",
		};
	}

	handleOpen = (message) => {
		this.setState({ open: true, selectedMessage: message });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handlePinning = () => {
		this.handleClose();
	};

	handleItemClick = (message) => {
		localStorage.setItem("currentPinMessage", JSON.stringify(message));
		console.log("currentPinMessage", message)
	  };


	componentDidMount() {
		this.context
			.getLoggedinUser()
			.then((user) => {
				this.setState({ loggedInUser: { ...user } });
			})
			.then(() => {
				this.enableMessageReaction();
				this.enableThreadedChats();
				this.enableDeleteMessage();
				this.enableDeleteMessageForModerator();
				this.enableEditMessage();
				this.enableTranslateMessage();
				this.enableMessageInPrivate();
			});
	}

	toggleTooltip = (event, flag) => {
		const elem = event.target;

		if (flag) {
			elem.setAttribute("title", elem.dataset.title);
		} else {
			elem.removeAttribute("title");
		}
	};

	enableMessageReaction = () => {
		/**
		 * If reacting to messages feature is disabled
		 */
		this.context.FeatureRestriction.isReactionsEnabled()
			.then((response) => {
				if (response === true) {
					this.setState({ enableMessageReaction: true });
				} else {
					this.setState({ enableMessageReaction: false });
				}
			})
			.catch((error) => {
				this.setState({ enableMessageReaction: false });
			});
	};

	enableThreadedChats = () => {
		/**
		 * If threaded chats are open, return false
		 */
		if (this.props.message.hasOwnProperty("parentMessageId") === true) {
			return false;
		}

		/**
		 * If threaded replies feature is disabled
		 */
		this.context.FeatureRestriction.isThreadedMessagesEnabled()
			.then((response) => {
				if (response === true) {
					this.setState({ enableThreadedChats: true });
				} else {
					this.setState({ enableThreadedChats: false });
				}
			})
			.catch((error) => {
				this.setState({ enableThreadedChats: false });
			});
	};

	enableDeleteMessage = () => {
		this.context.FeatureRestriction.isDeleteMessageEnabled()
			.then((response) => {
				if (response === true) {
					this.setState({ enableDeleteMessage: true });
				} else {
					this.setState({ enableDeleteMessage: false });
				}
			})
			.catch((error) => {
				this.setState({ enableDeleteMessage: false });
			});
	};

	enableDeleteMessageForModerator = () => {
		this.context.FeatureRestriction.isDeleteMemberMessageEnabled()
			.then((response) => {
				if (response === true) {
					this.setState({ enableDeleteMessageForModerator: true });
				} else {
					this.setState({ enableDeleteMessageForModerator: false });
				}
			})
			.catch((error) => {
				this.setState({ enableDeleteMessageForModerator: false });
			});
	};

	enableEditMessage = () => {
		/**
		 * If the message is not sent by the logged in user or the message type is not text
		 */
		if (
			this.props.message.sender?.uid !== this.state.loggedInUser?.uid ||
			this.props.message.type !== CometChat.MESSAGE_TYPE.TEXT
		) {
			return false;
		}

		this.context.FeatureRestriction.isEditMessageEnabled()
			.then((response) => {
				if (response === true) {
					this.setState({ enableEditMessage: true });
				} else {
					this.setState({ enableEditMessage: false });
				}
			})
			.catch((error) => {
				this.setState({ enableEditMessage: false });
			});
	};

	enableTranslateMessage = () => {
		/**
		 * message type is not text
		 */
		if (this.props.message.type !== CometChat.MESSAGE_TYPE.TEXT) {
			return false;
		}

		this.context.FeatureRestriction.isMessageTranslationEnabled()
			.then((response) => {
				if (response === true) {
					this.setState({ enableTranslateMessage: true });
				} else {
					this.setState({ enableTranslateMessage: false });
				}
			})
			.catch((error) => {
				this.setState({ enableTranslateMessage: false });
			});
	};

	/**
	 * If message in private feature is enabled
	 */
	enableMessageInPrivate = () => {
		this.context.FeatureRestriction.isMessageInPrivateEnabled()
			.then((response) => {
				if (response === true) {
					this.setState({ enableMessageInPrivate: true });
				} else {
					this.setState({ enableMessageInPrivate: false });
				}
			})
			.catch((error) => {
				this.setState({ enableMessageInPrivate: false });
			});
	};

	sendMessageInPrivate = () => {
		const item = this.props.message?.sender;
		const type = CometChat.ACTION_TYPE.TYPE_USER;

		this.context.setTypeAndItem(type, item);
	};

	reactToMessage = () => {
		this.props.actionGenerated(
			enums.ACTIONS["REACT_TO_MESSAGE"],
			this.props.message
		);
	};

	viewThread = () => {
		this.props.actionGenerated(
			enums.ACTIONS["VIEW_THREADED_MESSAGE"],
			this.props.message
		);
	};

	deleteMessage = () => {
		this.props.actionGenerated(
			enums.ACTIONS["DELETE_MESSAGE"],
			this.props.message
		);
	};


	getPinMessages=async()=>{

				let user_id=""

				let user_role = ""



				let search = window.location.search;

				
				let params = new URLSearchParams(search);
				
				let admin_id = params.get('admin_id');
				
				if(admin_id){
				
					user_id=admin_id.replace("admin_", "")
				
					user_role="super_admin"
				
				}
				
				else{
				
					user_id = JSON.parse(`${localStorage.getItem('userDetails')}`).id;
				
					user_role = localStorage.getItem('role') 
				
				}
				const header = {
				
					"Content-Type": 'application/json',
				
					Authorization: localStorage.getItem("user_token")
				};
				
				
				const res = await apiCall("get",`bx_block_chat/chat_pinned_messages/list_of_pinned_messages?user_id=${user_id}&role=${user_role}`,
				header
				);


				if (res) {
				
					localStorage.setItem("pinMessages", JSON.stringify(res.pinned_messages));
				
				} 
		}


	pinMessage = async () => {
		// alert("done")
		let user_id=""

		let role = ""
		
		let search = window.location.search;
		
		
		let params = new URLSearchParams(search);
		
		
		
		
		let admin_id = params.get('admin_id');
		
		
		if(admin_id){
		
		
			user_id=admin_id.replace("admin_", "")
		
			role="super_admin"
		}
		
		else{
		
		
			user_id = JSON.parse(`${localStorage.getItem('userDetails')}`).id;
		 
			role = localStorage.getItem('role') 
		}




		const messageId = this.props.message.id;
		const messageContent = this.props.message.text; 
		const senderName = this.props.message.sender?.name || ""; 
		const date = this.props.message.sentAt  || ""
		const avtar = this.props.message.sender?.avatar
		const fileUrl = this.props.message?.type === "file" ? this.props.message?.data?.attachments[0]?.url : null
		const fileName = this.props.message?.type === "file" ? this.props.message?.data.attachments[0]?.name : null
		const isPinned = false
		const pinnedMessage = {user_id,role, info: {id: messageId, content: messageContent, sender: senderName, date:date, avtar:avtar , isPinned:isPinned, fileName:fileName, fileUrl:fileUrl}};



		const header = {
			"Content-Type": 'application/json',
			Authorization: localStorage.getItem("user_token")
		  };
	  
		  const res = await apiCall(
			"post",
			"bx_block_chat/chat_pinned_messages/pin",
			header,
			JSON.stringify(pinnedMessage)
		  );
	  
		  if (res) {
			this.getPinMessages()
	
			return true;
		  } else {
			return false;
		  }

	  };

	editMessage = () => {
		this.props.actionGenerated(
			enums.ACTIONS["EDIT_MESSAGE"],
			this.props.message
		);
	};

	translateMessage = () => {
		this.props.actionGenerated(
			enums.ACTIONS["TRANSLATE_MESSAGE"],
			this.props.message
		);
	};

	render() {
		let reactToMessage = null;
		if (this.state.enableMessageReaction) {
			reactToMessage = (
				<li css={actionGroupStyle()} className='action__group'>
					<button
						type='button'
						onMouseEnter={(event) => this.toggleTooltip(event, true)}
						onMouseLeave={(event) => this.toggleTooltip(event, false)}
						css={groupButtonStyle(reactIcon, this.context)}
						className='group__button button__reacttomessage'
						data-title={Translator.translate(
							"ADD_REACTION",
							this.context.language
						)}
						onClick={this.reactToMessage}
					></button>
				</li>
			);
		}
	  
		let threadedChats = null;
		if (this.state.enableThreadedChats) {
			threadedChats = (
				<li css={actionGroupStyle()} className='action__group'>
					<img
						type='button'
						onMouseEnter={(event) => this.toggleTooltip(event, true)}
						onMouseLeave={(event) => this.toggleTooltip(event, false)}
						css={groupButtonStyle}
						src={pathIcon}
						className='group__button button__threadedchats'
						data-title={
							this.props.message.replyCount
								? Translator.translate("REPLY_TO_THREAD", this.context.language)
								: Translator.translate("REPLY_IN_THREAD", this.context.language)
						}
						onClick={this.viewThread}
					/>
				</li>
			);
		}
	  
		let pinMessage = null;
		// Condition for 'pinMessage' element
		{
			pinMessage = (
				<li css={actionGroupStyle()} className='action__group'>
					<img
						src={pinIcon}
						type='button'
						onMouseEnter={(event) => this.toggleTooltip(event, true)}
						onMouseLeave={(event) => this.toggleTooltip(event, false)}
						css={groupButtonStyle}
						className='group__button button__delete'
						data-title={Translator.translate('PIN_MESSAGE', this.context.language)}
						onClick={()=>
							{
								// this.pinMessage()
								this.handleOpen(this.props.message)
								this.handleItemClick(this.props.message)
							}}
					/>
					{this.state.open ? (
						<UnpinModal onPinIt={this.pinMessage}  open={this.state.open} onClose={this.handleClose} />
					) : null}
				</li>
			);
		}
	  
		let editMessage = null;
		 // Condition for 'editMessage' element
		{
		//   editMessage = (
		// 	// JSX code for the 'editMessage' element
		//   );
		}
	  
		let translateMessage = null;
		if (this.state.enableTranslateMessage) {
			translateMessage = (
				<li css={actionGroupStyle()} className='action__group'>
					<button
						type='button'
						onMouseEnter={(event) => this.toggleTooltip(event, true)}
						onMouseLeave={(event) => this.toggleTooltip(event, false)}
						css={groupButtonStyle(translateIcon, this.context)}
						className='group__button button__translate'
						data-title={Translator.translate(
							"TRANSLATE_MESSAGE",
							this.context.language
						)}
						onClick={this.translateMessage}
					></button>
				</li>
			);
		}
	  
		let messageInPrivate = null;
		// Condition for 'messageInPrivate' element
		if (
			this.state.enableMessageInPrivate === true &&
			this.context.type === CometChat.ACTION_TYPE.TYPE_GROUP &&
			this.props.message?.sender?.uid !== this.state.loggedInUser?.uid
		){
			messageInPrivate = (
				<li>
					<button
						type='button'
						onMouseEnter={(event) => this.toggleTooltip(event, true)}
						onMouseLeave={(event) => this.toggleTooltip(event, false)}
						css={groupButtonStyle(sendMessageInPrivateIcon, this.context)}
						className='group__button button__translate'
						data-title={Translator.translate(
							"SEND_MESSAGE_IN_PRIVATE",
							this.context.language
						)}
						onClick={this.sendMessageInPrivate}
					></button>
				</li>
			);
		}
	  
		let tooltip = (
			<ul
				css={messageActionStyle(
					this.props,
					this.context,
					this.state.loggedInUser
				)}
				className='message__actions'
			>
				{reactToMessage}
				{threadedChats}
				{editMessage}
				{pinMessage}
				{translateMessage}
			</ul>
		);

		if (
			threadedChats === null &&
			// deleteMessage === null &&
			pinMessage === null &&
			editMessage === null &&
			reactToMessage === null &&
			translateMessage === null &&
			messageInPrivate === null
		) {
			tooltip = null;
		}

		return tooltip;
	}
}

// Specifies the default values for props:
CometChatMessageActions.defaultProps = {
	theme: theme,
	actionGenerated: () => {},
};

CometChatMessageActions.propTypes = {
	theme: PropTypes.object.isRequired,
	actionGenerated: PropTypes.func.isRequired,
	message: PropTypes.object.isRequired,
};

export { CometChatMessageActions };
