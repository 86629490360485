import React from 'react'
// Customizable Area Start
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AnalysisReportController from "./AnalysisReportController.web";
import PageContentLayout from "../../PageContentLayout.web";
import SidebarLayout from "../../SidebarLayout.web";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CommonSelect from '../../../../../components/src/commonSelect.web';
import InfoModal from '../../../../../components/src/CommonInformationModal.web';

const configJSON = require("../../config.js");

// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles({
    label: {
        color: '#3B3B3B',
        fontSize: '20px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        marginBottom: '7px',
        '@media(max-width:600px)': {
            fontSize: "14px"
        }
    },
    required: {
        color: "red"
    },
    heading: {
        background: "#0056A3",
        borderRadius: "8px",
        fontSize: '18px',
        color: "#fff",
        fontWeight: "bold",
        padding: '11px 10px 11px 24px',
        marginBottom: '32px'
    },
    modalDialog: {
        "& div": {
            "& .MuiDialog-paperWidthSm": {
                width: "49.65vw !important"
            }
        }
    }
});

export const AddForm = (props: any) => {
    const classes = useStyles();

    const badgeDropdownList = props.mindId?.map((element: any) => {
        return {
            id: element,
            title: element,
            value: element
        }
    })
    const schooleDropdownList = props.schoolId?.map((element: any) => {
        return {
            id: element,
            title: element,
            value: element
        }
    })
    return (

        <Box p={4}>
            <Formik
                initialValues={{
                    programName: "",
                    schoolName: "",
                    academicYear: "",
                    Executive: "",
                    introduction: "",
                    keyConcerns: "",
                    focusAreas: "",
                    priority: "",
                    skillset: "",
                    Ahead01: "",
                    Ahead02: "",
                    Ahead03: "",
                    Ahead04: "",
                    Conclusion: "",
                    summary1: "",
                    summary2:"",
                    incidents:"",
                }}
                validationSchema={Yup.object().shape({
                    introduction: Yup.string().required(configJSON.AnalysisReport.IntroductionRequired)
                    .max(1270, "Introduction cannot exceed the word limit"),
                    keyConcerns: Yup.string().required(configJSON.AnalysisReport.KeyConcernsRequired)
                    .max(482, "Key Concern cannot be exceed the word limit"),
                    focusAreas: Yup.string().required(configJSON.AnalysisReport.FocusAreasRequired)
                    .max(650, "Focus area cannot be exceed the word limit"),
                    priority: Yup.string().required(configJSON.AnalysisReport.PriorityRequired)
                    .max(650, "Priority Module cannot be exceed the word limit"),
                    skillset: Yup.string().required(configJSON.AnalysisReport.SkillsetRequired)
                    .max(482, "Key Skillset cannot be exceed the word limit"),
                    Conclusion: Yup.string().required(configJSON.AnalysisReport.ConclusionRequired)
                    .max(1615, "Conclusion cannot be exceed the word limit"),
                    incidents: Yup.string().required(configJSON.AnalysisReport.keyIncidentRequired)
                    .max(482, "Key Incident cannot be exceed the word limit")
                })}
                data-test-id="formikDataTestId"
                onSubmit={(values) => {
                    props.OnCreate(values)
                }}
            >
                {({values,setFieldValue ,setFieldTouched}) => (
                <Form data-test-id="formTestId">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <CommonSelect
                                label={configJSON.AnalysisReport.ProgramNameLabel}
                                menuList={badgeDropdownList}
                                name={configJSON.AnalysisReport.ProgramName}
                                handleChange={props.handleFilterChange}
                                value={props.courseFilter}
                                placeholder={configJSON.AnalysisReport.EnterProgramName}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <CommonSelect
                                        placeholder={configJSON.AnalysisReport.EnterSchoolName}
                                        label={configJSON.AnalysisReport.SchoolNameLabel}
                                        menuList={schooleDropdownList}
                                        name={configJSON.AnalysisReport.SchoolName}
                                        handleChange={props.handleSchoolChange}
                                        value={props.schoolFilter}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CommonSelect
                                        menuList={props.academicList}
                                        placeholder={configJSON.TimelyProgress.EnterAcademicYear}
                                        label={`${configJSON.TimelyProgress.AcademicYear}*`}
                                        handleChange={props.handleAcademics}
                                        name={configJSON.AnalysisReport.AcademicYear}
                                        value={props.academics}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <label className={classes.label} htmlFor="introduction">
                                    {configJSON.AnalysisReport.IntroductionLabel}
                                </label>
                                <Field
                                    as={TextField}
                                    id="introduction"
                                    name={configJSON.AnalysisReport.Introduction}
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    fullWidth
                                    placeholder={configJSON.AnalysisReport.EnterIntroduction}
                                    value={values.introduction}
                                    data-test-id="formikItroductionTestId"
                                    onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handleIntroductionChange(event,setFieldValue,setFieldTouched)}
                                    helperText={
                                        <p className={classes.required}>
                                            <ErrorMessage 
                                            name={configJSON.AnalysisReport.Introduction} />
                                        </p>
                                    }
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Box mt={4}>
                        <Box className={classes.heading}>
                            <Typography >
                                {configJSON.AnalysisReport.analysis}
                            </Typography>
                        </Box>

                        <Grid 
                        container 
                        style={{ marginTop: "10px" }}>
                            <Grid 
                            item xs={12}>
                                <Box>
                                    <label 
                                    className={classes.label} 
                                    htmlFor="keyConcerns">
                                        {configJSON.AnalysisReport.KeyConcernsLabel}
                                    </label>
                                    <Field
                                        as={TextField}
                                        name={configJSON.AnalysisReport.KeyConcerns}
                                        id="keyConcerns"
                                        multiline
                                        variant="outlined"
                                        rows={2}
                                        placeholder={configJSON.AnalysisReport.EnterKeyConcerns}
                                        fullWidth
                                        data-test-id="formikKeyConcernTestId"
                                        value={values.keyConcerns}
                                        onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handleKeyConcernChange(event,setFieldValue,setFieldTouched)}
                                        helperText={
                                            <p 
                                            className={classes.required}>
                                                <ErrorMessage 
                                                name={configJSON.AnalysisReport.KeyConcerns} />
                                            </p>
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <label 
                                    htmlFor="incidents"
                                    className={classes.label} >
                                        {configJSON.AnalysisReport.keyIncidentLable}
                                    </label>
                                        <Field
                                            id="incidents"
                                            as={TextField}
                                            name={configJSON.AnalysisReport.incident}
                                            variant="outlined"
                                            rows={2}
                                            multiline
                                            value={values.incidents}
                                            data-test-id="incidentTestId"
                                            onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handleKeyIncidentChange(event,setFieldValue,setFieldTouched)}
                                            helperText={
                                                <p 
                                                className={classes.required}>
                                                    <ErrorMessage 
                                                    name={configJSON.AnalysisReport.incident}
                                                    />
                                                </p>
                                            }
                                            fullWidth
                                            placeholder={configJSON.AnalysisReport.enterKeyIncident}
                                        />
                                </Box>
                        </Grid>
                            <Grid
                             item 
                             xs={12}>
                                <Box>
                                    <label 
                                    className={classes.label} 
                                    htmlFor="skillset"
                                    >
                                        {configJSON.AnalysisReport.SkillsetNeededLabel}
                                    </label>
                                    <Field
                                        as={TextField}
                                        id="skillset"
                                        data-test-id="skillsetTestId"
                                        variant="outlined"
                                        fullWidth
                                        rows={2}
                                        placeholder={configJSON.AnalysisReport.EnterSkillsetNeeded}
                                        value={values.skillset}
                                        onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handleSkilletChange(event,setFieldValue,setFieldTouched)}
                                        helperText={
                                            <p className={classes.required}>
                                                <ErrorMessage name={configJSON.AnalysisReport.Skillset} />
                                            </p>
                                        }
                                        name={configJSON.AnalysisReport.Skillset}
                                        multiline
                                        />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mt={4}>
                        <Box
                            className={classes.heading}>
                            <Typography >
                                {configJSON.AnalysisReport.RecommendationsAndRequirements}
                            </Typography>
                        </Box>
                        <Grid item xs={12}>
                            <Box>
                                <label className={classes.label}
                                    htmlFor="focusAreas">
                                    {configJSON.AnalysisReport.FocusAreasLabel}
                                </label>
                                <Field
                                    as={TextField}
                                    name={configJSON.AnalysisReport.FocusAreas}
                                    id="focusAreas"
                                    variant="outlined"
                                    rows={2}
                                    multiline
                                    data-test-id="focusAreaTestId"
                                    value={values.focusAreas}
                                    onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handleFocusAreaChange(event,setFieldValue,setFieldTouched)}
                                    helperText={
                                        <p className={classes.required}>
                                            <ErrorMessage name={configJSON.AnalysisReport.FocusAreas} />
                                        </p>
                                    }
                                    placeholder={configJSON.AnalysisReport.EnterFocusAreas}
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <label
                                    className={classes.label}
                                    htmlFor="priority">
                                    {configJSON.AnalysisReport.PriorityModulesLabel}
                                </label>
                                <Field
                                    name={configJSON.AnalysisReport.Priority}
                                    as={TextField}
                                    id="priority"
                                    variant="outlined"
                                    multiline
                                    fullWidth
                                    rows={2}
                                    data-test-id="priorityTestId"
                                    value={values.priority}
                                    onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handlePriorityModueChange(event,setFieldValue,setFieldTouched)}
                                    helperText={
                                        <p className={classes.required}>
                                            <ErrorMessage name={configJSON.AnalysisReport.Priority} />
                                        </p>
                                    }
                                    placeholder={configJSON.AnalysisReport.EnterPriorityModules}
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <Box mt={4}>
                        <Box 
                        className={classes.heading}>
                            <Typography> {configJSON.AnalysisReport.Conclusion}</Typography>
                        </Box>
                        <Box mt={4}>
                            <Field
                                as={TextField}
                                id="Conclusion"
                                name={configJSON.AnalysisReport.Conclusion}
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                placeholder={configJSON.AnalysisReport.EnterText}
                                value={values.Conclusion}
                                onChange = {(event:React.ChangeEvent<HTMLInputElement>) => props.handleConclusionChange(event,setFieldValue,setFieldTouched)}
                                helperText={
                                    <p className={classes.required}>
                                        <ErrorMessage name={configJSON.AnalysisReport.Conclusion} />
                                    </p>
                                }
                            />
                        </Box>
                    </Box>
                    <Box
                        mt={4}
                        display={"flex"}
                        style={{ gap: "10px", textAlign: "center" }}
                        justifyContent={"end"}
                    >
                        <Button data-test-id="createbtn" type="submit" style={webStyle.submit} size="large">
                            Create
                        </Button>
                    </Box>
                </Form>
                 )}
            </Formik>
            <InfoModal
                modalType={configJSON.ModalTypes.Success}
                open={props.createsuccess}
                closeModal={props.handlesuccesClose}
                title={configJSON.AnalysisReport.ThankYou}
                info={configJSON.AnalysisReport.SuccessfulSubmissionMsg}
            />
        </Box>
    )
}
// Customizable Area End

export default class AddReaderForm extends AnalysisReportController {
    render() {
        // Customizable Area Start
        return <>
            <SidebarLayout {...this.props}>
                <PageContentLayout goBack={this.goBack} title={configJSON.AnalysisReport.NeedAnalysisReport}>
                    <AddForm OnCreate={this.OnCreate}
                        data-test-id = "addFormTestId"
                        handlesuccesClose={this.handlesuccesClose}
                        createsuccess={this.state.createsuccess}
                        mindId={this.state.mindId}
                        courseFilter={this.state.selectedCourse.name}
                        handleFilterChange={this.handleInputChange}
                        handleSchoolChange={this.handleSchoolInputChange}
                        schoolId={this.state.schoolId}
                        schoolFilter={this.state.selectedSchool.name}
                        handleAcademics = {this.handleAcademicYear}
                        academicList={this.state.academicYearList}
                        academics = {this.state.academicYear}
                        handleIntroductionChange = {this.handleIntroduction}
                        handleSkilletChange = {this.handleSkilledNeeded}
                        handleKeyIncidentChange = {this.handleKeyIncident}
                        handleKeyConcernChange = {this.handleKeyConcern}
                        handlePriorityModueChange = {this.handlePriorityModule}
                        handleFocusAreaChange = {this.handleFocusArea}
                        handleConclusionChange = {this.handleConclusion}
                    />
                </PageContentLayout>
            </SidebarLayout>
        </>
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    submit: {
        width: "150px",
        background: "#0056A3",
        color: "#fff",
        height: '48px',
        borderRadius: '8px',
        textTransform: "capitalize"
    } as React.CSSProperties,
    diaTitle: {
        color: "#0056A3",
        textAlign: "center",
        fontWeight: 700,
        fontSize: "2.222vw",
        marginTop: "1vw",
        marginBottom: "1vw",
    } as React.CSSProperties,
    diaContent: {
        marginTop: "20px",
        textAlign: "center",
        fontWeight: 600,
        width: "36.181vw",
        fontSize: "1.667vw",
    } as React.CSSProperties,
}
// Customizable Area End
