let config = require("../../../framework/src/config");

export const apiCall = async (
  httpMenthod: string,
  url: string,
  headers: any
) => {
  let fullURL = url.indexOf("://") === -1 ? config.baseURL + "/" + url : url;
  let res: any;
  try {
    if (headers) {
      res = await fetch(fullURL, {
        method: httpMenthod.toUpperCase(),
        headers: headers
      });
    }
    let jsonData = await res.json();
    if (res.status === 200) {
      if(jsonData?.data){
        return jsonData.data;
      }else{
        return jsonData;
      }
    
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};
