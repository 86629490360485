export const backArrow = require("../assets/backArrow.png");
export const cardMediaImg = require("../assets/cardMediaImg.png");
export const speedometerExpert = require("../assets/speedometerExpert.png");
export const speedometerInter = require("../assets/speedometerInter.png");
export const speedometerBeginner = require("../assets/speedometerBeginner.png");
export const courseEducator = require("../assets/courseEducator.png");
export const dustbin = require("../assets/delete.png");
export const rating = require("../assets/rating.png");
export const bullet = require("../assets/bulletPoint.png");
export const clockIcon = require("../assets/clock.png");
export const calendarIcon = require("../assets/calendarIcon.png");
export const videosIcon = require("../assets/videos.png");
export const workSheetIcon = require("../assets/document.png");
export const documentsIcon = require("../assets/documents.png");
export const nextVideo = require("../assets/nextVideo.png");
export const arrowRight = require("../assets/arrowRight.png");
export const paper = require("../assets/Paper.png");
export const cross = require("../assets/cross.png");
export const correct = require("../assets/correct.png");
export const correctChecked = require("../assets/correctChecked.png");
export const crossChecked = require("../assets/crossChecked.png");
export const attachmentClip = require("../assets/Attachment.png");
export const mainLogo = require("../assets/mainlogo.png");
export const dashBoard = require("../assets/dashboard.png");
export const dashBoardActive = require("../assets/DashboardActive.png");
export const calendar = require("../assets/calendar.png");
export const calendarActive = require("../assets/CalendarActive.png");
export const mPowerCheck = require("../assets/mPowerCheck.png");
export const mPowerCheckActive = require("../assets/mPowerCheckActive.png");
export const mindMastery = require("../assets/mindMastery.png");
export const mindMasteryActive = require("../assets/mindMasteryActive.png");
export const youMatter = require("../assets/youMatter.png");
export const youMatterActive = require("../assets/youMatterActive.png");
export const seekHelp = require("../assets/seekHelp.png");
export const seekHelpActive = require("../assets/seekHelpActive.png");
export const feedBack = require("../assets/feedback.png");
export const feedBackActive = require("../assets/ratingsActive.png");
export const topBarLeft = require("../assets/topBarLeft.png");
export const topBarRight = require("../assets/topBarRight.png");
export const playIcon = require("../assets/play.png");
export const playWhiteIcon = require("../assets/play-white.png");
export const worksheetIcon = require("../assets/worksheet.png");
export const logo = require("../assets/mainlogo.png");
export const profile = require("../assets/profile.svg");
export const medal = require("../assets/medal.svg");
export const playMpower = require("../assets/playMpower.svg");
export const document = require("../assets/Document.svg");
export const closeFile = require("../assets/closeFile.png");
export const uploadIcon = require("../assets/upload-arrow.png");
export const imagePlaceholder = require("../assets/photo.png");
export const successfulMan = require("../assets/successfulMan.svg");
export const warning = require("../assets/warning.png");
export const submitWarning = require("../assets/submitDocument.png");
export const arrowLeft = require("../assets/arrowLeft.png");
export const arrowDown = require("../assets/arrow-down.png");
export const showIcon = require("../assets/show.png");
export const strokeIcon = require("../assets/stroke.png");
export const locationIcon = require("../assets/location.png");
export const twoUserIcon = require("../assets/two-user.png");
export const darkArrowDown = require('../assets/dark-arrow-down.png')
export const calendarDark = require('../assets/calendar-dark.png')
export const addFields = require('../assets/addFields.png')
export const calendarArrowRight = require("../assets/calendarArrowRight.png");
export const notesIcon = require("../assets/notes.png");
export const removeQuestionIcon = require('../assets/removeQuestionIcon.png')
export const blueDownArrow = require('../assets/blueDownArrow.png')
export const blueCheckboxIcon = require('../assets/blueCheckbox.png')
export const blueCheckboxCheckedIcon = require('../assets/blueCheckboxChecked.png')
export const scheduleImg = require('../assets/imageSchedule.png')
export const scheduling = require('../assets/scheduling.svg');
export const trophyIcon = require("../assets/my-progress.svg");
export const rightArrowIcon = require("../assets/RightArrowTail.png");
export const imageMonitor = require('../assets/imageMonitor.png')
export const imageMonitorActive = require('../assets/imageMonitorActive.png')
export const trueIcon = require('../assets/trueIcon.png')
export const falseIcon = require('../assets/falseIcon.png')
export const superHeroIcon = require('../assets/superHeroIcon.png')
export const legendIcon = require('../assets/legendIcon.png')
export const noviceHeroIcon = require('../assets/noviceHeroIcon.png')
export const emergingHeroIcon = require('../assets/emergingHeroIcon.png')
export const risingHeroIcon = require('../assets/risingHeroIcon.png')
export const aspiringHeroIcon = require('../assets/aspiringHeroIcon.png')
export const documentIcon = require("../assets/document_icon.png");
export const editIcon = require("../assets/edit_icon.svg");
export const avatar1 = require("../assets/avatar1.png");
export const avatar2 = require("../assets/avatar2.png");
export const avatar3 = require("../assets/avatar3.png");
export const avatar4 = require("../assets/avatar4.png");
export const avatar5 = require("../assets/avatar5.png");
export const hourglass = require("../assets/hourglass.png");
export const Bitmap = require("../assets/Bitmap.png");
export const service = require("../assets/service.png");
export const RightArrow = require("../assets/RightArrow.png");
export const LeftArrow = require("../assets/LeftArrow.png");
export const darkClockIcon = require('../assets/darkClockIcon.png')
export const editPenBlue = require('../assets/editPenBlue.png')
export const editPenGrey = require('../assets/editPenGrey.png')
export const sheetBlue = require('../assets/sheetBlue.png')
export const sheetGrey = require('../assets/sheetGrey.png')
export const whiteDropdownIcon = require('../assets/whiteDownArrow.png')
export const deleteIcon = require("../assets/deleteIcon.png");
export const deleteAlertIcon = require("../assets/deleteAlertIcon.png");
export const editPencilIcon = require("../assets/editPencilIcon.png");
export const removeFieldsIcon = require("../assets/removeFields.png");
export const WorkProgress = require("../assets/work-in-progress.png")
export const guide = require('../assets/guide.png')
export const sandClockIcon = require('../assets/hourglass.png')
export const guideActive = require('../assets/guideActive.png')
export const impactReportIcon = require('../assets/impactReport.png')
export const impactReportActiveIcon = require('../assets/impactReportActive.png')
export const studentAnalysisIcon = require('../assets/studentAnalysis.png')
export const studentAnalysisActiveIcon = require('../assets/studentAnalysisActive.png')
export const trainerCloseIcon = require("../assets/trainerClose.png");
export const editButtonImage  = require("../assets/editButtonImage.png");
export const selectedIcon = require("../assets/selectedIcon.png");
export const unSelectedIcon = require("../assets/unSelectedIcon.png");
export const FilledStarIcon = require("../assets/FilledStarIcon.png");
export const EmptyStarIcon = require("../assets/EmptyStarIcon.png");
export const PublishedAlert = require("../assets/PublishedAlert.png");
export const PublishedSuccess = require("../assets/PublishedSuccess.png");
export const pdfAvaialbleIcon = require("../assets/pdfAvaialbleIcon.png");
export const pdfNotAvailabeIcon = require("../assets/pdfNotAvailabeIcon.png");
export const mpowerIcon = require("../assets/MpowerIcon.png");
export const Need_report = require("../assets/image_Business-report.png");
export const Programe_report = require("../assets/image_Annual-report.png");
export const student_report = require("../assets/image_Report-card.png");
export const time_report = require("../assets/image_Stats.png");
export const reportFileIcon = require("../assets/reportIcon.png");
export const collaborationIcon = require("../assets/collaborationIcon.png");
export const creativityIcon = require("../assets/creativityIcon.png");
export const criticalThinkingIcon = require("../assets/criticalThinkingIcon.png");
export const empathyIcon = require("../assets/empathyIcon.png");
export const initiativeIcon = require("../assets/initiativeIcon.png");
export const participationIcon = require("../assets/participationIcon.png");
export const perseveranceIcon = require("../assets/perseveranceIcon.png");
export const positiveAttitudeIcon = require("../assets/positiveAttitudeIcon.png");
export const respectIcon = require("../assets/respectIcon.png");
export const focusIcon = require("../assets/focusIcon.png");
export const addImpactStoryBtn = require("../assets/addImpactStoryBtn.png");
export const emptyStarRatingIcon = require("../assets/emptyStarRatingIcon.png");
export const filledStarRatingIcon = require("../assets/filledStarRatingIcon.png");
export const pinIcon = require("../assets/pin.png")
export const rightScrollIcon = require("../assets/rightScrollIcon.png");
export const leftScrollIcon = require("../assets/leftScrollIcon.png");
export const headerIcon = require("../assets/header.svg");
export const hapchiMainLogo = require("../assets/hapchiMainLogo.svg");
export const Ponder = require('../assets/Ponder.svg');
export const Engage = require('../assets/Engage.svg');
export const Enhance = require('../assets/Enhance.svg');
export const YouMatterConfirm = require("../assets/you-matter-submit-confirm.png");
export const HapSpark = require("../assets/Hap-spark.png");
export const HapBeam = require("../assets/Hap-beam.png");
export const HapStar = require("../assets/Hap-star.png");
export const ProgressStar = require("../assets/star.png");
export const RewardsCollected = require("../assets/Rewards-collected.png");