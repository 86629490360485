Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DragDropInterface";
exports.labelBodyText = "DragDropInterface Body";

exports.btnExampleTitle = "CLICK ME";

exports.optionAlphabets = {
  A:'A',
  B:'B',
  C:'C',
  D:'D',
  E:'E',
  F:'F'
}

exports.youMatterInfoTexts = {
  dragAndDropOptions: 'Drag & Drop Options',
  dragAndDropBucketInfoText: 'Drag and drop your answers in the below boxes'
}
// Customizable Area End