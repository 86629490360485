// Customizable Area Start
import React from "react";
import DateCalendar from "react-calendar";
import { calendarArrowRight } from "../assets";
import { Value } from "react-calendar/dist/cjs/shared/types";
// Customizable Area End

// Customizable Area Start
export interface CalendarCardProps {
  currentDate: Date;
  handleDateChange: (newDate: Date) => void
}
// Customizable Area End

export const CustomPrevIcon = () => (
  // Customizable Area Start
  <img
    style={{ transform: "scaleX(-1)" }}
    src={calendarArrowRight}
    alt="Prev Icon"
  />
  // Customizable Area End
);

// Customizable Area Start
// Customizable Area End

export const CustomNextIcon = () => (
  // Customizable Area Start
  <img src={calendarArrowRight} alt="Next Icon" />
  // Customizable Area End
);
// Customizable Area Start
const dayFormat = ["S", "M", "T", "W", "T", "F", "S"];
// Customizable Area End

// Customizable Area Start
export const CalendarCard = (props: CalendarCardProps) => {
  return (
    <DateCalendar
      data-test-id="date-calendar"
      prevLabel={<CustomPrevIcon />}
      nextLabel={<CustomNextIcon />}
      formatShortWeekday={(_locale: any, date: Date) =>
        dayFormat[date.getDay()]
      }
      value={props.currentDate}
      onChange={(newDate: Value) => props.handleDateChange(newDate as unknown as Date)}
    />
  );
};
// Customizable Area End

// Customizable Area Start
// Customizable Area End
