import React from "react";
// Customizable Area Start
import FeedBackController from "./FeedbackController.web";
import {
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Radio,
  InputLabel,
} from "@material-ui/core";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import StarIcon from "@material-ui/icons/Star";
import PageContentLayout from "../PageContentLayout.web";
import SidebarLayout from "../SidebarLayout.web";
import Loader from "../../../../components/src/Loader.web";
import CommonTextField from "../../../../components/src/commonTextField.web";
import CommonSelect from "../../../../components/src/commonSelect.web";
import commonStyles from "../../../../components/src/commonStyle.web";
import { makeStyles } from "@material-ui/core/styles";
import InfoModal from "../../../../components/src/CommonInformationModal.web";
// Customizable Area End
const configJSON = require("../config.js");

const useStyles = makeStyles(() => ({
  // Customizable Area Start
  ratingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    '@media(max-width:767px)': {
      flexDirection: 'column',
      width: 'fit-content'
    }
  }
  // Customizable Area End
}))
// Customizable Area Start
// Customizable Area End
export default class FeedBack extends FeedBackController {
  // Customizable Area Start
  // Customizable Area End

  
  render() {
    // Customizable Area Start
    return (
      <SidebarLayout {...this.props}>
        <PageContentLayout title={configJSON.Feedback.ReviewAndFeedback}>
          {this.state.isLoading ? (
            <Loader loading={this.state.isLoading} />
          ) : null}
          <FeedBackPage
            data-test-id='feedback-page'
            state={this.state}
            handleTrainerChange={this.handleTrainerChange}
            handleChangeCourse={this.handleChangeCourse}
            handleAnswerChange={this.handleAnswerChange}
            handleSubmit={this.handleSubmit}
            handleCancel={this.handleCancel}
            handleClearDropDown={this.handleClearDropDown}
          />
          <InfoModal title={this.state.modalType === configJSON.Feedback.Success ? configJSON.Feedback.Thankyou : configJSON.Feedback.Alert} open={this.state.openModal} closeModal={this.handleCloseModal} info={this.state.message} modalType={this.state.modalType} />
        </PageContentLayout>
      </SidebarLayout>
    );
    // Customizable Area End
  }
}
// Customizable Area Start

const FeedBackPage = (props: any) => {
  // Customizable Area Start
  const commonClasses = commonStyles({});
  const formattedCourseList = props.state.selectCourse.map((element: any) => {
    return {
      id: element.attributes.mind_mastery_course.id,
      title: element.attributes.mind_mastery_course.title,
      value: element.attributes.mind_mastery_course.id
    }
  })
  return <>
    <Box style={webStyle.mindMasteryPageContent}>
      <Box>
        <Typography className={commonClasses.mainTitle}>
          {configJSON.Feedback.Feedback}
        </Typography>
        <Typography variant="body2" style={webStyle.description}>
          {configJSON.Feedback.PlsProvideFeedback}
        </Typography>
      </Box>
      <Box mt={4}>
        <form onSubmit={props.handleSubmit}>
          <Grid container spacing={2} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} sm={6}>
              <CommonTextField
                labelFontSize={'18px'}
                mb={'0px'}
                placeholder={configJSON.Feedback.EnterYourName}
                name={configJSON.Feedback.FirstName}
                label={configJSON.Feedback.FirstNameLabel}
                value={props.state.usersData?.user_details?.first_name}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CommonTextField
                labelFontSize={'18px'}
                mb={'0px'}
                placeholder={configJSON.Feedback.EnterYourLastName}
                name={configJSON.Feedback.LastName}
                label={configJSON.Feedback.LastNameLabel}
                value={props.state.usersData?.user_details?.last_name}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CommonSelect
                data-test-id='course'
                mb={'24px'}
                labelFontSize={'18px'}
                placeholder={configJSON.Feedback.SelectCourse}
                label={configJSON.Feedback.CourseNameLabel}
                error={!!props.state.validationError}
                onClear={props.handleClearDropDown}
                value={props.state.selectedOption}
                menuList={formattedCourseList}
                handleChange={props.handleChangeCourse}
              />
              {props.state.validationError && (
                <Typography variant="body2" style={{ color: "red", fontSize: "12px", marginTop: '10px' }}>
                  {configJSON.Feedback.PlsSelectCourse}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <CommonTextField
                mb={'0px'}
                labelFontSize={'18px'}
                placeholder={configJSON.Feedback.TrainerNamePh}
                name={configJSON.Feedback.TrainerName}
                label={configJSON.Feedback.TrainerNameLabel}
                value={props.state.trainerName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CommonTextField
                mb={'0px'}
                labelFontSize={'18px'}
                placeholder={configJSON.Feedback.EnterEmailID}
                name={configJSON.Feedback.Email}
                label={configJSON.Feedback.EmailIDLabel}
                value={props.state.usersData?.user_details?.email}
                disabled
              />
            </Grid>
          </Grid>
          <Box mb={4} data-test-id="CourseRatingHeadingWrapper">
            <Typography className={commonClasses.mainTitle}>
              {configJSON.Feedback.CourseFeedback}
            </Typography>
            <Typography variant="body2" style={webStyle.description}>
              {configJSON.Feedback.PlsProvideFeedback}
            </Typography>
          </Box>
          <CourseFeedback
            isLoading={props.state.isLoading}
            course_feedback={props.state.course_feedback}
            handleAnswerChange={props.handleAnswerChange}
            optionError={props.state.optionError}
            courseId={props.state.courseId}
          />
          <TrainerFeedback
            isLoading={props.state.isLoading}
            trainer_feedback={props.state.trainer_feedback}
            handleTrainerChange={props.handleTrainerChange}
            optionError={props.state.optionError}
            trainerId={props.state.trainerId}
          />
          <Box display={"flex"} flexDirection={"row"} justifyContent={"end"} mt={4} style={{ gap: "20px" }}>
            <Button className={commonClasses.secondaryButton} onClick={props.handleCancel} data-test-id="cancelbtn">{configJSON.ButtonTexts.Cancel}</Button>
            <Button type="submit" data-test-id="submitbtn" className={commonClasses.primaryButton}>{configJSON.ButtonTexts.Submit}</Button>
          </Box>
        </form>
      </Box>
    </Box>
  </>
  // Customizable Area End
}
// Customizable Area End

// Customizable Area Start
export const SelectCard = (props: any) => {
  // Customizable Area Start
  return (
    <FormControl style={{ width: "100%" }} variant="outlined">
      <InputLabel id="demo-simple-select-outlined-label">{configJSON.Feedback.SelectCourse}</InputLabel>
      <Select
        data-test-id="selectcourse"
        error={props.validationError}
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={props.selectedOption}
        onChange={(e: any) => {
          props.handleOptionChange(e);
        }}
        displayEmpty
      >
        {props.selectCourse?.map((item: any, index: number) => {
          let check = item?.attributes?.mind_mastery_course
            ? item?.attributes?.mind_mastery_course?.title
            : item?.attributes?.title;
          return (
            <MenuItem value={item?.attributes?.mind_mastery_course || item?.attributes} key={index}>
              <Box display={"flex"} justifyContent={"space-between"} style={{ width: "100%" }} key={props.key}>
                {check}
                {window.location.pathname !== configJSON.Feedback.FeedbackReportPath && (
                  <Radio
                    key={props.key}
                    checked={
                      props.selectedOption ===
                      item?.attributes?.mind_mastery_course?.title
                    }
                  />
                )}
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
  // Customizable Area End
};
// Customizable Area End

// Customizable Area Start
export const RatingCard = (props: any) => {
  // Customizable Area Start
  const type = props.question_type;
  let unSelectedIcon = <StarBorderIcon style={{ fontSize: "30px", cursor: 'pointer' }} />;
  let selectedIcon = <StarIcon style={{ fontSize: "30px", cursor: 'pointer' }} />
  let wrapperStyle = webStyle.startRating;
  if (type === configJSON.Feedback.TypeEmojiRating) {
    selectedIcon = <SentimentSatisfiedAltIcon style={{ fontSize: "28px", cursor: 'pointer' }} />;
    unSelectedIcon = <SentimentSatisfiedIcon style={{ fontSize: "28px", cursor: 'pointer' }} />;
    wrapperStyle = webStyle.emojiRating;
  }
  return <Box data-test-id='box'>
    <Box style={wrapperStyle}>
      {[1, 2, 3, 4, 5].map((rating) => (
        <span key={props.question_id} data-test-id="courserating" onClick={() => props.handleRating(props.question_id, rating)}
          style={{
            color: rating <= props.answer ? "gold" : "grey",
          }}
        >
          {rating <= props.answer ? (selectedIcon) : (unSelectedIcon)}
        </span>
      ))}
    </Box>
    <Box display={"flex"} justifyContent={"space-between"}>
      <Typography style={{ fontSize: "14px" }}>{configJSON.Feedback.OK}</Typography>
      <Typography style={{ fontSize: "14px" }}>{configJSON.Feedback.Awesome}</Typography>
    </Box>
  </Box>
  // Customizable Area End
}
// Customizable Area End

// Customizable Area Start
export const CourseFeedback = (props: any) => {
  // Customizable Area Start
  const { course_feedback } = props;
  const classes = useStyles();
  const commonClasses = commonStyles({ labelFontWeight: 500, labelFontSize: '18px' });
  return (
    <Box data-test-id='box'>
      {(
        course_feedback?.map((item: any, index: any) => {
          return (
            <>
              <Box className={classes.ratingWrapper} >
                {item.question_type !== configJSON.Feedback.TypeText && <Box>
                  <Typography key={item.question_id} className={commonClasses.fieldLabel}>
                    {item.question}
                    <sup>*</sup>
                  </Typography>
                  <Box>
                    {props.courseId?.includes(item.question_id) && !item.answer &&  (
                      <>
                        <Typography variant="body2" style={{ color: "red", fontSize: "12px", margin: '5px 0' }}                        >
                          {configJSON.Feedback.PlsSelectRating}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>}
                {item.question_type !== configJSON.Feedback.TypeText && <RatingCard {...item} handleRating={props.handleAnswerChange} />}
              </Box>
              {item.question_type === configJSON.Feedback.TypeText && (<>
                <CommonTextField
                  labelFontWeight={500}
                  labelFontSize={'18px'}
                  label={`${item.question}*`}
                  name={`${item.question_id}-${index}`}
                  value={item.answer}
                  multiline
                  minRows={4}
                  error={props.courseId?.includes(item.question_id)}
                  helperText={props.courseId?.includes(item.question_id) && configJSON.Feedback.PlsEnterFeedback}
                  placeholder={configJSON.Feedback.WriteFeedback}
                  handleChange={(e) =>
                    props.handleAnswerChange(
                      item.question_id,
                      e.target.value
                    )}
                />
              </>
              )}
            </>
          );
        })
      )}
    </Box>
  );
  // Customizable Area End
};
// Customizable Area End

// Customizable Area Start
export const TrainerFeedback = (props: any) => {
  // Customizable Area Start
  const { trainer_feedback } = props;
  const classes = useStyles();
  const commonClasses = commonStyles({ labelFontWeight: 500, labelFontSize: '18px' });
  return (
    <Box data-test-id='box'>
      <Box style={{ marginBottom: '20px' }}>
        <Typography className={commonClasses.mainTitle} >{configJSON.Feedback.TrainerFeedback}</Typography>
        <Typography variant="body2" style={webStyle.description}>
          {configJSON.Feedback.PlsProvideTrainerFeedback}
        </Typography>
      </Box>
      <Box>
        {(
          trainer_feedback.map((item: any, index: any) => {
            return (
              <>
                <Box className={classes.ratingWrapper}>
                  {item.question_type !== configJSON.Feedback.TypeText && <Box>
                    <Typography variant="h6" key={item.question_id} className={commonClasses.fieldLabel}>
                      {item.question} <sup>*</sup>
                    </Typography>
                    <Box mt={2}>
                      {props.trainerId?.includes(item.question_id) && !item.answer && (
                        <>
                          <Typography variant="body2" style={{ color: "red", fontSize: "12px", margin: '5px 0' }}                          >
                            {configJSON.Feedback.PlsSelectRating}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Box>}
                {item.question_type !== configJSON.Feedback.TypeText && <RatingCard {...item} handleRating={props.handleTrainerChange} />}
                </Box>
                {item.question_type === configJSON.Feedback.TypeText && (
                  <>
                    <CommonTextField
                      labelFontSize={'18px'}
                      labelFontWeight={500}
                      label={`${item.question}*`}
                      name={`${item.question_id}-${index}`}
                      multiline
                      minRows={4}
                      value={item.answer}
                      placeholder={configJSON.Feedback.WriteFeedback}
                      handleChange={(e) =>
                        props.handleTrainerChange(
                          item.question_id,
                          e.target.value
                        )}
                    />
                  </>
                )}
              </>
            );
          })
        )}
      </Box>
    </Box>
  );
  // Customizable Area End
};
// Customizable Area End

// Customizable Area Start
const webStyle = {
  diaTitle: {
    color: "#0056A3",
    textAlign: "center",
    fontWeight: 600,
    fontSize: "24px",
  } as React.CSSProperties,
  diaContent: {
    marginTop: "20px",
    textAlign: "center",
    fontWeight: 600,
  } as React.CSSProperties,

  required: {
    color: "red",
    marginTop: "10px",
  } as React.CSSProperties,

  label: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    // fontSize: "14px",
    fontSize: "0.875rem",
    color: "#000",
    lineHeight: "35px",
  },

  submit: {
    width: "150px",
    background: "#0056A3",
    color: "#fff",
    padding: "10px",
    textTransform: "capitalize",
  } as React.CSSProperties,

  mindMasteryPageContent: {
    padding: "32px",
  },
  description: {
    fontSize: "0.875rem",
    color: "grey",
  },
  emojiIcon: {
    padding: "0px",
  },
  emojiRating: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  } as React.CSSProperties,
  startRating: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  } as React.CSSProperties,
};
// Customizable Area End

