import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

// Customizable Area Start
import { DropResult } from 'react-beautiful-dnd';
import { isEqual } from 'lodash'
const navigation = require("react-navigation");

export type Id = string | number;

export type BucketId = 'correctBucketItems' | 'incorrectBucketItems';

export type DragEndEvent = DropResult & React.DragEventHandler<{}>;

export interface BucketContent {
    id: string;
    type: string;
    attributes: {
        id: Id,
        question_id: number,
        content: string | null
        is_correct: boolean
    }
}

export interface Bucket {
    data: BucketContent[]
}

export interface Option {
    id: Id;
    content: string;
    is_selected: boolean;
}

export interface DragDropAnswerBucket {
    bucket_id: Id;
    choices: Array<number>;
    is_correct: boolean;
}

export interface DragDropAnswer {
    question_id: Id;
    question_type: string;
    buckets: Array<DragDropAnswerBucket>;
}

export interface DragDropQuestionContent {
    id: Id;
    options: Array<Option>;
    buckets: Bucket | undefined;
    answers: DragDropAnswer | undefined
}

export interface DragDropChangeData {
    question_id: Id;
    question_data: DragDropQuestionContent
    correct_bucket_items: DragDropAnswerBucket;
    incorrect_bucket_items: DragDropAnswerBucket;
}
// Customizable Area End

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    isListView: boolean;
    questionData: DragDropQuestionContent;

    handleDragDropChange: (data: DragDropChangeData) => void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    correctBucketItems: Array<Option>;
    incorrectBucketItems: Array<Option>;
    currentOptions: Array<Option>;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

export default class DragDragQuestionController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
        ];

        let correctBucketItems: Array<Option> = [];
        let incorrectBucketItems: Array<Option> = [];
        const { answers, options } = props.questionData;

        if (answers?.buckets) {
            const correctBucket = answers.buckets.find((bucket:DragDropAnswerBucket) => bucket.is_correct);
            const incorrectBucket = answers.buckets.find((bucket:DragDropAnswerBucket) => !bucket.is_correct);
            correctBucketItems = this.getBucketItems(correctBucket, options);
            incorrectBucketItems = this.getBucketItems(incorrectBucket, options);
        }

        const bucketItems = [...correctBucketItems, ...incorrectBucketItems];

        const modifiedCurrentOptions = options.map((propOption) => {
            const isSelected = bucketItems.some((item:Option) => item.id === propOption.id);
            return {
                ...propOption,
                is_selected: isSelected,
            };
        });

        // Customizable Area End
        this.state = {
            // Customizable Area Start
            correctBucketItems: correctBucketItems,
            incorrectBucketItems: incorrectBucketItems,
            currentOptions: modifiedCurrentOptions
            // Customizable Area End
        }

        // Customizable Area Start
        this.getBucketItems = this.getBucketItems.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.getOptionName = this.getOptionName.bind(this);
        this.getList = this.getList.bind(this);
        // Customizable Area End
    }
    // Customizable Area Start

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        const { questionData, handleDragDropChange } = this.props;
        const { correctBucketItems, incorrectBucketItems } = this.state;

        if (
            correctBucketItems !== prevState.correctBucketItems ||
            incorrectBucketItems !== prevState.incorrectBucketItems ||
            !isEqual(prevProps.questionData.options, questionData.options)
        ) {
            const correctBucketId = questionData.buckets?.data.find((bucket) => bucket.attributes.is_correct)?.id || "1";
            const incorrectBucketId = questionData.buckets?.data.find((bucket) => !bucket.attributes.is_correct)?.id || "2";

            const updatingData = {
                question_id: questionData.id,
                question_data: questionData,
                correct_bucket_items: {
                    bucket_id: correctBucketId,
                    is_correct: true,
                    choices: correctBucketItems.map((item: Option) => item.id) as number[],
                },
                incorrect_bucket_items: {
                    bucket_id: incorrectBucketId,
                    is_correct: false,
                    choices: incorrectBucketItems.map((item: Option) => item.id) as number[],
                },
            };

            handleDragDropChange(updatingData);
        }
    }

    handleDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }
      
        if (result.source.droppableId === 'options') {
            const sourceItems = Array.from(this.state.currentOptions);
            const destItems = Array.from(this.getList(result.destination.droppableId));
            const [movedItem] = sourceItems.splice(result.source.index, 1);
            movedItem.is_selected = true;
            destItems.splice(result.destination.index, 0, movedItem);
            this.setState({ [result.destination.droppableId]: destItems } as Pick<S, keyof S>);
        }

        const isDragBetweenBuckets = result.source.droppableId === 'correctBucketItems' && result.destination.droppableId === 'incorrectBucketItems' || result.source.droppableId === 'incorrectBucketItems' && result.destination.droppableId === 'correctBucketItems';
        if (isDragBetweenBuckets) {
            const sourceItems = Array.from(this.getList(result.source.droppableId));
            const destItems = Array.from(this.getList(result.destination.droppableId));
            const [movedItem] = sourceItems.splice(result.source.index, 1);
            movedItem.is_selected = true;
            destItems.splice(result.destination.index, 0, movedItem);
            this.setState({ [result.source.droppableId]: sourceItems, [result.destination.droppableId]: destItems } as Pick<S, keyof S>);
        }
    }

    handleDelete(bucketId: 'correctBucketItems' | 'incorrectBucketItems', optionId: Id) {
        const bucketKey = bucketId as 'correctBucketItems' | 'incorrectBucketItems';
        this.setState((prevState: S) => {
            const updatedBucket = prevState[bucketKey].filter((currentOption: Option) => currentOption.id !== optionId);
            return { [bucketKey]: updatedBucket } as Pick<S, keyof S>;
        });

        const updatedOptions = this.state.currentOptions.map((option: Option) =>
            option.id === optionId ? { ...option, is_selected: false } : option
        );

        this.setState({ currentOptions: updatedOptions });
    }

    getList = (id: string): Option[] => {
        if (id === 'correctBucketItems') return this.state.correctBucketItems;
        if (id === 'incorrectBucketItems') return this.state.incorrectBucketItems;
        return [];
    };

    getOptionName(id: number) {
        switch (id) {
            case 1:
                return configJSON.optionAlphabets.A;
            case 2:
                return configJSON.optionAlphabets.B;
            case 3:
                return configJSON.optionAlphabets.C;
            case 4:
                return configJSON.optionAlphabets.D;
            case 5:
                return configJSON.optionAlphabets.E;
            case 6:
                return configJSON.optionAlphabets.F;
        }
    }

    getBucketItems(bucket: DragDropAnswerBucket | undefined, options: Array<Option>): Array<Option> {
        return bucket?.choices.map((choiceNumber: number) => options.find((option: Option) => option.id === choiceNumber)!) || [];
    }
    // Customizable Area End
}