import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Typography, Box, Button, DialogTitle } from '@material-ui/core'
import {  submitWarning, successfulMan, warning } from "../../blocks/dashboard/src/assets";
import commonStyles from '../../components/src/commonStyle.web';

const useStyles = makeStyles({
    scheduleImg: {
        marginBottom: '40px',
        width: '130px',
        '@media(max-width:575px)': {
            marginBottom: '25px'
        }
    },
    controlbtnWrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        maxWidth: '460px',
        width: '100%'
    },
});

interface Props {
    title?: string;
    open: boolean;
    successImage?: string;
    confirmImage?: string;
    closeModal: () => void;
    closeModalFunction?: () => void
    modalType: "success" | "warning" | "confirm" | "schedule";
    info?: string;
    date?: Date | string;
    time?: string;
    handleDateTime?: (date: Date | string, time: string) => void;
    disabledDate?: boolean;
    submitBtnText?: string;
    canceBtnText?: string;
    submit?: () => void;
}

interface SuccessContentProps {
    closeModal: () => void;
    info?: string;
    image?: string;
    isTitleThere:boolean;
}
export const SuccessContent = (props: SuccessContentProps) => {
    const commonClasses = commonStyles(props);
    return <>
        <img className={commonClasses.successImg} src={props.image || successfulMan} alt="success_image" />
        <Typography className={commonClasses.successMsg}>{props.info}</Typography>
        <Button data-test-id='closeModal' className={commonClasses.primaryButton} onClick={props.closeModal} >Okay</Button>
    </>
}

interface WarningContentProps {
    info: string | undefined;
    closeModal: () => void;
}
export const WarningContent = (props: WarningContentProps) => {
    const commonClasses = commonStyles({});
    const { closeModal, info } = props;
    return <>
        <img className={commonClasses.warningConfirmImg} src={warning} alt="warning_image" />
        <Typography className={commonClasses.successMsg}>{info}</Typography>
        <Button className={commonClasses.primaryButton} onClick={closeModal} >Okay</Button>
    </>
}

interface ConfirmationContentProps {
    info: string;
    image?: string;
    submit?: () => void;
    cancel: () => void;
    canceBtnText?: string;
    submitBtnText?: string;
}

export const ConfirmationContent = (props: ConfirmationContentProps) => {
    const classes = useStyles();
    const commonClasses = commonStyles({});
    const { cancel, submit, info, image } = props;
    return <>
        <img className={commonClasses.warningConfirmImg} src={image || submitWarning} alt="warning_image" />
        <Typography className={commonClasses.successMsg}>{info}</Typography>
        <Box className={classes.controlbtnWrapper}>
            {cancel && <Button data-test-id='cancel' className={commonClasses.secondaryButton} onClick={cancel} >{props.canceBtnText || "Cancel"}</Button>}
            {submit && <Button data-test-id='submit' className={commonClasses.primaryButton} onClick={submit} >{props.submitBtnText || 'Submit'}</Button>}
        </Box>
    </>
}


const InfoModal = (props: Props) => {
    const commonClasses = commonStyles({});
    const { open, closeModal, modalType, info = '', title ,closeModalFunction} = props;
    const closeFn = closeModalFunction ? closeModalFunction : closeModal;

    return <>
        <Dialog data-test-id='box' className={commonClasses.dialog} BackdropProps={{ className: commonClasses.backdrop }} open={open} onClose={closeFn}>
            {title && <DialogTitle id="simple-dialog-title" className={commonClasses.dialogTitle} >
                <Typography className={commonClasses.dialogTitleText} >{title} </Typography>
            </DialogTitle>}
            <Box className={commonClasses.modalContent}>
                {modalType === 'success' && <SuccessContent data-test-id='success-content' isTitleThere={Boolean(title)} image={props.successImage} closeModal={closeModal} info={info} />}
                {modalType === 'warning' && <WarningContent closeModal={closeModal} info={info} />}
                {modalType === 'confirm' && <ConfirmationContent data-test-id='confirm' image={props.confirmImage} submitBtnText={props.submitBtnText} canceBtnText={props.canceBtnText} info={info} submit={props.submit} cancel={closeModal} />}
            </Box>
        </Dialog>
    </>
}

export default InfoModal;