let config = require("./config");

export const apiCall = async (method: string, endpoint: string, headers: any, body: string) => {
    let fullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;
    let response: any;
    try {
      if (headers && body) {
        response = await fetch(fullURL, {
          method: method.toUpperCase(),
          headers: headers,
          body: body
        });
      }else if (headers) {
        response = await fetch(fullURL, {
        method: method.toUpperCase(),
        headers: headers.length ? JSON.parse(headers) : headers,
      });
      } else {
        response = await fetch(fullURL, {
        method: method.toUpperCase()
      });
      }
      let responseJson = await response.json();
      if (response.status === 200) {
        return responseJson;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Api Error", error);
      return false;
    }
  }
  