import React from "react";
import { Box, Grid, Container, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UtilityInfo from "./UtilityInfo.web";
import GenericWebButton from "./GenericWebButton.web";
const {
  bannerBg,
  rightArrow,
  leftTop,
  rightBottom,
  bottomLeftShape
} = require("./assets");

interface Content {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
  multiLine?: string[];
}
interface Props {
  content: Content;
  bannerImage: any;
  showBtn: boolean;
}

const useStyles = makeStyles(theme => ({
  bannerBg: {
    backgroundImage: `url(${bannerBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingTop: "60px",
    paddingBottom: "60px",
    borderRadius: "0px 0px 64px 64px",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "60px"
    },
    "&::after": {
      content: '""',
      backgroundImage: `url(${leftTop})`,
      width: "355px",
      height: "400px",
      position: "absolute",
      top: "-145px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      left: "-35px",
      pointerEvents: "none",
      [theme.breakpoints.down("md")]: {
        backgroundImage: "none"
      }
    },
    "&::before": {
      content: '""',
      backgroundImage: `url(${rightBottom})`,
      height: "400px",
      position: "absolute",
      bottom: "-55px",
      right: "-35px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      width: "355px",
      [theme.breakpoints.down("md")]: {
        backgroundImage: "none"
      }
    }
  },
  containerStyle: {
    justifyContent: "center"
  },
  contentBox: {
    width: "493px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: 0,
    },
    marginTop: "30%",
    marginBottom: "60px"
  },
  bannerImageBox: {
    display:'flex',
    justifyContent:'center',
    height:'493px',
    [theme.breakpoints.down("sm")]: {
      transform: " translateY(0px)",
      paddingBottom: "60px",
      height:'auto',
    },
    position: "relative",
    "&::after": {
      content: '""',
      width: "80px",
      height: "80px",
      border: "4px solid #fff",
      position: "absolute",
      bottom: "-22px",
      left: "-26px",
      borderRadius: "100%",
      zIndex: "-1",
      [theme.breakpoints.down("xs")]: {
        bottom: "35px",
        left: "-8px"
      },
      [theme.breakpoints.down("md")]: {
        left: "-10px",
        bottom: "26px"
      }
    }
  },
  bannerImage: {
    boxShadow: "8px 8px 35px #424242",
    borderRadius: "46px"
  },
  topSpace: {
    marginTop: "15px"
  },
  containerSpace: {
    [theme.breakpoints.down("sm")]: {
      transform: " translateY(-24px)"
    }
  },
  bottomLeftBg: {
    width: "200px",
    position: "absolute",
    left: 0,
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

const Banner = (props: Props) => {
  const { content, bannerImage, showBtn } = props;
  const classes = useStyles();
  const handleRedirect = () => {
    window.location.href = '/partnership#specific-section';
    console.log("I am clicked");
  };
  return (
    <Box className={classes.bannerBg}>
      <Box className={classes.bottomLeftBg}>
        <img
          src={bottomLeftShape}
          width="100%"
          alt="bottom left background image"
        />
      </Box>
      <Container className={classes.containerSpace}>
        <Grid container className={classes.containerStyle}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box className={classes.contentBox}>
              <UtilityInfo {...content} lineHeight="72px" fontSize="48px" />
              {showBtn ? (
                <Box className={classes.topSpace}>
                  <Link style={{zIndex:"1000"}} onClick={handleRedirect}>
                    <GenericWebButton
                      icon={rightArrow}
                      text={"Contact Us"}
                      background={"white"}
                      color={"#0056A3"}
                      hoverColor={"white"}
                      boxShadow={"2px 2px 33px -2px white"}
                    />
                  </Link>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box className={classes.bannerImageBox}>
              <img
                className={classes.bannerImage}
                src={bannerImage}
                width="100%"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Banner;
