Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.userDetailsApi = "/students";
exports.userDetailsApiMethod = "GET";
exports.userDetailsApiContentType = "application/json";

exports.createAccountApiEndPoint='bx_block_registration/registrations';
exports.createAccountApiMethodType="PATCH";
exports.createAccountApiContentType="application/json";

exports.termsAndConditionsApiEndPoint='terms_and_conditions';
exports.termsAndConditionsApiMethodType="GET";
exports.termsAndConditionsApiContentType="application/json";

exports.privacyPolicyApiEndPoint='terms_and_conditions';
exports.privacyPolicyApiMethodType="GET";
exports.privacyPolicyApiContentType="application/json";

exports.resendOTPApi = "bx_block_registration/resend_otp";
exports.resendOTPApiMethod = "POST";
exports.resendOTPApiContentType = "application/json";

exports.verifyOTPApi = "bx_block_registration/verify_otp";
exports.verifyOTPApiMethod = "POST";
exports.verifyOTPApiContentType = "application/json";

exports.VerifyEmail = {
  VerifyYourEmailID:'Verify Your Email ID',
  EnterOtpMsg:'Enter OTP Sent on your Email ID',
  ToVerifyMsg:'to verify your account',
  WrongOTPEntered:'Wrong OTP Entered',
  OTPNotReceived:"Didn't Receive the OTP?",
  Resend:'Resend',
  VerifyAccount:'Verify Account'
};

exports.UserRole = {
  School:'School',
  Student:'Student',
  Trainer:'Trainer',
  Parents:'Parents'
};

exports.AccountCreateSuccess = {
  AccountCreatedSuccessfully:'Account Created Successfully',
  CongAccountCreatedSuccessfully:'Congratulations! Your account is successfully created.',
  PleaseLogin:'Please login to access your account.',
  Done:'Done'
};

exports.TermsConditions = {
  WeCare:'At Hapchi, we are concern about your privacy. We endeavour our full support to protect your personal information and keep it confidential.',
  OurPrivacyPolicyDescribes:'Our Privacy Policy describes what information we gather from you and others who visit and use the Website, how we use that information, and what we do to protect it. By visiting or using our Website, you expressly consent to the information handling practices described in this Privacy Policy.',
  TypesOfInformationCollected:'Types of Information Collected',
  PersonalInfoCollection:'We may collect the following types of personal information:',
  ContactInfo:'Contact information, such as name, email address, postal address, and phone number',
  DemographicInformation:'Demographic information, such as age, gender, and interests Payment information, such as credit card details and billing information',
  InformationAbout:'Information about your use of our services such as purchase history and preferences',
  OtherMoreInfo:'Any other information you choose to provide to us.',
  UseOfInformation:'Use of Information',
  WhereWeUseInfo:'We use the information we collect for the following purposes including but not limited to:',
  ToProvide:'To provide and manage your account To provide and improve our services',
  ManageAccess:'To provide and manage access to our website',
  ToProcessTransaction:'To process transactions and send you order confirmations and receipts',
  ToRespondToInquiries:'To respond to your inquiries and support requests',
  ToSendPromotionalEmail:'To send you promotional emails and offers about our products and services',
  ToPersonalizeExp:'To personalize your experience and provide you with customized content and recommendations',
  ToImproveSecurity:'To improve the security of our website and services',
  SharingBusinessInfo:'Sharing relevant business information',
  Communication:'communication.',
  AnalysingUseOfWebSite:'Analysing your use of our website and gathering feedback to enable us to continually improve our website and your user experience.',
  SupplyingNewsLetters:'Supplying you with newsletters, alerts etc. via email, that you may have subscribed to and that you may unsubscribe or opt-out at any time by clicking on the unsubscribe link from such emails or from your account profile',
  ScopeOfUse:'Scope of Use',
  ThanksForVisiting:'Thank you for visiting the website.',
  OperatedBy:'This website is operated by Hapchi Private Limited, India.',
  AllFeedback:'All feedback, comments, requests for technical support and other communications relating to the website should be directed to:',
  YourCommentsAndConcerns:'Your Comments and Concerns',
  UserOfPrivacyPolicy:'The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and the Organization with respect to the website and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the website.',
  EntireAgreement:'Entire Agreement',
  WaiverAndSeverability:'Waiver and Severability',
  OurWebSite:'Our website is not intended for individuals under 18 years of age, without the valid consent of their parent or guardian. If we learn that we have collected or received personal information from an individual under age 18 without verification of legal guardian or parental consent, we will delete that information. If you believe that we might have any information from or about a child under age 18, please contact us at',
  WeWillEnsure:'We will ensure that your personal information is processed lawfully, fairly, and transparently, without adversely affecting your rights. We will only process your personal information if at least one of the following basis applies: (i) you have given consent to the processing of your personal information for one or more specific purposes; (ii) processing is necessary for the performance of a contract to which you are a party or in order to take steps at the request of you prior to entering into a contract; (iii) processing is necessary for compliance with a legal obligation to which we are subject; (iv) processing is necessary to protect the vital interests of you or of another natural person; (v) when we believe in good faith that disclosure is necessary to protect our rights, protect your safety, or the safety of others, or to investigate fraud or other illegal activities.',
  ItIsImportant:'It is important to us that your personal information is up to date. We will take reasonable steps to make sure that your personal information is accurate, complete and up to date. If you find that the information we have is not up to date or is inaccurate, please advise us as soon as practicable so we can update our records and ensure we can continue to provide quality services to you.',
  SharingInfo:'Sharing of Personal information',
  WeMayContract:'We may contract with third parties to supply services to you on our behalf. These may include payment processing, search engine facilities, advertising, marketing, hosting our website, and providing customer support. In some cases, the third parties may require access to some or all of your data. Please be aware that we are not responsible for the privacy practices or content of those third parties or other sites, nor for any third party to whom we transfer your personal information in accordance with our Policy.',
  ThirdPartyLinks:'Third Party Links',
  RetentionOfPersonalInformation:'Retention of Personal Information',
  YourPersonalInfo:'Your Personal Information stored with us shall become a part of our database.  Hapchi may also use your Personal Information, where:',
  ItMayBe:'it may be necessary to deliver the services you have requested; and',
  WhereItIs:'where it is necessary to comply with legal requirements or for normal business purposes as set in this Policy.',
  SecurityOfInformation:'Security of Information',
  WeTakeReasonable:'We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no website or internet transmission is completely secure, and we cannot guarantee the security of your personal information.',
  Cookies:'Cookies',
  HapchiMay:'Hapchi may use technologies such as cookies, log files, the flash cookies for specific purposes, such as to help understand how you interact with our website and services, by providing information about the areas visited, the time spent on the Site, and any issues encountered, such as error messages. These cookies allow our website to remember the choices you make (such as your user name, language or the region you are in) to provide a more personalized online experience. They can also enable visitors to watch videos, play games and engage with social tools, such as blogs, chatrooms and forums. This helps us improve the performance of our Site.',
  TheWebSite:'The website may capture certain information automatically, such as the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the website.',
  UserConsent:'User Consent',
  ByRegistering:'By registering with us, you give us permission to contact you by email, SMS or phone call with respect to your enquiry and engagement with Hapchi. You also give us permission to collect, process, store, disclose and handle your personal information as set forth in this Policy now and as modified by us time to time. Processing of all client information in any manner, including, but not limited to, collecting, storing, using, combining, sharing, transferring, disclosing and deleting information currently takes place in India.',
  WebSiteMay:'website may provide links to other websites that are not under our direct control. Clicking on links may take you to sites outside our domain. These websites may have their own policies regarding privacy. We have no control of or responsibility for linked websites and provide these links solely for the convenience and information of our visitors. You are accessing such linked websites solely at your own risk. These websites are not subject to this Privacy Policy. We are not responsible for the privacy practices of these websites. We advise you to check the privacy policies of any such websites before providing any personal information to them. This Privacy Policy applies only to information collected by our website.',
  ChangesToThisPrivacyPolicy:'Changes to this Privacy Policy',
  WeMayUpdate:'We may update this privacy policy from time to time to reflect changes in our practices or to comply with applicable laws. Any changes will be immediately posted on our website and you will be deemed to have accepted the terms of the Privacy Policy on your first use of our website following the alterations. Hence, we encourage you to review our website regularly.',
  ContactInformation:'Contact Information',
  IfYouHave:'If you have any questions about this privacy policy or the use of your personal information, please contact us at',
  HAPCHIPRIVATELIMITED:'HAPCHI PRIVATE LIMITED',
  IfYouAre:'If you are not of a legal age to enter into a binding contract (in many places, this is 18 years old), then you must get your parent or guardian to read these terms and agree to them for you, before you use Hapchi or provide any information to us. Please review this agreement with your parent or guardian so that you both understand how Hapchi works and what restrictions apply to your use of our website and services.',
  AcceptanceOfTheTermsOfUse:'Acceptance of the Terms of Use',
  TheseCookies:'These cookies help us understand how visitors interact with our Site We might use information that we hold about you to show you relevant and',
  EachTime:'Each time you use this website, you accept and agree to abide by these Terms of Use and our Privacy Policy, incorporated herein by reference. This Terms of Use is for an indefinite term and you understand and agree that you are bound by such terms, if you do not agree to these Terms of Use or the Privacy Policy, please do not access or use the website.',
  ChangesToThisTermsOfUse:'Changes to this Terms of Use',
  WeMayRevise:'We may revise and update these Terms of Use from time to time in our sole discretion as per the applicable laws. All future changes are effective and binding when they are posted.',
  IfYouUse:'If you use the website following the posting of revised Terms of Use you accept and agree to the changes. It is your responsibility to check this page from time to time so you are aware of any changes, as they are binding on you if you choose to continue to access or use the website.',
  WeReserve:'We reserve the right to amend or modify the Terms of Use of this website, including but not limited to suspend or discontinue any service or material we provide on the website, in our sole discretion, at any time without notice, make modifications and alterations in any or all of the content, products and services contained on the website without prior notice. We will not be liable if for any reason all or any part of the website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the website, or the entire website, to users, including registered users. You will be deemed to have accepted such change(s) if you continue to access this website subsequent to such changes.',
  AccessToTheWebsite:'Access to the Website',
  YouAreRes:'You are responsible for making all arrangements necessary for you to have access to the website and ensuring that all persons who access the on your behalf are aware of these Terms of Use and comply with them.',
  ToAccess:'To access the website or some of the resources it offers, including enrolment to course Programs, you may be asked to sign up for account by providing your personal information',
  Security:'Security',
  YouAlsoAck:'You also acknowledge that your account is personal to you and agree not to provide any other person with access to this website or portions of it using your User Data. You agree to notify us immediately of any unauthorized access to or use of your User Data or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your User Data or other personal information.',
  WeHaveThe:'We have the right to disable any User Data, whether chosen by you or provided by us, at any time in our sole discretion if, in our opinion, you have violated any provision of these Terms of Use or we become aware of a potential breach of security through the use of your User Data.',
  IntellectualPropertyRights:'Intellectual Property Rights',
  TheWebSiteAnd:'The website and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, graphs, animation, video and audio, and the design, selection and arrangement thereof), touch and feel of the website are owned by us, its affiliates, its licensors or other providers of such material and are protected by national and international intellectual property or proprietary rights laws. Any infringement shall be vigorously prosecuted and pursued to the fullest extent permitted by law.',
  TheseTerms:'These Terms of Use permit you to use the website for your personal, non-commercial use only. You must not access or use for any commercial purposes any part of the website or any services or materials available through the website.',
  IfYouWish:'If you wish to make any use of material on the website other than that set out in this section, please address your request to:',
  NoRight:'No right, title or interest in or to the website or any content on the website is transferred to you, and all rights not expressly granted are reserved by us. Any use of the website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other intellectual property laws.',
  HapchiClassroom:'Hapchi Classroom(s)',
  ProhibitedUses:'Prohibited Uses',
  ProhibitedContent:'Prohibited content includes, but is not limited to content that, in the sole discretion of Organization:',
  IfYouAreAnEducator:'If you are an Educator, you agree to only use Hapchi for lawful purposes and to take reasonable steps to ensure that Students are using the Classroom appropriately, and that you will protect your unique code to limit access to the Classroom (as well as to follow the other restrictions in these Terms).',
  BecauseStudents:'Because Students may use Classrooms without their parents present, Hapchi requires that Educators obtain parental consent from Students who are under the age of thirteen (13) before allowing them in a Classroom. If you have questions about how to obtain parental consent, please ask your school for details on its procedures, or email us at',
  Infringe:'infringes or violates the intellectual property rights or any other rights of anyone else (including Hapchi);',
  ViolatesAny:'violates any law or regulation, including, without limitation, any applicable privacy laws or any other purpose not reasonably intended by Hapchi;',
  IsDangerous:'is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;',
  Attempts:'attempts, in any manner, to obtain the password, account, or other security information from any other user or',
  AnAttempts:'an attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the website, the server on which the website is stored, or any server, computer or database connected to the website.',
  PromotesRacism:'promotes, racism, bigotry, hatred or physical harm of any kind against any group or individual;',
  PromotesInfo:'promotes information that you know is false or misleading or promotes illegal activities or conduct that is abusive, threatening, offensive, obscene, defamatory or libelous;',
  HarmsOf:'harms or attempts to exploit or harm minors in any way by exposing them to inappropriate content, asking for personal information or otherwise.',
  PromotesAnIllegal:"promotes an illegal or unauthorized copy of another person's copyrighted work,",
  Sends:'sends, knowingly receives, uploads, downloads, uses or re-uses any material which does not comply with the standards set out herein;',
  AttemptsToImpersonate:'attempts to impersonate the Organization, an Organization employee, another user or any other person or entity (including, without limitation, by using e-mail addresses associated with any of the foregoing);',
  ExposeTheWeb:'Expose the website with malware, virus attacks, etc.',
  OtherwiseAttempts:'Otherwise attempt to interfere with the proper working of the website.',
  Involves:'involves the transmission, of any advertising or promotional material without our prior written consent including any',
  OrAny:'or any other similar unsolicited contains restricted or password only access pages or hidden pages or images (those not linked to or from another accessible page);'
};
  
// Customizable Area End
