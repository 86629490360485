// Customizable Area Start
import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core"
import {
	CometChatThreadedMessageReplyCount,
	CometChatReadReceipt,
	CometChatMessageActions,
} from "../";
import { CometChatMessageReactions } from "../Extensions";

import { CometChatContext } from "../../../util/CometChatContext";
import {
	checkMessageForExtensionsData,
	getMessageFileMetadata,
} from "../../../util/common";
import * as enums from "../../../util/enums.js";

import { theme } from "../../../resources/theme";

import {
	messageContainerStyle,
	messageWrapperStyle,
	messageFileWrapper,
	messageInfoWrapperStyle,
	messageReactionsWrapperStyle,
	iconStyle,
} from "./style";

import fileIcon from "./resources/file-upload.svg";
import moment from 'moment'
import 'moment-timezone'

class CometChatSenderFileMessageBubble extends React.Component {
	static contextType = CometChatContext;

	constructor(props) {
		super(props);

		this.state = {
			isHovering: false,
			fileData: {},
		};
	}

	componentDidMount() {
		const fileData = this.getFileData();
		this.setState({ fileData: fileData });
	}

	shouldComponentUpdate(nextProps, nextState) {
		const currentMessageStr = JSON.stringify(this.props.message);
		const nextMessageStr = JSON.stringify(nextProps.message);

		return (
			currentMessageStr !== nextMessageStr ||
			this.state.isHovering !== nextState.isHovering ||
			this.state.fileData !== nextState.fileData
		);
	}


	componentDidUpdate(prevProps) {
		const previousMessageStr = JSON.stringify(prevProps.message);
		const currentMessageStr = JSON.stringify(this.props.message);

		if (previousMessageStr !== currentMessageStr) {
			const fileData = this.getFileData();

			const previousfileData = JSON.stringify(this.state.fileData);
			const currentfileData = JSON.stringify(fileData);

			if (previousfileData !== currentfileData) {
				this.setState({ fileData: fileData });
			}
		}
	}

	handleMouseHover = () => {
		this.setState(this.toggleHoverState);
	};

	toggleHoverState = (state) => {
		return {
			isHovering: !state.isHovering,
		};
	};

	getFileData = () => {
		const metadataKey = enums.CONSTANTS["FILE_METADATA"];
		const fileMetadata = getMessageFileMetadata(
			this.props.message,
			metadataKey
		);

		if (fileMetadata instanceof Blob) {
			return { fileName: fileMetadata["name"] };
		} else if (
			this.props.message.data.attachments &&
			typeof this.props.message.data.attachments === "object" &&
			this.props.message.data.attachments.length
		) {
			const fileName = this.props.message.data.attachments[0]?.name;
			const fileUrl = this.props.message.data.attachments[0]?.url;

			return { fileName, fileUrl: fileUrl };
		}
	};

	dateShow = (date) => {
		const indianTime = moment.unix(date).tz('Asia/Kolkata');
		const formattedDate = indianTime.format('Do MMM YYYY, h:mm A');
		return <div>{formattedDate}</div>;
	};


	render() {

		const { message } = this.props;
	console.log(message,"======")
	

		const pinnedMessages = localStorage.getItem("pinMessages");
		const pinMessages = pinnedMessages ? JSON.parse(pinnedMessages) : null;
		const isPinned = pinMessages?.some((msg) => msg.data.id === message.id);
		if (!Object.keys(this.state.fileData).length) {
			return null;
		}

		const someDate = this.props.message.sentAt
		const formattedDate = this.dateShow(someDate);
		let messageReactions = null;
		const reactionsData = checkMessageForExtensionsData(
			this.props.message,
			"reactions"
		);
		if (reactionsData) {
			if (Object.keys(reactionsData).length) {
				messageReactions = (
					<div
						css={messageReactionsWrapperStyle()}
						className='message__reaction__wrapper'
					>
						<CometChatMessageReactions
							message={this.props.message}
							actionGenerated={this.props.actionGenerated}
						/>
					</div>
				);
			}
		}

		let toolTipView = null;
		if (this.state.isHovering) {
			toolTipView = (
				<CometChatMessageActions
					message={this.props.message}
					actionGenerated={this.props.actionGenerated}
				/>
			);
		}
		let fileMessage = null;
		if (this.state.fileData.hasOwnProperty("fileUrl")) {
			fileMessage = (
				<a
					href={this.state.fileData?.fileUrl}
					target='_blank'
					rel='noopener noreferrer'
					className='message__file'
				>
					<i css={iconStyle(fileIcon, this.context)}></i>
					<p>{this.state.fileData?.fileName}</p>
					
				</a>
			);
		} else {
			fileMessage = (
				<React.Fragment>
					<i css={iconStyle(fileIcon, this.context)}></i>
					<p>{this.state.fileData?.fileName}</p>
				</React.Fragment>
			);
		}


		const name = localStorage.getItem('userDetails')
		const userData = name ? JSON.parse(name) : null
		const adminCondition=  this.props.isAdmin? "A": this.props.message?.sender?.name.charAt(0)
		return (
			<div
				css={messageContainerStyle()}
				className='sender__message__container message__file'
				onMouseEnter={this.handleMouseHover}
				onMouseLeave={this.handleMouseHover}
			>
				<div style={{ display: "flex", justifyContent: "flex-end", position: "relative", top: "35px", right: "10px" }}>
					{toolTipView}
				</div>
				<div css={messageWrapperStyle()} className='message__wrapper'>
					<div
						css={messageFileWrapper(this.context)}
						className='message__file__wrapper'
					>
						<div style={{ display: "flex", gap: "5px", alignItems: "center", position:"relative",right:"12px" }}>
							<div className='chat__thumbnail' style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "white", fontSize: "22px", fontWeight: "bold" }}>
								<Avatar style={{ width: "35px", height: "35px", borderRadius: "50%", backgroundColor: "gray" }}>{userData?.attributes?.image_url  && !this.props.isAdmin ? <img src={userData?.attributes?.image_url} /> : adminCondition}</Avatar>
							</div>
							<div style={{ color: "#4C4B48", fontWeight: "bold",marginLeft:"12px" }}>{message?.sender?.name}</div>
							<div style={{ color: "#808080", fontSize: "12px", marginLeft: "10px" }}>{formattedDate}</div>
							{isPinned ? <div style={{ background: "#1067B5", borderRadius: "3px", fontWeight: "bold", color: "white", fontSize: "12px", padding: "5px", marginLeft: "20px" }}>Pinned</div> : null}
						</div>
						<div style={{position:"relative",display:"flex",justifyContent:"flex-start",left:"30px",top:"10px"}} className='message__file' >{fileMessage}</div>
					</div>
				</div>

				{messageReactions}

				<div css={messageInfoWrapperStyle()} className='message__info__wrapper'>
					<CometChatThreadedMessageReplyCount
						message={this.props.message}
						actionGenerated={this.props.actionGenerated}
					/>
					<CometChatReadReceipt message={this.props.message} />
				</div>
			</div>
		);
	}
}

// Specifies the default values for props:
CometChatSenderFileMessageBubble.defaultProps = {
	theme: theme,
	actionGenerated: () => { },
};

CometChatSenderFileMessageBubble.propTypes = {
	theme: PropTypes.object,
	actionGenerated: PropTypes.func.isRequired,
	message: PropTypes.object.isRequired,
};

export { CometChatSenderFileMessageBubble };
// Customizable Area End