export const messageContainerStyle = () => {
	return {
		alignSelf: "flex-start",
		marginBottom: "16px",
		borderLeft: "5px solid #366F92",
		width: "100%",
		backgroundColor:"rgb(235, 248, 255)",
		clear: "both",
		position: "relative",
		display: "flex",
		flexDirection: "column",
		flexShrink: "0",
	};
};

export const messageWrapperStyle = () => {
	return {
		width: "auto",
		flex: "1 1",
		alignSelf: "flex-start",
		display: "flex",
		
	};
};

export const messageThumbnailStyle = () => {
	return {
		width: "36px",
		height: "36px",
		margin: "10px 5px",
		float: "left",
		flexShrink: "0",
	};
};

export const messageDetailStyle = () => {
	return {
		flex: "1 1",
		display: "flex",
		flexDirection: "column",
	};
};

export const nameWrapperStyle = (avatar) => {
	const paddingValue = avatar
		? {
				padding: "3px 5px",
		  }
		: {};

	return {
		alignSelf: "flex-start",
		...paddingValue,
	};
};

export const nameStyle = (context) => {
	return {
		fontSize: "11px",
		color: `${context.theme.color.search}`,
	};
};

export const messageImgContainerStyle = (customWidth, customFlex, customAlignSelf) => {
	const defaultWidth = "auto";
	const defaultFlex = "1 1";
	const defaultAlignSelf = "flex-start";
  
	const width = customWidth || defaultWidth;
	const flex = customFlex || defaultFlex;
	const alignSelf = customAlignSelf || defaultAlignSelf;
  
	return {
	  width: width,
	  flex: flex,
	  alignSelf: alignSelf,
	  display: "flex",
	  position:"relative",
	  left:"20px",
	  top:"10px"
	};
  };
  

export const messageImgWrapperStyle = (context) => {
	const mq = [...context.theme.breakPoints];

	return {
		// display: "inline-block",
		alignSelf: "flex-start",
		width: "100%",
		height: "100px",
		// cursor: "pointer",
		img: {
			borderRadius: "8px",
			height: "100%",
		},
		[`@media ${mq[1]}, ${mq[2]}`]: {
			minWidth: "50px",
			maxWidth: "150px",
			height: "100px",
			padding: "2px 2px",
		},
	};
};

export const messageInfoWrapperStyle = () => {
	return {
		alignSelf: "flex-start",
		padding: "4px 8px",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		height: "25px",
	};
};

export const messageReactionsWrapperStyle = () => {
	return {
		display: "flex",
		alignSelf: "flex-start",
		width: "100%",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		minHeight: "36px",
	};
};
