import React from "react";
// Customizable Area Start
import MindMasteryController ,{SessionElement} from "./MindMasteryController.web";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Engage, Enhance, Ponder } from "../assets";
import PageContentLayout from "../PageContentLayout.web";
import SidebarLayout from "../SidebarLayout.web";
import Loader from "../../../../components/src/Loader.web";
import CommonPagination from "../../../../components/src/Pagination.web";
import InfoModal from "../../../../components/src/CommonInformationModal.web";
// Customizable Area End
const configJSON = require("../config.js");

const useStyles = makeStyles(() => ({
  // Customizable Area Start
  sessionInfoCard: {
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    border: "0.2px solid #e3e3e3",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: "8px",
    cursor: "pointer",
    "@media(max-width:991px)": {
      width: "100%"
    }
  },
  sessionInfoImgWrapper: {
    height: "75px",
    width: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  sessionInfoCardTitle: {
    fontWeight: 600,
    fontSize: "20px",
    fontFamily: "Poppins",
    lineHeight: "36px",
    color: "#3B3B3B",
    "@media(max-width:575px)": {
      fontSize: '18px'
    }
  },
  sessionInfoCardSubtitle: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
    lineHeight: "21px",
    color: "#3B3B3B",
    opacity: 0.64
  },
  sessionNumber: {
    background: "#0056A3",
    borderRadius: "8px",
    height: "48px",
    color: "white",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "27px",
    display: "flex",
    alignItems: "center",
    marginBottom: "24px",
    paddingLeft: "20px",
    "@media(max-width:575px)": {
      fontSize: '14px'
    }
  },
  cardContentWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  pageContentWrapper: {
    marginBottom: "32px",
    "&:last-child": { marginBottom: "0" }
  },
  gridItem: {
    "&.MuiGrid-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }
  // Customizable Area End
}));

// Customizable Area Start
const listType = (index: number) => {
  switch (index) {
    case 0:
      return configJSON.MindMasteryTexts.IceBreaker;
    case 1:
      return configJSON.MindMasteryTexts.Video;
    case 2:
      return configJSON.MindMasteryTexts.WorkSheet;
  }
};

interface SessionInfoCardProps {
  showError: boolean;
  openModal: (type: "success" | "warning" | "confirm" | "schedule", info: string) => void;
  goTo: (module: string, params?: Object) => void;
  image: string;
  courseId: any;
  sessionId: any;
  index: number;
  cardTitle: string;
  handleParticularSessions: (listType: string,sessionId:number) => void;
}

export const SessionInfoCard = (props: SessionInfoCardProps) => {
  const classes = useStyles();
  const handleCardClick = () => {
    if (props.showError) {
      props.openModal(configJSON.MindMasteryTexts.Warning, `${props.cardTitle} ${configJSON.MindMasteryTexts.NotAvailableForSession}`)
    } else {
      props.goTo(configJSON.MindMasteryTexts.SessionAttachmentListPage, {
        courseId: props.courseId,
        sessionId: props.sessionId,
        listType: listType(props.index)
      })
    }
    props.handleParticularSessions(listType(props.index),props.sessionId)
  }
  return (
    <Box
      className={classes.sessionInfoCard}
      data-test-id="session-info-card"
      onClick={handleCardClick}
    >
      <Box className={classes.sessionInfoImgWrapper}>
        <img src={props.image} alt="sessionAttachmentIcon" />
      </Box>
      <Box className={classes.cardContentWrapper}>
        <Typography className={classes.sessionInfoCardTitle}>
          {props.cardTitle}
        </Typography>
      </Box>
    </Box>
  );
};

interface SessionListPageProps {
  courseId: number;
  sessionId: number;
  openModal: (type: "success" | "warning" | "confirm" | "schedule", info: string) => void;
  goTo: (module: string, params?: Object) => void;
  attributes: {
    name: string;
    attachments: [{
      attachment_type: string;
    }]
  };
  handleParticularSessions: (listType: string,sessionId:number) => void;
}

export const SessionListPage = (props: SessionListPageProps) => {
  const classes = useStyles();
  const SessionInfo = [
    {
      image: Engage,
      cardTitle: configJSON.MindMasteryTexts.Engage,
      compareVar: configJSON.MindMasteryTexts.IceBreaker
    },
    { image: Enhance, cardTitle: configJSON.MindMasteryTexts.Enhance, compareVar: configJSON.MindMasteryTexts.Video },
    {
      image: Ponder,
      cardTitle: configJSON.MindMasteryTexts.Ponder,
      compareVar: configJSON.MindMasteryTexts.WorkSheet
    }
  ];
  const typeArr = props.attributes.attachments.map((element: any) => element.attachment_type);
  return (
    <Box className={classes.pageContentWrapper} data-test-id='box'>
      <Typography className={classes.sessionNumber}>{props.attributes.name}</Typography>
      <Grid container spacing={1}>
        {SessionInfo.map((element: any, index: number) => {
          const sessionInfoCardProps = {
            index: index,
            goTo: props.goTo,
            image: element.image,
            cardTitle: element.cardTitle,
            courseId: props.courseId,
            sessionId: props.sessionId,
            openModal: props.openModal,
            showError: !typeArr.includes(element.compareVar),
            handleParticularSessions: props.handleParticularSessions
          }

          return (
            <Grid
              key={element.image}
              className={classes.gridItem}
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
            >
              <SessionInfoCard data-test-id='session-info-card' {...sessionInfoCardProps} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
// Customizable Area End

export default class SessionsList extends MindMasteryController {
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    window.scrollTo(0, 0);
    const courseId = Number(this.props.navigation.getParam("courseId"));
    this.setState({courseId:courseId})
    this.getSessionList(courseId, this.state.page);
    this.onGetSessionstatus(courseId);
    this.handleAllPrePostDetails();
  }

  componentDidUpdate(prevProps: any, prevState: any): void {
    if (prevState.page !== this.state.page) {
      const courseId = Number(this.props.navigation.getParam("courseId"));
      this.getSessionList(courseId, this.state.page);
      this.onGetSessionstatus(Number(this.props.navigation.getParam("courseId")))
    }
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const courseId = Number(this.props.navigation.getParam("courseId"));
    const sessionId = Number(this.props.navigation.getParam("sessionId"));
    const data = this.state.sessionListPageData.data;
    const pageCount = this.state.sessionListPageData.page_options?.total_pages;
    return (
      <SidebarLayout {...this.props}>
        <PageContentLayout goBack={this.goBack} title={configJSON.MindMasteryTexts.MindMastery} subtitle={configJSON.MindMasteryTexts.ConceptRevision}>
          {this.state.isLoading ? (
            <Loader loading={this.state.isLoading} />
          ) : null}
          <Box style={webStyles.sessionCardWrapper}>
            {data.filter((element: SessionElement) => Number(element.id) === sessionId).map((element:SessionElement) => {
              return (
                <SessionListPage
                  key={element.id}
                  sessionId={Number(element.id)}
                  courseId={courseId}
                  openModal={this.handleOpenModal}
                  attributes={element.attributes}
                  goTo={this.goTo}
                  handleParticularSessions={this.handleParticularSession}
                  data-test-id="sessionTestId"
                />
              );
            })}
            <Box style={webStyles.paginationWrapper}>
              <CommonPagination page={this.state.page} pageCount={pageCount} handlePage={this.handlePage} />
            </Box>
          </Box>
          <InfoModal data-test-id='info-modal' title={"Alert"} closeModal={this.handleClose} open={this.state.openModal} submitBtnText={'Okay'} info={this.state.modalInfo} modalType={this.state.modalType} />
          <InfoModal
            data-test-id='sucessModalTestId'
            info="Complete the pre program first, then you can start the course"
            modalType="warning"
            open={this.state.courseCompletion}
            closeModal={this.handleCloseCourseStatus} />
        </PageContentLayout>
      </SidebarLayout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  sessionCardWrapper: {
    padding: "32px 24px"
  },
  paginationWrapper: {
    marginTop: '30px'
  }
};
// Customizable Area End