import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Backdrop } from "@material-ui/core";

interface Props {
  loading: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    '& .MuiCircularProgress-root': {
      color: '#0056A3'
    }
  },
  backdrop: {
    '&.MuiBackdrop-root': {
      backgroundColor: 'rgba(8, 77, 149, 0.481206)'
    }
  }
}));

export default function Loader(props: Props) {
  const classes = useStyles();
  return props.loading ? (
    <Backdrop className={classes.backdrop} style={{ zIndex: 9999 }} open={props.loading}>
      <div className={classes.circularContainer}>
        <CircularProgress size='4rem' />
      </div>
    </Backdrop>
  ) : (
    <div />
  );
}
