import React from "react";
import Switch from '@material-ui/core/Switch';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 4,
    '&$checked': {
      transform: 'translateX(23px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: 'blue',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 16,
    height: 16,
    color: '#fff',
  },
  track: {
    borderRadius: 24 / 2,
    backgroundColor: 'blue',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}));

interface Props{
  checkQuestionView: () => void;
}
const ToggleSwitch = (props:Props) => {
  const classes = useStyles();
  return (
    <Switch
      onClick={props.checkQuestionView}
      defaultChecked
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
    />
  );
};
export default ToggleSwitch;