Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.dashboardGetApiMethod = "GET";
exports.dashboardPostApiMethod = "POST";
exports.dashboardPatchApiMethod = "PATCH";
exports.dashboardContentType = "application/json";
exports.getUserDetailsApiEndPoint = "user_by_token";
exports.getUserProgressEndPoint = "dashboards/progress";
exports.getMindMateryEndPoint = "dashboards/mind_mastery";
exports.getReportEndPoint = "dashboards/reports";
exports.getSuperPowersEndPoint = "dashboards/super_powers_collected";
exports.getNotesEndPoint = "dashboards/notes";
exports.getUpcomingEventsEndPoint = "dashboards/upcoming_events";
exports.getfeedbackCourseEndPoint = "student_feedbacks/courses_for_students";
exports.httpPostMethod = "POST";
exports.mpowerChecksList = "mpower_checks";
exports.guidelines = "guidelines";
exports.programSchedulerGetApiMethod = "GET";
exports.programSchedulerContentType = "application/json";
exports.mpowerQuestion = "MpowerQuestion";
exports.getTrainerStudentReportIndex = "bx_block_reports/student_reports";
exports.getheadingThemeAPI = "bx_block_reports/themes";
exports.postPublishEndPoint = "bx_block_reports/student_reports/publish";
exports.getReportBadgesEndPoint = "bx_block_reports/report_badges";
exports.ParentGuideApiEndPoint="parenting_guidlines"
exports.getProgrammeSummaryEndPoint = "bx_block_reports/student_reports/programme_summary";
exports.getSchoolDetailsEndPoint = "bx_block_school_dashboard/dashboards/school_detail";
exports.getOnGoingProgramsEndPoint= "bx_block_school_dashboard/dashboards/ongoing_programs";
exports.getOnGoingProgramsListEndPoint = "bx_block_school_dashboard/dashboards/ongoing_programs_list";
exports.getParentsDetails = 'bx_block_parents_dashboard/dashboards/profile_details';
exports.getParentsChildrenDetails = 'bx_block_parents_dashboard/dashboards/children_details';
exports.getCourseListApiEndPoint = "bx_block_reports/reports/school_course_options";
exports.getprogramImpactReportsEndPoint = "bx_block_reports/program_impact_reports";
exports.getCourseListApiPreAndPostEndPoint = "current_student_pre_post_courses";
exports.deleteMethod = "DELETE";
exports.updateMethod = "PUT";
exports.getGradeListEndPoint = "sections/filtered_sections";
exports.timelyProgressReportEndPoint = "bx_block_reports/timely_progress_reports";
exports.MaterialContentType = "application/json";
exports.MaterialApiMethodType = "GET";
exports.MaterialEndPoint = "training_materials";
exports.PrePostProgramSubmitEndPoint = "pre_post_program_answer";
exports.PrePostProgramGetApiEndPoint = "pre_post_program_questions";
exports.YouMatterScoreEndpoint = 'answers/you_matter_score';

exports.Feedback = {
  ReviewAndFeedback:'Review & Feedback',
  Success:'success',
  Thankyou:'Thank you!!',
  Alert:'Alert',
  Feedback: 'Feedback',
  CourseFeedback:'Course Feedback',
  PlsProvideFeedback:'Please provide your feedback on the quality of our Courses',
  EnterYourName:'Enter Your Name',
  FirstName:'firstName',
  FirstNameLabel:'First Name*',
  EnterYourLastName:'Enter Your LastName',
  LastName:'lastName',
  LastNameLabel:'Last Name*',
  SelectCourse:'Select Course',
  CourseNameLabel:'Course Name*',
  PlsSelectCourse:'Please select course',
  TrainerNamePh:'Trainer Name',
  TrainerName:'trainerName',
  TrainerNameLabel:'Trainer Name*',
  EnterEmailID:'Enter Email ID',
  Email:'email',
  EmailIDLabel:'Email ID*',
  FeedbackReportPath:'/school/feedback-report',
  TypeEmojiRating:'emoji_rating',
  OK:'OK',
  Awesome:'Awesome',
  TypeText:'text',
  PlsSelectRating:'Please select a Rating',
  WriteFeedback:'Write your Feedback here...',
  PlsEnterFeedback:'Please enter your Feedback',
  TrainerFeedback:'Trainer Feedback',
  PlsProvideTrainerFeedback:'Please provide your feedback on the quality of our Trainer',
  ThankYouMessage:'Thank you',
  CourseAlreadyTaken:'Mind mastery course has already been taken',
  SuccessSubmissionMsg:'We appreciate your valuable feedback',
  FeedbackAlreadySubmitted:'Feedback is already submitted for the selected course',
  SomethingWrong:'Something Went Wrong',
  getCourseFeedbackDataEndPoint:'student_feedbacks/courses_for_student',
  getCoursesubmissionEndPoint:'student_feedbacks/previous_submission',
  StudentFeedbacksEndPoint:'student_feedbacks',
  selectCourse: "Please select a course first"
};

exports.MPower = {
  TableHeader : ["Session Topic","Due Date","Status","Super Power",""],
  MPowerCheckGuidelines:'MPower Check Guidelines',
  NoGuidelines :'No guideline found',
  AgreeAndStart:'Agree & Start MPower Check',
  Published : 'published',
  Pending:'pending',
  MPowerModule:'Mpower',
  PreSubmission : "Please complete the Pre-Program and Mind Mastery courses first to unlock the Post-Program."
};

exports.AnalysisReport = {
  AcademicYearRequired:'Academic year required and This field cannot exceed the word limit',
  IntroductionRequired:'Introduction required and This field cannot exceed the word limit',
  ExecutiveSummaryRequired:'Executive summary required and This field cannot exceed the word limit',
  KeyConcernsRequired:'Key Concerns required and This field cannot exceed the word limit',
  FocusAreasRequired:'Focus areas required and This field cannot exceed the word limit',
  PriorityRequired:'Priority required and This field cannot exceed the word limit',
  SkillsetRequired:'Skillset required and This field cannot exceed the word limit',
  ConclusionRequired:'Conclusion required and This field cannot exceed the word limit',
  TextRequired:'Text required and This field cannot exceed the word limit',
  SummaryRequired:'Summary required or you have exceed the word limit',
  keyIncidentRequired:'Key Incident required and This field cannot exceed the word limit',
  ProgramNameLabel:'Program Name*',
  ProgramName:'ProgramName',
  EnterProgramName:'Enter Program Name',
  EnterSchoolName:'Enter School Name',
  SchoolNameLabel:'School Name*',
  SchoolName:'SchoolName',
  AcademicYear:'academicYear',
  EnterAcademicYear:'Enter Academic Year',
  AcademicYearLabel:'Academic Year*',
  ExecutiveSummaryLabel:'Executive Summary*',
  EnterExecutiveSummary:'Enter Executive Summary',
  enterKeyIncident:"Enter Key Incidents",
  keyIncidentLable:"Key Incident*",
  analysis:'Analysis',
  Executive:'Executive',
  incident:'incidents',
  IntroductionLabel:'Introduction*',
  Introduction:'introduction',
  EnterIntroduction:'Enter Introduction',
  RecommendationsAndRequirements:'Recommendations & Requirements',
  KeyConcernsLabel:'Key Concerns*',
  KeyConcerns:'keyConcerns',
  EnterKeyConcerns:'Enter Key Concerns',
  FocusAreasLabel:'Focus Areas*',
  FocusAreas:'focusAreas',
  EnterFocusAreas:'Enter Focus Areas',
  PriorityModulesLabel:'Priority Modules*',
  Priority:'priority',
  EnterPriorityModules:'Enter Priority Modules',
  SkillsetNeededLabel:'Skillset Needed*',
  Skillset:'skillset',
  EnterSkillsetNeeded:'Enter Skillset Needed',
  WayAhead:'Way Ahead',
  WayAheadSummaryLabel:'Way Ahead Summary*',
  Summary:'summary',
  EnterText:'Enter Text',
  Ahead:'Ahead',
  Conclusion:'Conclusion',
  SuccessfulSubmissionMsg:'Your valuable inputs have been submitted successfully!!',
  ThankYou:'Thank You!!',
  TableHeading:["Program Name","School Name","Academic Year","","Action"],
  SchoolHeading:["Course Name","Academic Year","","",""],
  NeedYearFilter:'NeedYearFilter',
  SelectAcademicYear:'Select Academic Year',
  SelectedCourse:'selectedCourse',
  SelectCourse:'Select Course',
  SureDelete:'Are you sure you want to Delete the Report?',
  StudentsAnalysisReport:'Students Analysis Report',
  NeedAnalysisReport:'Need Analysis Report',
  CreateYouMatter:'CreateYouMatter',
  AddReaderForm:'AddReaderForm',
  NeedAnalysisReportEndPoint:'bx_block_reports/need_analysis_reports',
  SchoolCourseOptionEndPoint:'bx_block_reports/reports/school_course_options'
};

exports.SidebarLayoutTexts = {
  ListView:'List View',
  QuestionView:'Question View',
  Section:'Section',
  AttemptSummary:'Attempt Summary',
  StudentsMPower:'Students MPower',
  StudentsYouMatter:'Students You Matter',
  AnswerQueries:'Answer Queries',
  TrainerProgramSchedulePath:'/trainer/program-schedule',
  TrainerMPowerPath:'/trainer/Mpower',
  TrainerYouMatter:'/trainer/you-matter',
  AnswerQueriesPath:'/trainer/queries',
  SchoolProgramSchedulePath:'/school/program-schedule',
  Report:'Reports',
  SchoolReportPath:'/school/report',
  FeedbackReport:'Feedback Report',
  SchoolFeedbackReportPath:'/school/feedback-report',
  ParentProgramSchedulePath:'/parent/program-schedule',
  ParentingGuide:'Parenting Guide',
  ParentingGuidePath:'/parent/guide',
  ParentMaterial:'Parent Material',
  ParentMaterialPath:'/parent/parent-material',
  ChildReport:'Child Report',
  ChildReportPath:'/parent/child-report',
  Assistance:'Assistance',
  AssistancePath:'/parent/assistance',
  UserDetails:'userDetails',
  TrainingMaterial:'Training Material',
  TrainerMaterialPath:'/trainer/trainer-material',
  TrainerReportPath:'/trainer/report',
  Dashboard:'Dashboard',
  DashboardPath:'/dashboard',
  ProgramSchedule:'Program Schedule',
  StudentProgramSchedulePath:'/student/program-schedule',
  MPowerCheck:'MPower Check',
  MPowerCheckPath:'/student/Mpower',
  MindMastery:'Mind Mastery',
  MindMasteryPath:'/mind-mastery',
  YouMatter:'You Matter',
  YouMatterPath:'/student/you-matter',
  SeekHelp:'Seek Help',
  SeekHelpPath:'/student/seek-help',
  ReviewAndFeedback:'Review & Feedback',
  ReviewAndFeedbackPath:'/student/feedback-ratings',
  RoleSchool:'School',
  RoleTrainer:'Trainer',
  RoleParents:'Parents'
};

exports.PageContentLayoutTexts = {
  AddNewYouMatter:'Add New You Matter',
  AddNewMPower:'Add New MPower',
  AddNewReport:'Add New report',
  UserDetails:'userDetails',
  PreProgram:'Pre Program',
  PostProgram:'Post Program',
  SelectProgramForm:'Select Program Form',
  SelectCourse:'Select Course',
  YouMatter:'you-matter',
  Document:'document',
  GeneralisedNoteForReports: 'Click on the Refresh button to view the generated reports',
};

exports.ProgramSchedule= {
  SelectCourse:'Select Course',
  SelectSchool:'Select School',
  SelectChild:'Select Child',
  Date:'Date',
  Time:'Time',
  CourseTopic:'Course Topic',
  SchoolName:'School Name',
  NoScheduledEventsOnTheCalendar:'No scheduled events on the calendar',
  Add:'Add',
  Edit:'Edit',
  Title:'title',
  EnterTitle:'Enter Title*',
  EnterCourse:'Enter Course*',
  Course:'course',
  SelectDate:'Select Date*',
  TypeDate:'date',
  StartTime:'Start Time*',
  TypeTime:'time',
  EndTime:'End Time*',
  School:'School*',
  Grade:'Grade*',
  Section:'Section*',
  Location:'location',
  EnterLocation:'Enter Location*',
  Notes:'notes',
  AddNotes:'Add Notes',
  Organizer:'organizer',
  OrganizerPh:'Organizer',
  Color:'Color',
  SchoolNameAndSectionNeeded:'School Name and Section needed!',
  StartTimeName: 'start_time',
  EndTimeName: 'end_time',
};

exports.MindMasteryTexts = {
  Sessions: 'Sessions',
  Online: 'Online',
  Offline:'Offline',
  Trainer: 'Trainer',
  AboutCourse: 'About Course',
  CourseStructure:'Course Structure',
  SessionListPage:'SessionsListPage',
  ViewAll: 'View All',
  Controls:[
    'play-large',
    'play',
    'progress',
    'current-time',
    'duration',
    'mute',
    'volume',
    'settings',
    'fullscreen'
  ],
  PlayBackSpeed:[
    0.5, 
    0.75,
    1, 
    1.25,
    1.5,
    1.75,
    2
  ],
  Video:'video',
  UpperCaseVideo:'Video',
  Session:'Session',
  VideoPageMindMastery:'VideoPageMindMastery',
  IceBreaker:'ice_breaker',
  WorkSheet:'worksheet',
  StartCourse:'Start Course',
  NoMindMasteryCourseFound:'No Mind Mastery Course Found',
  MindMastery:'Mind Mastery',
  ConceptRevision:'Concept Revision',
  CourseDetailsPage:'CourseDetailsPage',
  MindMasteryCourseEndPoint:'mind_mastery_courses',
  VideoViewPageData:'videoViewPageData',
  CourseSessions:'course_sessions',
  VideoAttachments:'video_attachments',
  CourseDetailsPageData:'courseDetailsPageData',
  Videos:'Videos',
  IceBreakerStatic:'Ice Breaker',
  WatchMe:'Watch me',
  Ponder:'Ponder',
  Engage:'Engage',
  Enhance: 'Enhance',
  ReadMe:'Read me',
  WorkWithMe:'Work with me',
  WorkSheetStatic:'Worksheet',
  ListType:'listType',
  CourseId:'courseId',
  SessionId:'sessionId',
  VideoId:'videoId',
  SessionAttachmentListPage:'SessionAttachmentListPage',
  Warning:'warning',
  NotAvailableForSession:'not available for this session',
  VideoIndex:'videoIndex',
  VideoPage:'VideoPage',
  VideoPlyrVideoId:'videoPlyrVideoId',
  NextVideos:'Next Videos',
  courseDetails: 'course_statuses',
  couserStatusPatch: 'course_statuses/update_status',
  sessionStatusPatch: 'course_session_statuses/update',
  getSessionStatus: 'course_session_statuses',
  nullYouMatterId: "You Matter for this session has not been created, please check after some time.",
  youMatterCompleted: "You Matter for this session is already submitted.",
  sessionCompleted: "You have successfully completed the session, now attend the You Matter."
};

exports.YouMatterTexts = {
  ChoiceId:'choice_id',
  StudentSubmission:'student_submission',
  NoQuestionsForCourse:'No questions available for the selected course',
  PleaseSelectAssignedCourse:'Please select assigned course to load the questions',
  ChoiceArr: [{ id: true, content: 'Yes' }, { id: false, content: 'No' }],
  Evaluated:'evaluated',
  SubmitPreProgram:'Submit Pre Program',
  SubmitPostProgram:'Submit Post Program',
  PreProgramType:'pre_program_form',
  PostProgramType:'post_program_form',
  CourseName:'Course Name',
  DueDate:'Due Date',
  SubmissionDate:'Submission Date',
  SubmissionStatus:'Submission Status',
  Published:'published',
  Submitted:'submitted',
  AttemptYouMatter:'Attempt you matter',
  PublishedYouMatter:'Published You matter',
  SubmittedYouMatter:'Submitted You matter',
  NoYouMatterFound:'No You Matter Found',
  FunChallenges:'Fun Challenges',
  YouMatter:'You Matter',
  YouMatterModule:'YouMatter',
  Correct:'correct',
  Cross:'cross',
  CorrectChecked:'correctChecked',
  CrossChecked:'crossChecked',
  PreProgramForm:'Pre Program Form',
  ProgramForm:'ProgramForm',
  PostProgramForm:'Post Program Form',
  PreProgram:'pre-program',
  PostProgram:'post-program',
  SelectCourseText:'Please select assigned course to load the questions',
  AwesomeYou:'Awesome you!!',
  SuccessMsg:'Your valuable inputs have been submitted successfully!',
  ConfirmMsg:'Are you sure you want to submit the',
  Success:'success',
  MPowerFormType:'mPowerFormType',
  True:'true',
  False:'false',
  DragAndDropText:'Drag & drop a file here, or click to select a file',
  PDF:'pdf',
  DOC:'doc',
  ApplicationPdf:'application/pdf',
  SubmitProgramForm:'Submit Program Form',
  Done:'Done',
  Cancel:'cancel',
  Of1Uploaded:'of 1 uploaded',
  Trainer:'Trainer',
  YourSolution :'Your Solution',
  StartYouMatter:'Start You Matter',
  YouMatterDetails:'You Matter Details',
  AttachFile:'Attach File',
  Close:'Close',
  Select:'Select',
  Enter:'Enter Your Solution',
  DragAndDropOptionsLabelTxt: 'Drag & Drop Options',
  CurrentSection:'currentSection',
  YouMatterId:'youMatterId',
  WriteYourAnswerHere:'Write your answer here',
  A:'A',
  B:'B',
  C:'C',
  D:'D',
  E:'E',
  F:'F',
  YouMatterQuestions:'YouMatterQuestions',
  PreProgramStatic:'Pre Program',
  PrePostEndPoint:'pre_post_courses',
  YouMatters:'you_matters',
  Section:'section',
  Answers:'answers',
  SubmitYourMatter:'Submit Your Matter',
  ChildName:'Child Name',
  AcademicYear:'Academic Year',
  SelectChild:'Select Child',
  NoReports:'No Reports Available',
  ChildReport:'Child Report',
  getChildrenApiEndPoint:'/parents/get_children',
  ParentShowReportEndPoint:'parents/show_reports',
  YouMatterScoreText: 'Your You Matter has been completed successfully and You have received',
};

exports.TrainerMPowerYouMatter = {
  ApiGradeName:'gradeName',
  ApiSchoolName:'schoolName',
  ApiSectionName:'sectionName',
  SuperPower:'Super Power',
  SelectSuperPower:'Select Super Power',
  Published:'published',
  DefaultTableHeader:['Name', 'Course Name', 'School Name', 'Grade', 'Activation Date', 'Due Date', 'Status'],
  DifficultyDropdownList:[{ id: 'easy', value: 'easy', title: 'Easy' }, { id: 'moderate', value: 'moderate', title: 'Moderate' }, { id: 'difficult', value: 'difficult', title: 'Difficult' }],
  MPowerCheckTableHeader:['Student Name', 'MPower Title', 'Submitted Date', 'Scores', 'Status', 'Badges', 'Action'],
  MPowerTableHeader:['Name', 'Course Name', 'School Name', 'Grade', 'Status'],
  YouMatterTitle:'You Matter Title',
  YouMatterCheckTableHeader:['Student Name', 'You Matter Title', 'Submitted Date', 'Scores', 'Status', 'Action'],
  Title:'title',
  Description: 'you_matter_details',
  EnterYouMatterTitle:'Enter You Matter Title',
  EnterYouMatterDescription: 'Enter You Matter Description',
  MpowerTitle:'MPower Title',
  Difficulty:'difficulty',
  EnterMpowerTitle:'Enter MPower Title',
  Form:'form',
  DifficultyInCaps:'Difficulty',
  DueDateInCaps:'Due Date',
  StartDateInCaps:'Start Date',
  DateType:'date',
  DueDate:'dueDate',
  YouMatterDetails:'You Matter Details',
  NoOfSections:'No. of Sections',
  StartDate:'startDate',
  MpowerDetails:'MPower Details',
  SectionsName:'Sections Name',
  EnterTotalQuestions:'Enter Total Questions',
  EnterSectionName:'Enter Section Name',
  NumberType:'number',
  Questions:'Questions',
  PublishNow:"Publish Now",
  Next:'Next',
  Confirm:'confirm',
  TrainerMpowerCheck:'TrainerMpowerCheck',
  TrainerYouMatterCheck:'TrainerYouMatterCheck',
  SelectSession:'Select Session',
  SelectCourse:'Select Course',
  SelectSchool:'Select School',
  SessionName:'sessionName',
  SelectSection:'Select Section',
  SelectGrade: 'Select Grade',
  Grade:'Grade',
  SchoolName:'schoolName',
  CourseName:'courseName',
  Course:'course',
  SectionName:'sectionName',
  School:'school',
  Session:'session',
  CourseInCaps:'Course',
  Section:'section',
  SchoolInCaps:'School',
  SessionInCaps:'Session',
  YouMatter:'You Matter',
  SectionInCaps:'Section',
  ConfirmPublishMsg:'Are you sure you want to Publish this',
  MpowerCheck:'MPower Check',
  Scheduled:'Scheduled',
  NoQuestionBankAlert:"For selected course, school and grade questions doesn't exists",
  Schedule:'Schedule',
  CreateNewMpower:'Create New MPower',
  CreateNewYouMatter:'Create New You Matter',
  MpowerDescription:'MPower Description',
  YouMatterDescription:'You Matter Description',
  IsReload:'isReload',
  YouMatterPath:'you-matter',
  StudentName:'Student Name',
  Badges:'Badges',
  SelectBadge:'Select Badge',
  Scores:'Scores',
  CorrectAnswer:'Correct Answer',
  TrainerYouMatterCheckDashoard:'TrainerYouMatterCheckDashoard',
  TrainerMpowerCheckDashoard:'TrainerMpowerCheckDashoard',
  SuccessFulEvaluationMsg:'Your Evaluation has been submitted successfully!',
  ConfirmEvaluationMsg:'Are you sure you want to submit the Evaluation?',
  Evaluated:'Evaluated',
  YouMatterChecks:'You Matter Checks',
  MpowerChecks:'MPower Checks',
  MPowerYouMatterId:'mpowerYouMatterId',
  StudentId:'studentId',
  UpdateChecks:'update-checks',
  Edit:'edit',
  EditDisable:'editDisable',
  Show:'show',
  ShowDisable:'showDisable',
  Name:'Name',
  Status:'Status',
  SchoolNameInCaps:'School Name',
  Action:'Action',
  MPowerTitle:'MPower Title',
  Sheet:'sheet',
  SubmittedDate:'Submitted Date',
  TrainerYouMatterEvaluation:'TrainerYouMatterEvaluation',
  TrainerMpowerEvaluation:'TrainerMpowerEvaluation',
  TrainerMpowerUpdateEvaluation:'TrainerMpowerUpdateEvaluation',
  Pen:'pen',
  NoRecords:'No records found',
  Pending:'pending',
  Submitted:'submitted',
  EvaluatedInLower:'evaluated',
  Filter:'filter',
  CourseFilter:'courseFilter',
  SchoolFilter:'schoolFilter',
  SectionFilter:'sectionFilter',
  CreateYouMatter:'CreateYouMatter',
  TrainerMpowerGuidelines:'TrainerMpowerGuidelines',
  StudentsYouMatter:'Students You Matter',
  StudentsMPower:'Students MPower',
  CheckboxChecked:'checkboxChecked',
  CheckBox:'checkbox',
  StudentMPower:'Student MPower',
  MPowerGuidelines:'MPower Guidelines',
  Guidelines:'guidelines',
  CreateResources:'create_resources',
  ImportQuestions:'import from question bank',
  NoImportedQuestions:'No Questions Imported',
  QuestionsInCaps:'Questions',
  Type:'Type',
  TrainerYouMatterQuestionList:'TrainerYouMatterQuestionList',
  TrainerMpowerQuestionList:'TrainerMpowerQuestionList',
  NoQuestions:'No questions',
  QuestionBankTabList:[{ id: 1, name: "MCQ's Questions" }, { id: 2, name: 'Subjective Questions' }, { id: 3, name: 'True False Questions' },{ id: 3, name: 'Puzzle' }, { id: 4, name: "Drag & Drop Questions"}],
  Checkbox:'checkbox',
  DownArrow:'downArrow',
  QuestionBank:'Question Bank',
  Alert:'Alert',
  QuestionBanksEndPoint:'question_banks',
  FilterResoures:'filter_resources',
  UpdateResource:'update_resource',
  QuestionAnswer:'question_answer',
  CreateMPower:'CreateMpower',
  BadgesEndPoint:'badges',
  TotalQuestions:'totalQuestions',
  SectionId:'sectionId',
  Option:{
    A:'A',
    B:'B',
    C:'C',
    D:'D',
    E:'E',
    F:'F'
  },
  ActivateLabel:'Activate after (In days)*',
  ActivateAfter:'activateAfter',
  Duration:'duration',
  ActivateAfterPlaceholder:'Activate after',
  DurationPlaceholder:'Duration',
  DurationLabel:'Duration (In days)*',
  InvalidDays:'Invalid value entered for days',
  Number:'number',
  OnlyValidNumbersRegex:/^(?!0\d+)\d+(\.\d+)?$/,
  DaysLimit:'Days must be less than 366',
  InvalidTotalQuestion:'Invalid value for total questions!',
  NoOfQuestionLessThan:'Total questions must be less than',
  DragAndDropOptionsLabelTxt: 'Drag & Drop Options',
  DragAndDropBucketInfoTxt: 'Drag and drop your answers in the below boxes',
  GeneralisedNoteForOptions: '* For every question, the correct answers are highlighted in blue.',
  DurationError: "The duration should be atleast of 1 day",
};

exports.ImpactReport = {
  TableHeader : ["Program Name", "School Name", "Academic Year", "Action"],
  ProgramName:'Program Name',
  EnterProgramName:'Enter Program Name',
  SchoolName:'School Name',
  EnterSchoolName:'Enter School Name',
  AcademicYear:'Academic Year',
  EnterAcademicYear:'Enter Academic Year',
  Overview:'Overview',
  ImpactMeasurement:'Impact Measurement',
  ImpactMeasurementPlaceholder:'Here, you can add a brief line explaining your data.',
  StoryPlaceholder:'Enter Stories of Impact',
  PreQuantiPlaceholder:'Enter Pre Quanti',
  PostQuantiPlaceholder:'Enter Post Quanti',
  PreStoryPlaceholder:'Enter Pre Stories of Impact',
  PostStoryPlaceholder:'Enter Post Stories of Impact',
  EnterMessage:'Enter Message',
  EnterFeedback:'Enter Feedback',
  enterImprovement:'Enter Improvement',
  improvementName:'improvement',
  feedbackName: "Feedback",
  improvementHead: "Improvement",
  errorMessage: "* This field can't be left empty!",
  AwesomeYou:'Awesome You!!',
  ConfirmMsg:'Are you sure you want to delete the report?',
  AcademicYearName:'academic_year',
  OverviewName:'overview',
  ImpactMeasurementName:'impactMeasurement',
  StoryName:'story',
  PreQuantiName:'pre_quanti',
  PostQuantiName:'post_quanti',
  PreStoryName:'pre_story_of_impact',
  PostStoryName:'post_story_of_impact',
  Msg:'msg',
  FeedbackName:'feedback_and_improvements',
  CallingPage:'proCommonStudentReportHeadergramImpactReport',
  ProgramImpactReport:'Program Impact Report',
  ImpactHeading:["Course Name","Academic Year","","","",""],
  SelectAcademicYear:'Select Academic Year',
  SelectCourse:'Select Course',
  ImpactReport:'Impact Report',
  CoursePir:'coursePir',
  YearPir:'yearPir',
  ImpactMeasurementKey:'impactMeasurement',
  SchoolPir:'schoolPir',
  PreStory:'pre_story',
  PostStory:'post_story',
  Feedback:'feedback',
  PirList:'pirList',
  ErrorMessageForStoryImpact: "* This field cannot exceed the word limit",
  ErrorMessageForEducator: "* This field cannot exceed the word limit",
  ErrorMessageForFeedback: "* This field cannot exceed the word limit",
  ErrorMessageForImprovement: "* This field cannot exceed the word limit",
};

exports.TimelyProgress = {
  ProgramName:'Program Name',
  EnterSchoolName:'Enter School Name',
  SchoolName:'School Name',
  EnterProgramName:'Enter Program Name',
  AcademicYear:'Academic Year',
  AcademicYearName:'academic_year',
  EnterAcademicYear:'Enter Academic Year',
  Overview:'Overview',
  OverviewName:'overview',
  ProgramTrack:'Program Track',
  EnterText:'Enter text',
  ProgramTrackName:'programTrack',
  Module:'module',
  EnterModuleName:'Enter module name',
  SelectGrade:'Select Grade',
  EnterValue:'Enter value',
  Attendance:'attendance',
  Message:'message',
  Recommendations:'recommendations',
  EnterMessage:'Enter Message',
  AwesomeYou:'Awesome You!!',
  Acknowledgement:'acknowledgement',
  SuccessfulMsg:'Your valuable inputs have been submitted successfully!',
  ConfirmMsg:'Are you sure you want to delete the report?',
  TimelyProgressReport:'Timely Progress Report',
  CallingPage:'proCommonStudentReportHeadergramImpactReport',
  TableHeaderSchTpr:["Course Name", "Academic Year", ""],
  CourseValueSchTpr:'courseValueSchTpr',
  YearValueSchTpr:'yearValueSchTpr',
  SelectCourseSchTpr:'SelectCourseSchTpr',
  SelectYearSchTpr:'SelectYearSchTpr',
  Participation:'Participation',
  FocusKey:'focus',
  ParticipationKey:'participation',
  Collaboration:'Collaboration',
  Focus:'Focus',
  CollaborationKey:'collaboration',
  RespectKey:'respect',
  Respect:'Respect',
  Initiative:'Initiative',
  InitiativeKey:'initiative',
  PositiveAttitude:'Positive attitude',
  PositiveAttitudeKey:'positive_attitude',
  CriticalThinkingKey:'critical_thinking',
  CriticalThinking:'Critical thinking',
  Perseverance:'Perseverance',
  PerseveranceKey:'perseverance',
  CreativityKey:'creativity',
  Creativity:'Creativity',
  EmpathyKey:'empathy',
  Students:'students',
  Empathy:'Empathy',
  Educators:'educators',
  Parents:'parents',
  AttendanceSecond:'attendanceSecond',
  AttendanceFirst:'attendanceFirst',
  School:'school',
  Course:'course',
  ProgramTrackKey:'programTrack',
  Year:'year',
  AttendanceFirstInput:'attendanceFirstInput',
  AttendanceFirstDropDown:'attendanceFirstDropDown',
  AttendanceSecondInput:'attendanceSecondInput',
  AttendanceSecondDropDown:'attendanceSecondDropDown',
  ErrorMessageLength: '* Minimum three fields required. Use the ' + ' icon to add more fields',
  CharacterError: "* This field cannot exceed the word limit",
  ErrorMessageForAllFeedback: "* This field can't be empty and Minimum three fields are required. Use the icon to add more fields"
};

exports.Report = {
  ProgramImpactReport:'Program Impact Report',
  ProgramImpactReportPath:'ProgramImpactReport',
  NeedAnalysisReport:'Need Analysis Report',
  NeedAnalysisReportPath:'AnalysisReport',
  TimelyProgressReport:'Timely Progress Report',
  TimelyProgressReportPath:'TimelyProgressReport',
  StudentReport:'Student Report',
  StudentReportPath:'TrainerStudentReport',
  NeedAnalysisPath:'/trainer/needanalysis-report',
  ImpactReport:'Impact Report',
  ImpactReportPath:'ImpactReport',
  StudentsAnalysisReport:'Students Analysis Report',
  SchoolAnalysisReportPath:'SchoolReport',
  SchooolTimelyProgressReportPath:'SchoolTimelyReport',
  Reports:"Reports",
  getImactDataEndPoint:'bx_block_reports/program_impact_reports',
  getNeedDataEndPoint:"bx_block_reports/need_analysis_reports",
  programImpactEndPoint:'bx_block_reports/program_impact_reports',
  CourseFilter:'courseFilter',
  YearFilter:'yearFilter',
  SelectHeadingTheme:'Select Heading Theme',
  SelectEndingTheme:'Select Ending Theme',
  SelectBadge:'Select Badge',
  StudentsReport:'Students Report'
};

exports.StudentsReport = {
  OnlyWhenMsg:'The report can only published once the evalution is completed for all the students.',
  AlreadyPublished:'These reports have already been published.',
  PublishedForAll:'Reports have been published for all students.',
  Failure:'failure',
  Success:'success',
  AttendenceName:'attendence',
  Attendence:'Attendence',
  WriteAttendenceHere:'Write Attendence here',
  AreasToFocusName:'areasToFocus',
  AreasToFocus:'Area to focus on',
  WriteYourFeedbackHere:'Write Your Feedback here',
  FieldNotEmpty:"* This field can't be left empty!",
  SuccessfulMsg:'Your valuable inputs have been submitted successfully!',
  AwesomeYou:'Awesome You!!',
  FeedbackForStudents:'Feedback for students',
  StudentsReport:'Students Report',
  SelectHeadingTheme:'Select Heading Theme',
  SelectEndingTheme:'Select Ending Theme',
  SelectBadge:'Select Badge',
  TableHeader:["Student Name", "Course Name", "School Name", "Grade", "Status", "Action"],
  Published:'Published',
  GetReportsEndPoint:'bx_block_reports/student_reports',
  postGenerateReportEndPoint: "bx_block_reports/student_reports/create_all",
  CourseFilterValue:'courseFilterValue',
  SchoolFilterValue:'schoolFilterValue',
  YearFilterValue:'yearFilterValue',
  GradeFilterValue:'gradeFilterValue',
  SectionFilterValue: 'sectionFilterValue',
  SelectYear:'Select Year',
  SelectCourse:'Select Course',
  SelectSchool:'Select School',
  SelectGrade:'Select Grade',
  SelectSection: 'Select Section',
  yearFilterValue: "Please select a year",
  courseFilterValue: "Please select a Course",
  schoolFilterValue: "Please select a School",
  gradeFilterValue: "Please select a Grade",
  sectionFilterValue: "Please select a Section",
  NoReports:'No Reports Available'
};

exports.SchoolFeedback = {
  ReviewAndFeedback:'Review & Feedback',
  EnterYourName:'Enter Your Name',
  SchoolName:'schoolname',
  SchoolNameLabel:'School Name',
  SelectCourse:'Select Course',
  CourseName:'Course Name',
  EmailPlaceholder:"Enter Email ID",
  Email:'Email',
  PlsProvideFeedback:'Please provide your feedback on the quality of our Courses',
  EventsDataApiEndPoint:'bx_block_school_feedback/school_feedbacks/courses_for_school',
  CourseFeedbackEndPoint:'bx_block_school_feedback/school_feedbacks',
  UpcommingEventsEndPoint:'bx_block_school_feedback/school_feedbacks/previous_submission'
};

exports.Material = {
  FilterResouresEndPoint:'filter_resources',
  TrainingMaterialEndPoint:'training_materials',
  ParentingMaterialEndPoint:'parenting_materials',
  CourseFilter:'courseFilter',
  SelectCourse:'Select Course',
  SchoolFilter:'schoolFilter',
  SelectSchool:'Select School',
  SectionFilter:'sectionFilter',
  SelectSection:'Select Section',
  SelectGrade:'Select Grade',
  ParentMaterial:'Parent Material',
  TrainingMaterial:'Training Material',
  ParentMaterialPath:'/parent/parent-material',
  PDF:'pdf',
  XML:'xml',
  Video:'video',
  Document:'document',
  VideoMp4:"video/mp4",
  VideoUrl:'videoUrl',
  NoParentGuidelines:'No Parent guidelines.',
  ParentingGuide:'Parenting Guide'
};

exports.Dashboard = {
  ParentMaterial:'ParentMaterial',
  TrainerMaterial:"TrainerMaterial",
  UserDetails:'userDetails',
  DataNotFound:'Data Not Found',
  SomethingWrong:'Something went wrong',
  UserLogout:'user is logged out from comet chat',
  ProfileUpdated:'Profile updated successsfully',
  LoginForm:'LoginForm',
  YouMatterTitle:'You Matter Title',
  AssignedCourse:'Assigned Courses',
  CompleteCourseToCollectSuperPower:'Complete course to collect superpowers',
  CompleteCourseToCollectRewards: 'Complete course to collect Rewards',
  OngoingProgram:'Ongoing Program',
  ProgramDuration:'Program Duration',
  MPowerTitle:'MPower Title',
  Date:'Date',
  Status: 'Status',
  CourseName:'Course Name',
  SchoolName:'School Name',
  Grade:'Grade',
  School:'School',
  Course: 'Course',
  ClearFilter: 'Clear Filter',
  ClearAll: 'Clear All',
  CreateMPower:'Create MPower',
  TrainerMPowerPath:"/trainer/mpower",
  StudentsYouMatter:'Students You Matter',
  Title:'Title',
  DueDate:'Due Date',
  TrainerYouMatterPath:'/trainer/you-matter',
  CreateYouMatter:'Create You Matter',
  Action:'Action',
  MPower:'MPower',
  MyProgress:'My Progress',
  TrainerUpcomingEventsEndPoint:'bx_block_trainer_dashboard/dashboards/upcoming_events',
  SchoolUpcomingEventsEndPoint:'bx_block_school_dashboard/dashboards/upcoming_events',
  ParentsUpcomingEventsEndPoint:'bx_block_parents_dashboard/dashboards/upcoming_events',
  StudentUpcomingEventsEndPoint:'dashboards/upcoming_events',
  getEventsEndPoint:'program_schedules',
  TrainerStudentMpowerEndPoint:'bx_block_trainer_dashboard/dashboards/student_mpower',
  TrainerStudentYouMatterEndPoint:'bx_block_trainer_dashboard/dashboards/student_you_matter',
  TrainerOngoingProgramsEndPoint:'bx_block_trainer_dashboard/dashboards/ongoing_programs',
  TrainerDetailsEndPoint:'bx_block_trainer_dashboard/dashboards/trainer_detail',
  UpcomingEvents:'Upcoming Events',
  NoUpcomingEvents:'No upcoming events scheduled',
  SuperpowersCollected:'Superpowers Collected',
  RewardsCollected: 'Rewards Collected',
  StudentsMPower:'Students MPower',
  Report:'Report',
  NoReportsFound: "No Reports Found",
  PasswordsMustBeSame:'Password and Confirm Password must be same',
  ConfirmPwd:'confirmPwd',
  updateUsersEndPoint:'users',
  Upper:'upper',
  Lower:'lower',
  Number:'number',
  SpecialChar:'specialChar',
  Length:'length',
  PWD:'pwd',
  NoScheduledUpcomingOrOngoingPrograms:'No scheduled upcoming or ongoing programs',
  OngoingProgramsList:'Ongoing Programs List',
  NumberOfPrograms:'Number of Programs',
  NoChildrenActivity:'No children activity',
  ChildrensActivity:"Children's Activity",
  Parent:'Parent',
  NoOngoingProgramsFound:'No ongoing programs found',
  OngoingPrograms:'Ongoing Programs',
  SchoolDashboardTableHeader:["Course Name", "Section", "Date", "Status"],
  studentErrorMessagre: "Enter a valid password which contain 8 character long,one uppercase letter, one lowercase letter, one digit & one special character",
  Superpower1: "Hap-Spark",
  Superpower2: "Hap-Beam",
  Superpower3: "Hap-Star",
};

exports.Profile = {
  AddressLine1:'addressLine1',
  AddressLine1Ph:'Address Line 1',
  AddressLine2:'addressLine2',
  AddressLine2Ph:'Address Line 2',
  State:'state',
  StatePh:'State',
  City:'city',
  CityPh:'City',
  Pincode:'pincode',
  PincodePh:'Pincode',
  Bio:'bio',
  BioPh:'Bio',
  Password:'Password',
  TempPwd:'tempPwd',
  ConfirmPwd:'confirmPwd',
  ConfirmPassword:'Confirm Password',
  ChildEmail:'childEmail',
  ChildEmailPh:"Child's Email ID",
  SchoolName:'schoolName',
  SchoolNamePh:'School Name',
  Class:'class',
  ClassPh:'Class',
  Section:'Section',
  ChildsClass:"Child's Class",
  FirstName:'firstName',
  FirstNamePh:'First Name',
  LastName:'lastName',
  LastNamePh:'Last Name',
  DOB:'DOB',
  DateofBirth:'Date of Birth',
  Gender:'gender',
  GenderPh:'Gender',
  Email:'email',
  EmailID:'Email ID',
  ParentEmail:'parent_email',
  ParentEmailID:'Parent Email ID',
  MobileNumber:'mobile_number',
  MobileNumberPh:'Mobile Number',
  School:'school',
  AAdhaar:'aadhaar',
  AdharCardNo:'Adhar Card No',
  MyProfile: "My Profile",
  confirmationMessage: "Are you sure you want to leave this page?",
  paswwordRequiredError: "Password is required",
  confirmPasswordError: "Confirm password is required",
  confirmErrorForPass: "Password and confirm password should be the same",
  letterLength: "Password should be at least 8 characters long, contain one uppercase letter, one lowercase letter, one digit, and one special character.",
  errorProfile: "*This field is required",
};

exports.SeekHelp = {
  Unpinit : 'unpinIt',
  AreYouSure:'Are you sure you want to remove this message?',
  DoYouWantToPin:'Do you want to Pin this message?',
  RemoveIt:'Remove it',
  Cancel:'Cancel',
  PinIt:'Pin it',
  ListPinMsgEndPoint:'bx_block_chat/chat_pinned_messages/list_of_pinned_messages',
  CurrentPinMessage:'currentPinMessage',
  PinnedMessages:'Pinned Messages',
  NoDataFound:'No data found',
  PinMessages:'pinMessages',
  AdminId:'admin_id',
  UserDetails:'userDetails',
  SuperAdmin:'super_admin',
  Role:'role',
  UnpinIt:'Unpin it'
};

exports.UserRole = {
  School:'School',
  Student:'Student',
  Trainer:'Trainer',
  Parents:'Parents',
  LowerTrainer:'trainer',
  LowerStudent:'student'
};

exports.ButtonTexts = {
  Yes:'Yes',
  No:'No',
  Save:'Save',
  Next:'Next',
  Prev:'Prev',
  Delete:'Delete',
  Cancel:'Cancel',
  Submit:'Submit'
};

exports.QuestionTypes = {
  TextBased:'text_based',
  Objective:'objective',
  TrueFalse:'true_false',
  Puzzle:'puzzle',
  DragAndDrop: 'drag_and_drop',
};

exports.ModalTypes = {
  Success:'success',
  Confirm:'confirm',
  Warning:'warning'
};

exports.sliderSettings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 4,
  autoplay: false,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: false,
        arrows: true,
        autoplay: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
        arrows: true,
        autoplay: false,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
        centerMode: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      },
    },
  ],
};

exports.formatDuration = (seconds) => {
  seconds = Math.floor(seconds);
  if (seconds < 60) {
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${formattedSeconds} sec${seconds === 1 ? "" : "s"}`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds} min${
      minutes === 1 && formattedSeconds === "00" ? "" : "s"
    }`;
  } else {
    const hours = Math.floor(seconds / 3600);
    const remainingMinutes = Math.floor((seconds % 3600) / 60);
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = remainingMinutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} hr${
      hours === 1 && formattedMinutes === "00" ? "" : "s"
    }`;
  }
};

exports.formatDropdownList = (elements) => {
  return elements?.map((element) => ({ title: element.name, value: element.id, id: element.id }));
};

exports.submitAnswer = "answers";
exports.devURL = 'https://hapchi1-302938-ruby.b302938.dev.eastus.az.svc.builder.cafe';
exports.ProgramColor = {
  "blue": {
    primary: "#0190FD",
    secondary: "#98D5FE"
  },
  "yellow": {
    primary: "#FFB74C",
    secondary: "#FFE3B8"
  },
  "red": {
    primary: "#F36769",
    secondary: "#FBC2C3"
  },
  "purple": {
    primary: "#9430A1",
    secondary: "#E69EF0"
  },
  "indigo": {
    primary: "#0056A3",
    secondary: "#4B90CD"
  }
}

exports.getComentChatId = "bx_block_chat/chats/user_list";
exports.submitGroupID = "bx_block_chat/chat_groups";
exports.pageElementCount = 10;
exports.groupPrefix = "group_";
exports.adminPrefix = "admin_";
exports.sendNotification = "bx_block_chat/chats/chat_notification";
// Customizable Area End
