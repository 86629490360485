import React from "react";
// Customizable Area Start
import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  TableBody,
  Link,
  IconButton,
  styled,
  Typography
} from "@material-ui/core";
import { removeQuestionIcon, editPenBlue, deleteAlertIcon, sheetBlue , sheetGrey} from '../../assets'
import AnalysisReportController from "./AnalysisReportController.web";
import { PageContentLayout } from "../../PageContentLayout.web";
import SidebarLayout from "../../SidebarLayout.web";
import CommonSelect from "../../../../../components/src/commonSelects.web";
import commonStyles from "../../../../../components/src/commonStyle.web";
import CommonPagination from "../../../../../components/src/Pagination.web";
import { useStyles } from "../ImpactReport.web";
import InfoModal from "../../../../../components/src/CommonInformationModal.web";
// Customizable Area End
const configJSON = require("../../config.js");
// Customizable Area Start
const ROW_PER_PAGE = 10;

export const tableStyle = {
  headerBox: {
    alignItems: "center",
    display: "flex",
    background:
      "linear-gradient(70deg, rgba(1,86,162,1) -36.53%, rgba(75,144,205,1) 66%, rgba(179,219,255,1) 146%)",
    borderRadius: "12px 12px 0px 0px",
    padding: "16px 24px",
  },
  mainBox: {
    maxWidth: "1108px",
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: "12px",

  },
  boxPadding: {
    padding: "24px",
  },
  mainTable: {
    borderCollapse: "separate",
    borderSpacing: "0px 4px"
  },
  tableHeadCell: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '27px',
    color: "#fff",
    "&:lastChild": {
      borderRadius: " 0 10px 10px 0",
    },
  },
  tableHeader: {
    backgroundColor: "0056A3",
    borderRadius: "8px",
    color: 'white',
    "&:lastChild": {
      borderRadius: " 0 10px 10px 0",
    },
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
};

export const TableHeading = configJSON.AnalysisReport.TableHeading;

export const SchoolHeading = configJSON.AnalysisReport.SchoolHeading;

export const SelectNeed = (props: any) => {
  const Needcourse = props.needlistCourse?.map((element: any) => {
    return {
      id: element,
      value: element,
      title: element,
    }
  })

  const NeedYear = props.needlistYear?.map((element: any) => {
    return {
      id: element,
      value: element,
      title: element,
    }
  })

  return (
    <Grid container spacing={2} style={{ marginBottom: '10px' }}>
      <Grid item xs={12} sm={6}>
        <CommonSelect
          name={configJSON.AnalysisReport.NeedYearFilter}
          value={props.NeedYearFilter}
          handleChange={props.NeedYearFilterChange}
          menuList={NeedYear}
          placeholder={configJSON.AnalysisReport.SelectAcademicYear}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CommonSelect
          name={configJSON.AnalysisReport.SelectedCourse}
          value={props.selectedCourse}
          handleChange={props.handleCourseSelectChange}
          menuList={Needcourse}
          placeholder={configJSON.AnalysisReport.SelectCourse}
        />
      </Grid>
    </Grid>
  )
}

export const Tablecontent = (props: any) => {
  const commonClasses = commonStyles({})
  return (
    <>
      <TableBody>
        {props.reader_list?.map((listData: any) => {
          return (
            <TableRow
              key={listData.id}
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                background: "#fff",
                borderRadius: "10px",
                outline: "1px solid #ddd",
              }}
            >
              <TableCell align="left">
                {listData?.attributes?.mind_mastery_course?.name}
              </TableCell>
              {props.userRole ? null :
                <TableCell align="left">
                  {listData?.attributes?.school?.name}
                </TableCell>
              }
              <TableCell align="left">
                {listData?.attributes?.academic_year}
              </TableCell>

              {
                props.userRole ? null : (
                  <TableCell align="left"> </TableCell>
                )
              }
              {
                props.userRole ? (
                  <>
                    <TableCell align="left"> </TableCell>
                    <TableCell align="left"> </TableCell>
                  </>

                ) : null
              }

              <TableCell align="left">
                <Box display={"flex"}>
                  <IconButton className={commonClasses.iconButton} >
                    <Link href={
                      listData?.attributes?.need_analysis_report_url
                    } target="_blank">
                      <img src={listData.attributes.need_analysis_report_url?sheetBlue : sheetGrey} alt="share" />
                    </Link>
                  </IconButton>
                  {
                    props.userRole ? null : (
                      <>
                        <IconButton className={commonClasses.iconButton}>
                          <img src={editPenBlue}
                            data-test-id="dataBody"
                            onClick={() =>
                              props.goTo("EditReaderForm", {
                                EditId: listData?.id
                              })
                            }
                            alt="edit"
                          />
                        </IconButton>
                        <IconButton className={commonClasses.iconButton}>
                          <img src={removeQuestionIcon}
                            data-test-id="dataBody"
                            onClick={() => props.handleopen(listData?.id)} alt="delete"
                          />
                        </IconButton>
                      </>
                    )
                  }
                </Box>
              </TableCell>
            </TableRow >
          )
        })}
      </TableBody >
      <InfoModal
        data-test-id="infoModal"
        modalType="confirm"
        title=""
        submitBtnText={configJSON.ButtonTexts.Delete}
        confirmImage={deleteAlertIcon}
        submit={props.handledelete}
        open={props.Deletesuccess}
        closeModal={props.handleClose}
        info={configJSON.AnalysisReport.SureDelete}
      />
    </>
  )
}

export const TableWrapper = (props: any) => {
  const classes = useStyles()
  return <TableContainer className={classes.tableContainer} data-test-id='table'>
    <Table className={classes.tableWrapper}  >
      <TableHead>
        <Tabletitle
          TableHeading={props.user?.attributes?.role === configJSON.UserRole.School ? SchoolHeading : TableHeading}
          tableHeader={tableStyle.tableHeader}
          tableHeadCell={tableStyle.tableHeadCell}
        />
      </TableHead>
      <Tablecontent
        data-test-id="tablecontent"
        {...props.analysisProps}
      />
    </Table>
  </TableContainer>
}
// Customizable Area End
class AnalysisReport extends AnalysisReportController {
  // Customizable Area Start
  goToNextPage = (page: number) => {
    this.setState({ firstRowIndex: (page - 1) * ROW_PER_PAGE });
    this.setState({ lastRowIndex: page * ROW_PER_PAGE });
  };

  render() {
    const path = window.location.pathname;
    const AnalysisReportLink = path.includes('report-analysis')
    const user = JSON.parse(`${localStorage.getItem('userDetails')}`);
    const usersControl = user?.attributes?.role === configJSON.UserRole.School

    const analysisProps = {
      reader_list: this.state.reader_list,
      Deletesuccess: this.state.Deletesuccess,
      handleClose: this.handleClose,
      handleopen: this.handleopen,
      handledelete: this.handledelete,
      goTo: this.goTo,
      userRole: usersControl
    }
    return <>
      <SidebarLayout {...this.props}>
        <PageContentLayout goBack={this.goBack} title={usersControl ? configJSON.AnalysisReport.StudentsAnalysisReport : configJSON.AnalysisReport.NeedAnalysisReport} handleReportRefresh={this.handleReportRefresh}
          needAnalysis={usersControl ? undefined : () => this.goTo(AnalysisReportLink ? configJSON.AnalysisReport.CreateYouMatter : configJSON.AnalysisReport.AddReaderForm)}>
          <Box style={tableStyle.boxPadding}>
            <GeneralisedNoteBox data-test-id="generalised-noted">
              <Typography>{configJSON.PageContentLayoutTexts.GeneralisedNoteForReports}</Typography>
            </GeneralisedNoteBox>
            {usersControl ?
              <SelectNeed
                needlistCourse={this.state.NeedlistCourse}
                selectedCourse={this.state.needFilterCourse?.value}
                handleCourseSelectChange={this.NeedcourseFilterChange}
                needlistYear={this.state.NeedListYear}
                NeedYearFilter={this.state.needFilterYear?.value}
                NeedYearFilterChange={this.NeedYearFilterChange}
              /> : null}
            <TableWrapper data-test-id="tableWrapper" analysisProps={analysisProps} user={user} />
            <CommonPagination data-test-id='pagination' pageCount={Math.ceil(
              this.state.totalReports / ROW_PER_PAGE
            )} page={this.state.pageNumber} handlePage={this.handlePageChange} />
          </Box>
        </PageContentLayout>
      </SidebarLayout>
    </>
  }
  // Customizable Area End
}
// Customizable Area Start
const GeneralisedNoteBox = styled(Box)({
  border: '0.2px solid #e3e3e3',
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
  borderRadius: '8px',
  marginBottom: '20px',
  padding: '10px'
});
export const Tabletitle = (props: any) => {
  return (
    <TableRow style={props.tableHeader} data-test-id='row'>
      {props.TableHeading?.map((val: any) => {
        return (
          <TableCell style={props.tableHeadCell} key={val}>
            {val}
          </TableCell>
        );
      })}
    </TableRow>
  )
}
// Customizable Area End
export default AnalysisReport;