import React, {useState} from "react";
import { Button, Box, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "react-native-elements";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
interface Props {
  icon?: string;
  ref?: React.Ref<HTMLInputElement>;
  callback?: () => void;
  ourPartner?:boolean
}
const useStyles = makeStyles<Theme, Props>((theme) => ({
  btn: {
    background: "#052B62",
    height: "64px",
    fontWeight: 600,
    padding: "0 20px",
    borderRadius: "32px",
    color: "#fff",
    textTransform: "capitalize",
    letterSpacing: "1px",
    fontSize: "16px",
    right: "20px",
    position: "fixed",
    zIndex: 9999,
    bottom: "20px",
    "&:hover": {
      background: "#052B62",
    },
  },
  arrowIcon: {
    paddingLeft: "15px",
  },
  upArrowBtn:{
    position: 'fixed',
    bottom: (props) => props.ourPartner ? '146px' : '90px',
    transform: "rotate(-90deg)",
    width: '42px',
    cursor: 'pointer',
    height: '42px',
    display: 'flex',
    zIndex: 9,
    background: "#052B62",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    border:'none',
    color:'#fff',
  },
  whatsAppIcon: {
    position: "fixed",
    bottom: (props) => props.ourPartner ? '94px' : '40px',
    right: "46px",
    background: "#052B62",
    height: "42",
    width: "42",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    zIndex:9999,
    cursor: 'pointer',
  },
}));
const FlotingButton = (props: Props) => {
  const classes = useStyles(props);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Box className={classes.whatsAppIcon}>
        <Link target="_blank" href="https://wa.me/916364004764">
         <WhatsAppIcon style={{ fontSize: "35px", color: "#fff" }} />
        </Link>
        <button
        className={classes.upArrowBtn}
        onClick={scrollToTop}
      >
       <DoubleArrowIcon/>
      </button>
      </Box>
      {props.ourPartner && (
        <Button className={classes.btn} onClick={props.callback}>
          Our Partners{" "}
          {props.icon != undefined && (
            <img
              src={props.icon}
              width="33px"
              height="33px"
              className={classes.arrowIcon}
            />
          )}
        </Button>
      )}
      
    </>
  );
};
export default FlotingButton;
