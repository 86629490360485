import React from "react";
// Customizable Area Start
// import { Stepper, Step } from "react-form-stepper";
// Customizable Area End

// Customizable Area Start
import {
    Box,
    FormLabel,
    Grid,
    TextField as InputField,
    InputAdornment,
    Link,
    Select,
    IconButton,
} from "@material-ui/core";
import PrimaryButton from "../../../components/src/PrimaryButtonCommonComponent.web";

import {
    imgProfile,
    arrowDown,
    lock,
    profileIcon,
    maleAndFemale,
    messageIcon,
    schoolIcon,
    mobileIcon,
    uploadIconAadhar,
} from "./assets";
import MenuItem from "@material-ui/core/MenuItem";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { usePopoverStyles, CalendarIcon } from "./CreateAccount.web";

import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
// Customizable Area End
import DateFnsUtils from "@date-io/moment";
// Customizable Area Start
interface CreateAccountPropsForSchool {
    handleSubmit: () => void;
    handleTnCPopup: (show?: boolean) => void;
    handleChange: (e: any) => void;
    handleChangeSelect: (menuType: string, value: any) => void;
    handleDOB: (date: any) => void;
    handleOpenDropZone: () => void;
    handleOpenAadharImageDropZone: () => void;
    handleClickShowPwd: () => void;
    handleClickShowConfirmPwd: () => void;
    handleNext: () => void;
    schools: any[];
    classList: any[];
    genderList: any[];
    states: any;
    setState: any;
    handlePwdChange: (e: any) => void;
    isSchool: boolean;
    isParent: boolean;
}

const inputAdornmentIcon = (iconName: string) => {
    switch (iconName) {
        case "lock":
            return <img style={webStyles.addornmentIcon} src={lock} alt="" />;
        case "person":
            return <img style={webStyles.addornmentIcon} src={profileIcon} alt="" />;
        case "message":
            return <img style={webStyles.addornmentIcon} src={messageIcon} alt="" />;
        case "gender":
            return <img style={webStyles.addornmentIcon} src={maleAndFemale} alt="" />;
        case "school":
            return <img style={webStyles.addornmentIcon} src={schoolIcon} alt="" />;
        case "mobile":
            return <img style={{ width: 19, height: 26 }} src={mobileIcon} alt="" />;
        case "upload":
            return (
                <img style={{ width: 24, height: 23 }} src={uploadIconAadhar} alt="" />
            );
    }
};

const UseStyles = makeStyles((theme) => ({
    cursor: {
        cursor: "pointer",
    },
    textField: {
        "&.MuiFormControl-root.MuiTextField-root": {
            [theme.breakpoints.down(600)]: {
                minWidth: "320px",
            },
            minWidth: "400px",
            width: "100%",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2",
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",

            height: "64px",
            width: "100%",
            [theme.breakpoints.down(600)]: {
                minWidth: "320px",
                maxWidth: "320px",
            },
            minWidth: "400px",
            maxWidth: "400px",
        },
    },
    textFields: {
        width: "100%",
        backgroundColor: "#fff",

        [theme.breakpoints.down(815)]: {
            minWidth: "400px",
            maxWidth: "400px",
        },
        [theme.breakpoints.down(600)]: {
            minWidth: "320px",
            maxWidth: "320px",
        },
    },
    formHeaderLabel: {
        color: "#0056A3",
        fontSize: "40px",
        [theme.breakpoints.down(600)]: {
            fontSize: "32px",
            display: "flex",
            justifyContent: "center",
        },
        fontWeight: "bold",
        margin: "24px auto",
    },
    formSubHeaderLabel: {
        paddingTop: 12,
        color: "#0056A3",
        fontSize: "24px",
        [theme.breakpoints.down(815)]: {
            fontSize: "20px",
            display: "flex",
            justifyContent: "center",
        },
        fontWeight: "bold",
        margin: "24px auto",
    },
    dropDown: {
        backgroundColor: "#fff",
        [theme.breakpoints.down(815)]: {
            minWidth: "320px",
        },
        [theme.breakpoints.down(600)]: {
            maxWidth: "320px",
        },

        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2",
        },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2",
        },
        "&.MuiOutlinedInput-root": {
            width: "400px",
            height: "64px",
            margin: "16px 0 8px 0",
        },
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "22px 0 22px 14px",
        },
    },
    sectionDropDown: {
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2",
        },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2",
        },
        "&.MuiOutlinedInput-root": {
            [theme.breakpoints.down(600)]: {
                width: "320px",
            },
            width: "400px",
            height: "64px",
            margin: "16px 0 8px 0",
        },
        "& .MuiOutlinedInput-inputMarginDense": {
            padding: "22px 0 22px 25px",
        },
    },
    menuItem: {
        "& .MuiFormControlLabel-root": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
        },
    },
    showAPIError: {
        color: "red",
        margin: "10px 0 10px 0",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        mixBlendMode: "normal" as "normal",
        opacity: 0.74,
    },
    gridContainer: {
        "&.MuiGrid-container": {
            maxWidth: "824px",
            margin: "16px auto 0 auto",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            "@media(max-width:815px)": {
                padding: "0 10px",
                justifyContent: "center",
            },
        },
    },
    gridItem: {
        "&.MuiGrid-item": {
            flexBasis: "auto",
        },
    },

    gridItemSchool: {
        "@media(max-width:815px)": {

            "&.MuiGrid-item": {
                flexBasis: "auto",
            },
        }
    },

    gridItemSmall: {
        height: 88,
        [theme.breakpoints.down(815)]: {
            "&.MuiGrid-item": {
                flexBasis: "auto",
            },
        },
    },
    pwdGridItem: {
        "&.MuiGrid-item": {
            flexBasis: "auto",
            [theme.breakpoints.down(600)]: {
                width: "320px",
            },
        },
    },
    dayWithDotContainer: {
        "& button": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "22px",
            textAlign: "center",
            color: "#3F526D",
            mixBlendMode: "normal",
            opacity: 0.7,
        },
        "& button.MuiPickersDay-daySelected": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "22px",
            textAlign: "center",
            color: "#FFFFFF",
            background:
                "linear-gradient(90deg, #09549F 0%, #09539E 0.54%, #000428 106.13%)",
            opacity: "1 !important",
            borderRadius: "4%",
        },
        "& button.MuiPickersDay-hidden": {
            opacity: "0.3 !important",
        },
    },
    createAccount: {
        "&.MuiButton-root": {
            position: "absolute",
            right: "0",
            borderRadius: "12px",
            height: "48px",
            width: "175px",
            backgroundColor: "#0056A3",
            color: "white",
            textTransform: "capitalize",
            display: "block",
            "@media(max-width:815px) and (min-width:580px)": {
                right: "38%",
                bottom:-65
            },
            "@media(max-width:580px)":{
                left: "28%",
                bottom: -65,
                right: "28%"
            }
        },
    },
    instructionBox: {
        color: "#4F4F4F",
        padding: "3px",
        fontSize: "9px",
        borderRadius: "4px",
    },
    uploadBtn: {
        "&.MuiButton-root": {
            width: "144px",
            height: "40px",
            borderRadius: "20px",
            backgroundColor: "#0056A3",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            top: "-24px",
            color: "white",
            position: "relative" as "relative",
        },
        "& .MuiButton-label": {
            textTransform: "capitalize",
        },
    },
    datePicker: {
        "& .MuiInput-root": {
            position:"relative",
            top: "16px",
            "@media(max-width:818px)": {
                top: "0",
                margin: "18 0",
            },
            "@media(max-width:818px) and (min-width:580px)": {
                right:"2px",
                width:400
            },
            "@media(max-width:580px)": {
                top: "0",
                margin: "18 0",
                right:"40px"
            },


        },
        "& .MuiInputAdornment-positionEnd": {
            marginRight: "8px",
        },
        "& .MuiInputBase-root": {
            flexDirection: "row-reverse",
        },
        "& .MuiInputBase-input": {
            paddingRight: "20px",
        },
        "& .MuiFormHelperText-root": {
            position: "relative",
            top: "33px",
            "@media(max-width:818px)": {
                top: "0",
            },
        },
        "& .MuiInput-underline::before": {
            content: "",
            backgroundColor: "#fff",
            position: "absolute",
            height: "64px",
            bottom: "-18px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "5px",
            [theme.breakpoints.down(600)]: {
                minWidth: "320px",
                maxWidth: "320px",
                flexBasis: "80%",
            },
            minWidth: "400px",
            zIndex: -1,
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            border: "1px solid #066CD2",
        },
        "& .MuiInput-underline::after": {
            content: "",
            position: "absolute",
            height: "64px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "5px",
            bottom: "-18px",
            transform: "scaleX(0)",
            transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        },
        "& .MuiInput-underline.Mui-focused::after": {
            border: "1px solid #066CD2",
        },
        "& .MuiInput-underline.Mui-error::after": {
            transform: "scaleX(1)",
            transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
            border: "1px solid red",
        },
    },
}));

const UsePopoverStyles = makeStyles({
    paper: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "27px",
        color: "#0056A3",
        border: "1px solid #066CD2",
        boxhadow: "0px 10px 16px rgba(165, 176, 194, 0.15)",
        borderRadius: "10px",
        "& .MuiPickersCalendarHeader-switchHeader": {
            borderBottom: "2px solid #0056A3",
            paddingTop: "12px",
            paddingBottom: "10px",
            flexDirection: "row-reverse",
        },
        "& .MuiPickersCalendarHeader-dayLabel": {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "15px",
            lineHeight: "22px",
            textAlign: "center",
            color: "#066CD2",
        },
        "& .MuiSvgIcon-root": {
            color: "#066CD2",
        },
        "& .MuiPickersCalendarHeader-iconButton:nth-of-type(1)": {
            order: 1,
        },
        "& .MuiPickersCalendarHeader-transitionContainer": {
            order: 2,
        },
    },
});

const SelectMenuIcon = () => {
    return <></>;
};

const getAvatar: (props:CreateAccountPropsForSchool) => string = (props:CreateAccountPropsForSchool) => {
    return props?.states?.tempAvatarKey as string || imgProfile as string;
}

const SchoolForm1 = (props: CreateAccountPropsForSchool) => {
    const { isSchool, isParent } = props;
    const disableFutureDates = (date: any) => {
        const currentDate = new Date();
        if (date > currentDate) {
            return true;
        }
        return false;
    };
    const renderDayInPicker = (
        date: any,
        selectedDate: any,
        dayInCurrentMonth: any,
        dayComponent: any
    ) => {
        return <div className={classes.dayWithDotContainer}>{dayComponent}</div>;
    };
    const popoverClasses = usePopoverStyles();
    const classes = UseStyles();
    const avatarImage = getAvatar(props);
    const profileImage = props?.states?.displayProfileUrl || avatarImage;
    return (
        <Grid container className={classes.gridContainer}>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                <div style={webStyles.picWrapper}>
                    <img
                        style={webStyles.uploadedImg}
                        src={profileImage}
                        alt="student-profile-img"
                    />
                </div>
                <PrimaryButton
                    data-test-id="uploadImage"
                    className={classes.uploadBtn}
                    onClick={props.handleOpenDropZone}
                >
                    Upload Image
                </PrimaryButton>
            </Grid>
            {isSchool && (
                <Grid
                    item
                    className={classes.gridItemSchool}
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <InputField
                        className={classes.textFields}
                        value={props.states.school}
                        name="school"
                        margin="normal"
                        data-test-id="school"
                        error={Boolean(props.states.errors.school)}
                        helperText={props.states.errors.school}
                        onChange={props.handleChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    style={webStyles.inputAdornment}
                                    position="start"
                                >
                                    {inputAdornmentIcon("school")}
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        fullWidth={false}
                        placeholder="School Name"
                        disabled={true}
                    />
                </Grid>
            )} 

            {!isSchool && (
                <>
                    <Grid
                        item
                        className={classes.gridItem}
                        sm={12}
                        xs={12}
                        md={12}
                        lg={6}
                        xl={6}
                    >
                        <InputField
                            className={classes.textField}
                            value={props.states.firstName}
                            name="firstName"
                            margin="normal"
                            data-test-id="firstName"
                            error={Boolean(props.states.errors.firstName)}
                            helperText={props.states.errors.firstName}
                            onChange={props.handleChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment style={webStyles.inputAdornment} position="start">
                                        {inputAdornmentIcon("person")}
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            fullWidth={false}
                            placeholder="First Name [POC]"
                            disabled={true}
                        />
                    </Grid>
                    <Grid
                        item
                        className={classes.gridItem}
                        sm={12}
                        xs={12}
                        md={12}
                        lg={6}
                        xl={6}
                    >
                        <InputField
                            value={props.states.lastName}
                            className={classes.textField}
                            name="lastName"
                            margin="normal"
                            onChange={props.handleChange}
                            error={Boolean(props.states.errors.lastName)}
                            helperText={props.states.errors.lastName}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment style={webStyles.inputAdornment} position="start">
                                        {inputAdornmentIcon("person")}
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            placeholder="Last Name [POC]"
                            disabled={true}
                        />
                    </Grid>
                    <Grid
                        item
                        className={classes.gridItem}
                        sm={12}
                        xs={12}
                        md={12}
                        lg={6}
                        xl={6}
                    >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                className={classes.datePicker}
                                disableToolbar
                                variant="inline"
                                format="DD/MM/YYYY"
                                margin="normal"
                                data-test-id="DOB"
                                shouldDisableDate={disableFutureDates}
                                PopoverProps={{ classes: popoverClasses }}
                                renderDay={renderDayInPicker}
                                error={Boolean(props.states.errors.dob)}
                                helperText={props.states.errors.dob}
                                keyboardIcon={<CalendarIcon />}
                                placeholder="Date of Birth"
                                value={props.states.datePickerProp}
                                onChange={props.handleDOB}
                                disabled={true}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    {!isParent ? (
                        <Grid
                            item
                            className={classes.gridItem}
                            sm={12}
                            xs={12}
                            md={12}
                            lg={6}
                            xl={6}
                        >
                            <InputField
                                value={props.states.gender}
                                className={classes.textField}
                                name="gender"
                                margin="normal"
                                onChange={props.handleChange}
                                error={Boolean(props.states.errors.gender)}
                                helperText={props.states.errors.gender}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            style={webStyles.inputAdornment}
                                            position="start"
                                        >
                                            {inputAdornmentIcon("gender")}
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                placeholder="Mobile Number"
                                disabled={true}
                            />
                        </Grid>
                    ) : (
                        <Grid
                            item
                            className={classes.gridItem}
                            sm={12}
                            xs={12}
                            md={12}
                            lg={6}
                            xl={6}
                        >
                            <InputField
                                value={props.states.mobileNo}
                                className={classes.textField}
                                name="mobileNo"
                                margin="normal"
                                onChange={props.handleChange}
                                error={Boolean(props.states.errors.mobileNo)}
                                helperText={props.states.errors.mobileNo}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            style={webStyles.inputAdornment}
                                            position="start"
                                        >
                                            {inputAdornmentIcon("mobile")}
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                placeholder="Mobile Number"
                                disabled={true}
                            />
                        </Grid>
                    )}
                </>
            )}

            <Grid
                item
                className={classes.gridItem}
                sm={12}
                xs={12}
                md={12}
                lg={6}
                xl={6}
            >
                <InputField
                    value={props.states.email}
                    className={classes.textField}
                    name="email"
                    margin="normal"
                    onChange={props.handleChange}
                    error={Boolean(props.states.errors.email)}
                    helperText={props.states.errors.email}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment style={webStyles.inputAdornment} position="start">
                                {inputAdornmentIcon("message")}
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    placeholder="Email ID"
                    disabled={true}
                />
            </Grid>
            {!isParent ? (
                <Grid
                    item
                    className={classes.gridItem}
                    sm={12}
                    xs={12}
                    md={12}
                    lg={6}
                    xl={6}
                >
                    <InputField
                        value={props.states.mobileNo}
                        className={classes.textField}
                        name="mobileNo"
                        margin="normal"
                        onChange={props.handleChange}
                        error={Boolean(props.states.errors.mobileNo)}
                        helperText={props.states.errors.mobileNo}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    style={webStyles.inputAdornment}
                                    position="start"
                                >
                                    {inputAdornmentIcon("mobile")}
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        placeholder="Mobile Number"
                        disabled={true}
                    />
                </Grid>
            ) : (
                <Grid
                    item
                    className={classes.gridItem}
                    sm={12}
                    xs={12}
                    md={12}
                    lg={6}
                    xl={6}
                >
                    <InputField
                        name="city"
                        className={classes.textField}
                        placeholder="City"
                        margin="normal"
                        variant="outlined"
                        data-test-id="city"
                        error={Boolean(props.states.errors.city)}
                        value={props.states.city}
                        onChange={props.handleChange}
                    />

                    <FormHelperText style={webStyles.error}>
                        {props.states.errors.city}
                    </FormHelperText>
                </Grid>
            )}

            <Grid
                item
                className={classes.pwdGridItem}
                sm={12}
                xs={12}
                md={12}
                lg={6}
                xl={6}
            >
                <InputField
                    data-test-id="password"
                    className={classes.textField}
                    name="tempPwd"
                    type={props.states.showTempPwd ? "text" : "password"}
                    margin="normal"
                    onChange={props.handlePwdChange}
                    error={Boolean(props.states.errors.passwordField)}
                    helperText={props.states.errors.passwordField}
                    inputProps={{
                        minLength: 8,
                        maxLength: 30,
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment style={webStyles.inputAdornment} position="start">
                                {inputAdornmentIcon("lock")}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={props.handleClickShowPwd}
                                    edge="end"
                                    id="btneye1"
                                >
                                    {props.states.showTempPwd ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    placeholder="Password"
                />
                <Box className={classes.instructionBox}>
                    At least{" "}
                    <text
                        style={{
                            color: props.states.rules.uppercase ? "#0056A3" : "inherit",
                        }}
                    >
                        one uppercase letter
                    </text>
                    ,{" "}
                    <text
                        style={{
                            color: props.states.rules.lowercase ? "#0056A3" : "inherit",
                        }}
                    >
                        one lowercase letter
                    </text>
                    ,{" "}
                    <text
                        style={{ color: props.states.rules.digit ? "#0056A3" : "inherit" }}
                    >
                        one digit
                    </text>{" "}
                    &{" "}
                    <text
                        style={{
                            color: props.states.rules.special ? "#0056A3" : "inherit",
                        }}
                    >
                        one special character
                    </text>
                </Box>
            </Grid>
            <Grid
                item
                className={classes.gridItem}
                sm={12}
                xs={12}
                md={12}
                lg={6}
                xl={6}
            >
                <InputField
                    className={classes.textField}
                    type={props.states.showConfirmPwd ? "text" : "password"}
                    margin="normal"
                    name="confirmPassword"
                    onChange={props.handleChange}
                    error={Boolean(
                        props.states.errors.confirmPassword ||
                        props.states.errors.notmatching
                    )}
                    helperText={
                        props.states.errors.confirmPassword ||
                        props.states.errors.notmatching
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment style={webStyles.inputAdornment} position="start">
                                {inputAdornmentIcon("lock")}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle confirm password visibility"
                                    onClick={props.handleClickShowConfirmPwd}
                                    edge="end"
                                    id="btneye2"
                                >
                                    {props.states.showConfirmPwd ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    placeholder="Confirm Password"
                />
            </Grid>

            <Grid item xl={12} sm={12} lg={12} md={12}>
                <PrimaryButton
                    onClick={props.handleNext}
                    variant="contained"
                    color="primary"
                    disabled={true}
                    data-test-id="submit"
                    className={classes.createAccount}
                >
                    Next
                </PrimaryButton>
            </Grid>
        </Grid>
    );
};

const createAccountForSchool = (props: CreateAccountPropsForSchool) => {
    const classes = UseStyles();

    const schoolForm2 = () => (
        <>
            {props.isParent && (
                <Grid container className={classes.gridContainer}>
                    {props.states?.childrens.length &&
                        props.states?.childrens.map((v: any, i: any) => (
                            <>
                                <Grid
                                    item

                                    style={{ marginTop: 10 }}
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <FormLabel className={classes.formSubHeaderLabel}>
                                        Child {i + 1}
                                    </FormLabel>
                                </Grid>
                                <Grid
                                    item
                                    className={classes.gridItemSmall}
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <InputField
                                        className={classes.textFields}
                                        value={v.email}
                                        name="childEmail"
                                        margin="normal"
                                        data-test-id="childEmail"
                                        error={Boolean(props.states.errors.pinCode)}
                                        helperText={props.states.errors.pinCode}
                                        onChange={props.handleChange}
                                        variant="outlined"
                                        fullWidth={false}
                                        placeholder="Child's Email ID"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    style={webStyles.inputAdornment}
                                                    position="start"
                                                >
                                                    {inputAdornmentIcon("message")}
                                                </InputAdornment>
                                            ),
                                        }}
                                        
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    className={classes.gridItem}
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    lg={6}
                                    xl={6}
                                >
                                    <InputField
                                        className={classes.textField}
                                        value={v.school_name}
                                        name="schoolName"
                                        margin="normal"
                                        data-test-id="schoolName"
                                        error={Boolean(props.states.errors.schoolName)}
                                        helperText={props.states.errors.schoolName}
                                        onChange={props.handleChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    style={webStyles.inputAdornment}
                                                    position="start"
                                                >
                                                    {inputAdornmentIcon("school")}
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                        fullWidth={false}
                                        placeholder="School Name"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    className={classes.gridItem}
                                    sm={12}
                                    xs={12}
                                    md={12}
                                    lg={6}
                                    xl={6}
                                >
                                    <InputField
                                        value={v.school_class}
                                        className={classes.textField}
                                        name="schoolClass"
                                        margin="normal"
                                        onChange={props.handleChange}
                                        error={Boolean(props.states.errors.schoolClass)}
                                        helperText={props.states.errors.schoolClass}
                                        variant="outlined"
                                        placeholder="Child's Class"
                                        disabled={true}
                                    />
                                </Grid>
                            </>
                        ))}
                    <Grid item xl={12} sm={12} lg={12} md={12}>
                        <div style={webStyles.privacyPolicy}>
                            By clicking the Create Account button, you agree to our{" "}
                            <Link
                                className={classes.cursor}
                                color="inherit"
                                onClick={() => props.handleTnCPopup()}
                            >
                                Terms
                                <span> &amp;</span> Conditions
                            </Link>{" "}
                            and{" "}
                            <Link
                                className={classes.cursor}
                                color="inherit"
                                onClick={() => props.handleTnCPopup(true)}
                            >
                                Privacy Policy.
                            </Link>
                        </div>
                        {props.states.showAPIError && (
                            <Box className={classes.showAPIError}>
                                {props.states.userRegistered
                                    ? "User has already been registered"
                                    : "We seem to be facing some issues, please try again"}
                            </Box>
                        )}
                    </Grid>
                    <Grid item xl={12} sm={12} lg={12} md={12}>
                        <PrimaryButton
                            onClick={props.handleSubmit}
                            variant="contained"
                            color="primary"
                            disabled={true}
                            data-test-id="submit"
                            className={classes.createAccount}
                        >
                            Create Account
                        </PrimaryButton>
                    </Grid>
                </Grid>
            )}

            {!props.isParent && (
                <Grid container className={classes.gridContainer}>
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <FormLabel className={classes.formSubHeaderLabel}>
                            Address
                        </FormLabel>
                    </Grid>
                    <Grid
                        item
                        className={classes.gridItem}
                        sm={12}
                        xs={12}
                        md={12}
                        lg={6}
                        xl={6}
                    >
                        <InputField
                            className={classes.textField}
                            value={props.states.addressLine1}
                            name="addressLine1"
                            margin="normal"
                            data-test-id="addressLine1"
                            error={Boolean(props.states.errors.addressLine1)}
                            helperText={props.states.errors.addressLine1}
                            onChange={props.handleChange}
                            variant="outlined"
                            fullWidth={false}
                            placeholder="Address Line 1"
                        />
                    </Grid>
                    <Grid
                        item
                        className={classes.gridItem}
                        sm={12}
                        xs={12}
                        md={12}
                        lg={6}
                        xl={6}
                    >
                        <InputField
                            value={props.states.addressLine2}
                            className={classes.textField}
                            name="addressLine2"
                            margin="normal"
                            onChange={props.handleChange}
                            error={Boolean(props.states.errors.addressLine2)}
                            helperText={props.states.errors.addressLine2}
                            variant="outlined"
                            placeholder="Address Line 2"
                        />
                    </Grid>
                    <Grid
                        item
                        className={classes.gridItem}
                        sm={12}
                        xs={12}
                        md={12}
                        lg={6}
                        xl={6}
                    >
                        <InputField
                            name="city"
                            className={classes.textField}
                            placeholder="City"
                            margin="dense"
                            variant="outlined"
                            data-test-id="city"
                            error={Boolean(props.states.errors.city)}
                            onChange={props.handleChange}
                            helperText={props.states.errors.city}
                            value={props.states.city}
                            
                        />
                    </Grid>{" "}
                    <Grid
                        item
                        className={classes.gridItem}
                        sm={12}
                        xs={12}
                        md={12}
                        lg={6}
                        xl={6}
                    >
                        <InputField
                            name="state"
                            className={classes.textField}
                            placeholder="State"
                            margin="dense"
                            variant="outlined"
                            data-test-id="state"
                            error={Boolean(props.states.errors.state)}
                            onChange={props.handleChange}
                            helperText={props.states.errors.state}
                            value={props.states.state}/>
                    </Grid>
                    <Grid
                        item
                        className={classes.gridItemSmall}
                        sm={12}
                        xs={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <InputField
                            className={classes.textFields}
                            value={props.states.pinCode}
                            name="pinCode"
                            margin="normal"
                            data-test-id="pinCode"
                            error={Boolean(props.states.errors.pinCode)}
                            helperText={props.states.errors.pinCode}
                            onChange={props.handleChange}
                            variant="outlined"
                            fullWidth={false}
                            placeholder="Pin Code"
                        />
                    </Grid>
                    {!props.isSchool && (
                        <>
                            <Grid
                                item
                                sm={12}
                                xs={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <FormLabel className={classes.formSubHeaderLabel}>
                                    ID Proof
                                </FormLabel>
                            </Grid>
                            <Grid
                                item
                                className={classes.gridItem}
                                sm={12}
                                xs={12}
                                md={12}
                                lg={6}
                                xl={6}
                            >
                                <InputField
                                    className={classes.textField}
                                    value={props.states.aadharCardNumber}
                                    name="aadharCardNumber"
                                    margin="normal"
                                    data-test-id="aadhar_card_number"
                                    error={Boolean(props.states.errors.aadharCardNumber)}
                                    helperText={props.states.errors.aadharCardNumber}
                                    onChange={props.handleChange}
                                    variant="outlined"
                                    fullWidth={false}
                                    placeholder="Enter Addhar Card No"
                                />
                            </Grid>
                            <Grid
                                item
                                className={classes.gridItem}
                                sm={12}
                                xs={12}
                                md={12}
                                lg={6}
                                xl={6}
                            >
                                <InputField
                                    value={props.states.aadharImageName}
                                    className={classes.textField}
                                    name="aadharImageName"
                                    margin="normal"
                                    onChange={props.handleChange}
                                    error={Boolean(props.states.errors.aadharImageName)}
                                    helperText={props.states.errors.aadharImageName}
                                    variant="outlined"
                                    placeholder="Upload Addhar Card"
                                    disabled={true}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    data-test-id="aadharUploadImage"
                                                    onClick={props.handleOpenAadharImageDropZone}
                                                    edge="end"
                                                    id="btneye1"
                                                >
                                                    {inputAdornmentIcon("upload")}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xl={12} sm={12} lg={12} md={12}>
                        <div style={webStyles.privacyPolicy}>
                            By clicking the Create Account button, you agree to our{" "}
                            <Link
                                className={classes.cursor}
                                color="inherit"
                                onClick={() => props.handleTnCPopup()}
                            >
                                Terms
                                <span> &amp;</span> Conditions
                            </Link>{" "}
                            and{" "}
                            <Link
                                className={classes.cursor}
                                color="inherit"
                                onClick={() => props.handleTnCPopup(true)}
                            >
                                Privacy Policy.
                            </Link>
                        </div>
                        {props.states.showAPIError && (
                            <Box className={classes.showAPIError}>
                                {props.states.userRegistered
                                    ? "User has already been registered"
                                    : "We seem to be facing some issues, please try again"}
                            </Box>
                        )}
                    </Grid>
                    <Grid item xl={12} sm={12} lg={12} md={12}>
                        <PrimaryButton
                            onClick={props.handleSubmit}
                            variant="contained"
                            color="primary"
                            disabled={true}
                            data-test-id="submit"
                            className={classes.createAccount}
                        >
                            Create Account
                        </PrimaryButton>
                    </Grid>
                </Grid>
            )}
        </>
    );

    return (
        <Box style={webStyles.signupStepWrapper}>
            <FormLabel className={classes.formHeaderLabel}>
                Create {props.states.accountType} Account
            </FormLabel>
            {props.states.schoolNextForm ? <SchoolForm1 {...props} /> : schoolForm2()}
        </Box>
    );
};
// Customizable Area End
export default createAccountForSchool;
// Customizable Area Start
const webStyles = {
    privacyPolicy: {
        color: "#0056A3",
        margin: "10px 0 48px 0",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        mixBlendMode: "normal" as "normal",
        opacity: 0.74,
    },
    addornmentIcon: { width: "24px" },
    signupStepWrapper: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column" as "column",
        alignItems: "center",
    },
    error: { color: "red" },
    customRadioLabelImg: { width: "45px", height: "45px" },
    uploadedImg: { width: "64px", height: "64px", borderRadius: "50%" },
    picWrapper: {
        width: "64px",
        height: "64px",
        borderRadius: "50%",
        border: "1px solid #0056A3",
        display: "inline-block",
        marginRight: "25px",
    },
    fileUploadInput: { display: "none" },
    uploadLabel: {
        width: "144px",
        height: "40px",
        borderRadius: "20px",
        backgroundColor: "#0056A3",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        top: "-24px",
        color: "white",
        position: "relative" as "relative",
    },
    inputAdornment: {
        width: "48px",
        height: "48px",
        display: "flex",
        justifyContent: "center",
    },
    arrowDownImage: {
        width: "16px",
        height: "9px",
        position: "absolute" as "absolute",
        right: "27px",
    },
};
// Customizable Area End
