import React, { Component } from 'react';
import RootContext from './RootContext';


class RootContextWrapper extends Component {

    state = {};

    setGlobal = (index: any, newData: any) => {
        this.setState({
            [index]: newData,
        });
    };

    render() {
        const { children } = this.props;
        return (
            <RootContext.Provider
                value={{
                    ...this.state,
                    setGlobal: this.setGlobal
                } as any}>
                {children}
            </RootContext.Provider>
        );
    }
}

export default RootContextWrapper;
