import React from 'react'
// Customizable Area Start
import SidebarLayout from "../../SidebarLayout.web";
import { Box, Grid, IconButton,Button, styled, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CommonPagination from '../../../../../components/src/Pagination.web';
import TrainerStudentReportController, { S, configJSON } from './StudentsReportListController.web';
import { editPenBlue, PublishedSuccess, sheetBlue, sheetGrey } from "../../assets"
import "./EditStudentReport.css";
import PageContentLayout from '../../PageContentLayout.web';
import commonStyles from '../../../../../components/src/commonStyle.web';
import InfoModal from '../../../../../components/src/CommonInformationModal.web';
import CommonSelect from '../../../../../components/src/commonSelect.web';
import Loader from '../../../../../components/src/Loader.web';
// Customizable Area End

// Customizable Area Start
export const useStyles = makeStyles({
    pageWrapper: {
        padding: '32px 24px 24px 24px',
    },
    dropDown: {
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2"
        },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2"
        },
        "& .MuiOutlinedInput-inputMarginDense": {
            paddingLeft: '14px'
        },
        "&.MuiInputBase-root.makeStyles-dropDown-84.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-marginDense.MuiOutlinedInput-marginDense": {
            height: "3.33vw !important"
        },
        '& .MuiSelect-outlined.MuiSelect-outlined': {
            width: 'calc(100% - 60px)',
            '&:hover': {
                width: '100%'
            },
            paddingRight: '2px',
        },
        "&.MuiOutlinedInput-root": {
            backgroundColor: '#ffffff4f',
            borderRadius: '8px',
            width: "100%",
            height: "48px",
            fontSize: '14px',
            border: '1px solid #DEDDE4',
            marginLeft: 'auto',
            marginRight: '24px',
            color: 'black'
        }
    },
    menuItems: {
        '&.MuiListItem-root.Mui-selected': {
            backgroundColor: '#0056a314'
        },
        '&.MuiListItem-root:hover': {
            backgroundColor: '#0056a314'
        },
        "&.MuiMenuItem-root": {
            justifyContent: "space-between",
            width: "100%",
            display: "flex"

        }
    },
    tableHead: {
        '&.MuiGrid-container': {
            background: '#0056A3',
            borderRadius: '8px',
            color: 'white',
            fontFamily: 'Poppins',
            fontWeight: 700,
            fontSize: '1.25vw',
            lineHeight: '1.875vw',
            justifyContent: 'space-between',
            padding: '1.389vw 1.667vw',
            marginBottom: '16px'
        }
    },
    tableRow: {
        '&.MuiGrid-container': {
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
            borderRadius: '8px',
            border: '1px solid #e3e3e3',
            justifyContent: 'space-between',
            marginBottom: '8px',
            padding: '1.389vw 1.667vw'
        }
    },
    headerCellStudentReport: {
        '&.MuiGrid-item': {
            padding: '0 0.694vw',
            overflow: 'hidden',
            margin: 'auto',
            display: '-webkit-box',
            '-webkitLineClamp': 2,
            '-webkitBoxOrient': 'vertical',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
            fontSize: "1.319vw"
        },
        '&.MuiGrid-grid-xs-true': {
            flexBasis: '16.667%',
            flexGrow: "0"
        }
    },
    headerCell: {
        '&.MuiGrid-item': {
            padding: '0 0.694vw',
            overflow: 'hidden',
            margin: 'auto',
            display: '-webkit-box',
            '-webkitLineClamp': 2,
            '-webkitBoxOrient': 'vertical',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
            fontSize: "1.319vw"
        },
        '&.MuiGrid-grid-xs-true': {
            flexBasis: '25%',
        }
    },
    completeStatusCell: {
        '&.MuiGrid-item': {
            color: '#008A00',
            margin: 'auto',
        }
    },
    pendingStatusCell: {
        '&.MuiGrid-item': {
            color: '#FF9300',
            margin: 'auto',
        }
    },
    dropDownWrapper: {
        marginBottom: '12px'
    },
    tableWrapper: {
        marginBottom: '36px'
    },
    editIconsContainer: {
        textAlign: "center"
    },
    editIconsContainerImpactReport: {
        textAlign: "left"
    },
})

const GeneralisedNoteBox = styled(Box)({
    border: '0.2px solid #e3e3e3',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
    borderRadius: '8px',
    marginBottom: '20px',
    padding: '10px'
});

export const TrainerStudentReportPage = (props: any) => {
    const classes = useStyles();
    const commonClasses = commonStyles({})
    const nuberedPages = props.data.reports_count / configJSON.pageElementCount;
    let tableHeader = configJSON.StudentsReport.TableHeader;
    return <>
        <Box className={classes.pageWrapper}>
            <GeneralisedNoteBox data-test-id="generalised-noted">
                <Typography>{configJSON.PageContentLayoutTexts.GeneralisedNoteForReports}</Typography>
            </GeneralisedNoteBox>
            <Grid container className={classes.dropDownWrapper} spacing={2}>
                <Grid item lg={3} xl={3} md={3} sm={6} xs={12}>
                    <CommonSelect
                        data-test-id='year-filter'
                        handleChange={props.handleDropDownValueChange}
                        name={configJSON.StudentsReport.YearFilterValue}
                        onClear={() => props.clearFilter(configJSON.StudentsReport.YearFilterValue)}
                        value={props.states.yearFilterValue}
                        placeholder={configJSON.StudentsReport.SelectYear}
                        menuList={props.states.yearFilterOption}
                        error={props.states.validationErrors?.yearFilterValue}
                    />
                    {props.states.validationErrors?.yearFilterValue && <Grid><ErrorStyle>{configJSON.StudentsReport.yearFilterValue}</ErrorStyle></Grid>}
                </Grid>
                <Grid item lg={3} xl={3} md={3} sm={6} xs={12}>
                    <CommonSelect
                        data-test-id='course-filter'
                        handleChange={props.handleDropDownValueChange}
                        name={configJSON.StudentsReport.CourseFilterValue}
                        onClear={() => props.clearFilter(configJSON.StudentsReport.CourseFilterValue)}
                        value={props.states.courseFilterValue}
                        placeholder={configJSON.StudentsReport.SelectCourse}
                        menuList={props.states.courseFilterOption}
                        error={props.states.validationErrors?.courseFilterValue}
                    />
                    {props.states.validationErrors?.courseFilterValue && <Grid><ErrorStyle>{configJSON.StudentsReport.courseFilterValue}</ErrorStyle></Grid>}
                </Grid>
                <Grid item lg={3} xl={3} md={3} sm={6} xs={12}>
                    <CommonSelect
                        data-test-id='school-filter'
                        handleChange={props.handleDropDownValueChange}
                        name={configJSON.StudentsReport.SchoolFilterValue}
                        onClear={() => props.clearFilter(configJSON.StudentsReport.SchoolFilterValue)}
                        value={props.states.schoolFilterValue}
                        placeholder={configJSON.StudentsReport.SelectSchool}
                        menuList={props.states.schoolFilterOption}
                        error={props.states.validationErrors?.schoolFilterValue}
                    />
                    {props.states.validationErrors?.schoolFilterValue && <Grid><ErrorStyle>{configJSON.StudentsReport.schoolFilterValue}</ErrorStyle></Grid>}
                </Grid>
                <Grid item lg={3} xl={3} md={3} sm={6} xs={12}>
                    <CommonSelect
                        data-test-id='grade-filter'
                        handleChange={props.handleDropDownValueChange}
                        name={configJSON.StudentsReport.GradeFilterValue}
                        onClear={() => props.clearFilter(configJSON.StudentsReport.GradeFilterValue)}
                        value={props.states.gradeFilterValue}
                        placeholder={configJSON.StudentsReport.SelectGrade}
                        menuList={props.states.gradeFilterOption}
                        error={props.states.validationErrors?.gradeFilterValue}
                    />
                    {props.states.validationErrors?.gradeFilterValue && <Grid><ErrorStyle>{configJSON.StudentsReport.gradeFilterValue}</ErrorStyle></Grid>}
                </Grid>
                <Grid item lg={3} xl={3} md={3} sm={6} xs={12}>
                    <CommonSelect
                        data-test-id='section-filter'
                        handleChange={props.handleDropDownValueChange}
                        name={configJSON.StudentsReport.SectionFilterValue}
                        onClear={() => props.clearFilter(configJSON.StudentsReport.SectionFilterValue)}
                        value={props.states.sectionFilterValue}
                        placeholder={configJSON.StudentsReport.SelectSection}
                        menuList={props.states.sectionFilterOption}
                        error={props.states.validationErrors?.sectionFilterValue}
                    />
                    {props.states.validationErrors?.sectionFilterValue && <Grid><ErrorStyle>{configJSON.StudentsReport.sectionFilterValue}</ErrorStyle></Grid>}
                </Grid>

            </Grid>
            <StudentReportStyle>
                <Button className="addMpowerYouMatterBtn" data-test-id="generateReportTestId" onClick={props.handleGenerateReports}>Generate Report</Button>
            </StudentReportStyle>
            <Box className={commonClasses.tableWrapper} >
                <Box>
                    <Grid container className={commonClasses.tableHead}>
                        {tableHeader.map((element: any) => (<Grid key={element} item xs={true} className={commonClasses.cell} >{element}</Grid>))}
                    </Grid>
                    <Grid>
                        {props.data.reports?.length > 0 ? props.data.reports?.map((row: any) => {
                            const attributes = row;
                            return <Grid key={attributes.student_id} container className={commonClasses.tableRow} >
                                <Grid item xs={true} className={commonClasses.cell}>{attributes.name}</Grid>
                                <Grid item xs={true} className={commonClasses.cell}>{attributes.mind_mastery_course.title}</Grid>
                                <Grid item xs={true} className={commonClasses.cell}>{attributes.school.name}</Grid>
                                <Grid item xs={true} className={commonClasses.cell}>{`${attributes.grade.name}-${attributes.section.name}`}</Grid>
                                <Grid item xs={true} className={attributes.status.toLowerCase() === 'pending' ? classes.pendingStatusCell : classes.completeStatusCell}>{attributes.status}</Grid>
                                <Grid item xs={true} className={commonClasses.cell} >
                                    <IconButton className={commonClasses.iconButton}>
                                        <a style={attributes.status.toLowerCase() === 'pending' ? { pointerEvents: "none" } as any : null} href={attributes.pdf_url} target="_blank" rel="noopener noreferrer">
                                            <img
                                                data-test-id="pdfIcon"
                                                src={attributes.status.toLowerCase() === 'pending' ? sheetGrey : sheetBlue}
                                            />
                                        </a>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        }) : configJSON.StudentsReport.NoReports}
                    </Grid>
                </Box>
            </Box>
            <CommonPagination page={props.pageNo} handlePage={props.handlePageChange} pageCount={nuberedPages < 1 ? 1 : Math.ceil(nuberedPages)} />
        </Box >
    </>
}
// Customizable Area End

export default class TrainerStudentReport extends TrainerStudentReportController {
   // Customizable Area Start
    async componentDidMount() {
        this.getStudentReportIndex();
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<S>): void {
        if (prevState.courseFilterValue !== this.state.courseFilterValue ||
            prevState.yearFilterValue !== this.state.yearFilterValue ||
            prevState.gradeFilterValue !== this.state.gradeFilterValue ||
            prevState.schoolFilterValue !== this.state.schoolFilterValue ||
            prevState.sectionFilterValue !== this.state.sectionFilterValue ||
            prevState.page !== this.state.page
        ) {
            this.getStudentReportIndex();
        }
    }

    renderPublishModal() {
        if (this.state.publishSuccess) {
            return (<>
                <InfoModal
                    open={this.state.publishSuccess}
                    closeModal={() => this.closeModal("success")}
                    info={this.state.successMessage}
                    title={configJSON.StudentsReport.Published}
                    successImage={PublishedSuccess}
                    modalType={configJSON.ModalTypes.Success}
                />
            </>)
        }
        if (this.state.publishFailure) {
            return (<>
                <InfoModal data-test-id='info' open={this.state.publishFailure} modalType='warning' title={'Alert'} closeModal={() => this.closeModal("failure")} info={configJSON.StudentsReport.OnlyWhenMsg} />
            </>)
        }
        return (<></>)
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return <>
            <SidebarLayout {...this.props} >
                <PageContentLayout
                    title={configJSON.StudentsReport.StudentsReport}
                    handleReportRefresh={this.handleReportRefresh}
                    handlePublishReport={this.handlePublishClick}
                    goBack={this.goBack}>
                    {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
                    <TrainerStudentReportPage
                        data-test-id='report-page'
                        data={this.state.studentReportData}
                        goTo={this.goTo}
                        clearFilter={this.handleClearFilter}
                        states={this.state}
                        handleDropDownValueChange={this.handleDropdownChange}
                        pageNo={this.state.page}
                        handlePageChange={this.handlePageChange}
                        handleGenerateReports = {this.handleGenerateReport}
                    />
                </PageContentLayout>
            </SidebarLayout>
            {this.renderPublishModal()}
        </>
        // Customizable Area End
    }
}
// Customizable Area Start
const ErrorStyle = styled(Box)({
    color:"red",
    fontSize:"12px",
    marginTop:"7px"
})
const StudentReportStyle = styled(Box)({
    display:"flex",
    justifyContent:"flex-end",
    marginBottom:"30px",
    "& .addMpowerYouMatterBtn": {
        "&.MuiButton-root": {
            height: '48px',
            width: '216px',
            marginLeft: 'auto',
            borderRadius: '8px',
            color: "white",
            backgroundColor: "#0056A3",
            border: '1px solid #0056A3',
            '@media(max-width:600px)': {
                width: 'max-content'
            }
        },
        "& .MuiButton-label": {
            fontSize: "14px",
            fontFamily: "Poppins",
            lineHeight: "21px",
            textTransform: "capitalize",
            fontWeight: 600
        },
    },
})
// Customizable Area End