// Customizable Area Start
import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";
import { CometChatContext } from "../../../util/CometChatContext";

import Translator from "../../../resources/localization/translator";
import { theme } from "../../../resources/theme";

import { msgTimestampStyle, iconStyle } from "./style";

import blueDoubleTick from "./resources/message-read.svg";
import greyDoubleTick from "./resources/message-delivered.svg";
import greyTick from "./resources/message-sent.svg";
import sendingTick from "./resources/wait.svg";
import errorTick from "./resources/warning-small.svg";
import moment from 'moment'
import 'moment-timezone'

class CometChatReadReceipt extends React.PureComponent {
	static contextType = CometChatContext;
	loggedInUser;

	constructor(props, context) {
		super(props, context);
		this._isMounted = false;
		this.state = {
			receipts: false,
		};

		this.context.getLoggedinUser().then((user) => {
			this.loggedInUser = { ...user };
		});
	}

	componentDidMount() {
		this._isMounted = true;
		this.toggleReadReceipts();
	}

	componentDidUpdate() {
		this.toggleReadReceipts();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	dateShow = (date) => {
		const indianTime = moment.unix(date).tz('Asia/Kolkata');
		const formattedDate = indianTime.format('Do MMM YYYY, h:mm A');
		return <div>{formattedDate}</div>;
	  };

	toggleReadReceipts = () => {
		/**
		 * if delivery receipts feature is disabled
		 */
		this.context.FeatureRestriction.isDeliveryReceiptsEnabled()
			.then((response) => {
				if (response !== this.state.receipts && this._isMounted) {
					this.setState({ receipts: response });
				}
			})
			.catch((error) => {
				if (this.state.receipts !== false) {
					this.setState({ receipts: false });
				}
			});
	};
	  
	getMessageStatus = (message, context) => {
		if (message.hasOwnProperty("error")) {
		  return { ticks: errorTick, receiptText: "ERROR", color: context.theme.color.red };
		}
	  
		if (message.hasOwnProperty("readAt")) {
		  return { ticks: blueDoubleTick, receiptText: "SEEN", color: context.theme.primaryColor };
		}
	  
		if (message.hasOwnProperty("deliveredAt")) {
		  return { ticks: greyDoubleTick, receiptText: "DELIVERED", color: context.theme.secondaryTextColor };
		}
	  
		if (message.hasOwnProperty("sentAt")) {
		  return { ticks: greyTick, receiptText: "SENT", color: context.theme.secondaryTextColor };
		}
	  
		return { ticks: sendingTick, receiptText: "SENDING", color: context.theme.secondaryTextColor };
	  };
	  
	  render() {
		const { message } = this.props;
		const { theme, language } = this.context;
		const { uid: loggedInUserUid } = this.loggedInUser || {};
	  
		let ticks = null;
		let receiptText = null;
		let color = null;
	  
		if (message?.sender?.uid === loggedInUserUid) {
		  if (message.receiverType === CometChat.RECEIVER_TYPE.GROUP) {
			({ ticks, receiptText, color } = this.getMessageStatus(message, this.context));
		  } else {
			({ ticks, receiptText, color } = this.getMessageStatus(message, this.context));
	  
			if (message.hasOwnProperty("readAt")) {
			  ticks = blueDoubleTick;
			  receiptText = "SEEN";
			  color = theme.primaryColor;
			}
		  }
		}
	  
		// If delivery receipts are disabled
		if (this.state.receipts === false) {
		  ticks = null;
		}
	  
		const receipt = ticks ? (
		  <i
			css={iconStyle(ticks, color)}
			title={Translator.translate(receiptText, language)}
		  ></i>
		) : null;
	  
		return (
		  <>
			<span
			  css={msgTimestampStyle(theme, this.props, this.loggedInUser)}
			  className='message__timestamp'
			></span>
			{receipt}
		  </>
		);
	  }
	  
}

// Specifies the default values for props:
CometChatReadReceipt.defaultProps = {
	theme: theme,
};

CometChatReadReceipt.propTypes = {
	theme: PropTypes.object,
	message: PropTypes.object.isRequired,
};

export { CometChatReadReceipt };
// Customizable Area End