// Customizable Area Start
import React, { ReactNode } from "react"
import SidebarLayout from "../SidebarLayout.web";
import PageContentLayout from "../PageContentLayout.web";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Button, Theme, RadioGroup, Radio, FormControlLabel, Grid, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Loader from "../../../../components/src/Loader.web";
import InfoModal from "../../../../components/src/CommonInformationModal.web";
import CommonSnackBar from "../../../../components/src/commonSnackBar.web";
import { CheckedIcon, Icon, Option } from "../YouMatter/YouMatterQuestions.web";
import CommonPagination from "../../../../components/src/Pagination.web";
import MPowerController, { ProgramSubmitData, S } from "./MPowerController";
import DragDropQuestion from "../../../dragdropinterface/src/DragDropQuestion.web";
import { Bucket, DragDropChangeData, DragDropQuestionContent } from "../../../dragdropinterface/src/DragDropQuestionController";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
const navigation = require("react-navigation");
// Customizable Area End

const configJSON = require("../config.js");
// Customizable Area Start
// Customizable Area End

const useStyles = makeStyles<Theme>(() => ({
    // Customizable Area Start
    programFormWrapper: {
        padding: '24px 32px'
    },
    pageTitle: {
        fontWeight: 700,
        fontSize: "32px",
        color: "#0056A3",
        fontFamily: "Poppins",
        textTransform: 'capitalize',
        "@media(max-width:767px)": {
            fontSize: "24px"
        }
    },
    questionCard: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
        borderRadius: '8px',
        border: '0.2px solid #e3e3e3',
        marginBottom: '16px'
    },
    questionNumber: {
        minHeight: '56px',
        minWidth: '56px',
        borderRadius: '8px',
        backgroundColor: '#0056A3',
        color: 'white',
        fontFamily: "Poppins",
        fontSize: '20px',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '16px'
    },
    questionText: {
        fontWeight: 500,
        marginRight: '10px',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#3B3B3B',
        fontFamily: "Poppins",
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkitLineClamp': 2,
        '-webkitBoxOrient': 'vertical',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        '&:hover': {
            '-webkitBoxOrient': 'inherit',
            wordBreak: 'break-word',
        }
    },
    arrowWrapper: {
        background: '#0056A3',
        padding: '10px',
        borderRadius: '12px',
        color: '#FFFFFF',
        marginRight: '10px',
        '&.true': {
            transform: 'rotate(180deg)'
        }
    },
    radioGroup: {
        '&.MuiFormGroup-root': {
            flexDirection: 'row',
            "@media(max-width:575px)": {
                flexDirection: 'column',
            }
        }
    },
    submitForm: {
        "&.MuiButton-root": {
            height: '48px',
            maxWidth: '208px',
            width: '100%',
            borderRadius: "12px",
            backgroundColor: "#0056A3",
            display: 'block',
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            color: 'white',
            fontSize: '16px',
            fontFamily: "Poppins",
        },
        '&.MuiButton-root.Mui-disabled': {
            backgroundColor: '#0056a380'
        }
    },
    formDescription: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '21px',
        color: '#3B3B3B',
        fontFamily: "Poppins",
        marginBottom: '32px'
    },
    questionInputWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    optionWrapper: {
        padding: "4px",
        borderRadius: "8px",
        "&:has(.Mui-checked)": {
            border: "0.75px solid #0056A3",
        },
        border: "0.2px solid #e3e3e3",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    },
    optionFormLabel: {
        "&.MuiFormControlLabel-root": {
            marginLeft: "0px",
            width: "100%",
            justifyContent: "space-between",
        },
    },
    submitPaginationWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        "@media(max-width:600px)": {
            flexDirection: 'column-reverse'
        }
    },
    paginationWrapper: {
        "@media(max-width:600px)": {
            marginTop: '25px'
        }
    },
    accordionSummary: {
        "& .MuiAccordionSummary-content": {
            minHeight: "auto",
            padding: "0",
            margin: "7px 0",
            display: "block",
        },
        "&.MuiAccordionSummary-root": {
            padding: "0",
        },
    },
    accordion: {
        "&.MuiAccordion-root:before": {
            display: "none",
        },
        "&.MuiAccordion-root": {
            boxShadow: "none",
        },
    },
    accordionDetails: {
        "&.MuiAccordionDetails-root": {
            padding: "10px 0 0 0",
            display: "block",
        },
    },
    tableHeader: {
        backgroundColor: "0056A3",
        color: "#fff",
        borderRadius: "10px",
        "&:lastChild": {
          borderRadius: " 0 10px 10px 0",
        },
      },
    
      tableHeadCell: {
        color: "#fff",
        "&:lastChild": {
          borderRadius: " 0 10px 10px 0",
        },
      },
      paginationContainer: {
        display: "flex",
        justifyContent: "end",
      },
      headerBox: {
        background:
          "linear-gradient(70deg, rgba(1,86,162,1) -36.53%, rgba(75,144,205,1) 66%, rgba(179,219,255,1) 146%)",
        borderRadius: "12px 12px 0px 0px",
        padding: "16px 24px",
        display: "flex",
        alignItems: "center",
      },
      mainBox: {
        background: "#FFFFFF",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        borderRadius: "12px",
        maxWidth: "1108px",
      },
      boxPadding: {
        padding: "24px",
      },
    
    // Customizable Area End
}))

// Customizable Area Start
interface Choice {
    id: number;
    content: string;
}
interface QuestionCardProps {
    navigation: typeof navigation;
    id: string;
    questionId: any;
    questionIndex: number;
    question: string;
    choices: Choice[];
    buckets: Bucket;
    questionType: string;
    selectedAnswers: any;
    handleAnswer: (id: any, value: any, questionType: string) => void;
    accordionState: string;
    index: number;
    handleChangeAccordion: (panel: string) => ((event: React.ChangeEvent<{}>, expanded: boolean) => void) | undefined;
    handleDragDropChange: (data: DragDropChangeData) => void;
}
// Customizable Area End
export const QuestionCard = (props: QuestionCardProps) => {
    // Customizable Area Start
    const classes = useStyles()
    const { questionId, question, handleAnswer, selectedAnswers, questionIndex, choices, questionType, index, accordionState, handleChangeAccordion } = props;
    const isBoolean = questionType === configJSON.QuestionTypes.TrueFalse;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = isBoolean ? (event.target as HTMLInputElement).value === 'true' : (event.target as HTMLInputElement).value;
        handleAnswer(questionId, value, questionType)
    };
    const size4boolean = isBoolean ? 6 : 3;
    const options = choices?.map((choice: Choice)=> ({...choice, is_selected: false }));
    const buckets = props.buckets;
    const isAccordionActive = accordionState === `${index + 1}`;
    const renderDragDropQue = () => {
        const questionData: DragDropQuestionContent = { id: questionId, options: options, buckets: buckets, answers: selectedAnswers[questionId] };
        return <DragDropQuestion data-test-id="drag-drop-que" navigation={props.navigation} id={props.id} questionData={questionData} isListView={false} handleDragDropChange={props.handleDragDropChange}/>
    }

    return( <Accordion data-test-id="accordion" className={classes.accordion} expanded={isAccordionActive} onChange={handleChangeAccordion(`${index + 1}`)}>
        <AccordionSummary className={classes.accordionSummary}>
            <Box className={classes.questionCard} data-test-id='box'>
                <Box className={classes.questionNumber}>{`Q${questionIndex}.`}</Box>
                <Box className={classes.questionInputWrapper}>
                    <Typography className={classes.questionText}>{question}</Typography>
                    <Box className={`${classes.arrowWrapper} ${isAccordionActive}`} data-test-id='accordion-arrow'>
                        <KeyboardArrowDown/>
                    </Box>
                </Box>
            </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
            {questionType === configJSON.QuestionTypes.DragAndDrop 
            ? renderDragDropQue() 
            : (
                <RadioGroup className={classes.radioGroup} data-test-id='radio' value={`${selectedAnswers[questionId]?.value}`} onChange={handleChange}>
                    <Grid container spacing={2}>
                        {choices && choices.map((element: Choice, index: number) => (
                            <Grid key={element.id} item xl={size4boolean} lg={size4boolean} md={6} sm={6} xs={12} data-test-id="choice-option">
                                <Box className={classes.optionWrapper}>
                                    <FormControlLabel
                                        className={classes.optionFormLabel}
                                        value={`${element.id}`}
                                        labelPlacement="start"
                                        control={<Radio color="primary" icon={<Icon />} checkedIcon={<CheckedIcon />} />}
                                        label={<Option option={index + 1} content={element.content} isListView={true} />}
                                    />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </RadioGroup>
            )}
        </AccordionDetails>
    </Accordion>)
    // Customizable Area End
}
// Customizable Area Start
interface ProgramFormPageProps {
    navigation: typeof navigation,
    id: string,
    handleAnswer: (id: any, value: any, questionType: string) => void;
    states: S;
    formType: string;
    totalPages: number;
    handlePage: (page: number) => void;
    handleSubmit: () => void;
    closeSnackbar: () => void;
    handleChangeAccordion: (panel: string) => ((event: React.ChangeEvent<{}>, expanded: boolean) => void) | undefined;
    handleDragDropChange: (data: DragDropChangeData) => void;
}
// Customizable Area End
export const ProgramFormPage = (props: ProgramFormPageProps) => {
    // Customizable Area Start
    const classes = useStyles()
    const { states, formType, closeSnackbar, handleAnswer, handleSubmit, handlePage, totalPages, handleChangeAccordion, handleDragDropChange } = props;
    const answers:ProgramSubmitData[] = Object.values(states.programFormSubmitData);
    const ansLength = answers.filter((item: ProgramSubmitData) => {
        if(item.questionType === 'drag_and_drop' && Array.isArray(item.value)) {
          return item.totalOptions.length === item.value.length
        } else if(!Array.isArray(item.value)) {
          return item.value !== undefined && item.value !== ""
        }
      }).length;
    const noDataMsg = props.states.courseId ? configJSON.YouMatterTexts.NoQuestionsForCourse : configJSON.YouMatterTexts.PleaseSelectAssignedCourse
    return <Box className={classes.programFormWrapper} data-test-id='box'>
        {
            states.prePostFormData.length > 0 ? states.prePostFormData.map((element: any, index: number) => {
                const attributes = element?.attributes;
                const questionType = attributes.question_type;
                const choices = questionType === configJSON.QuestionTypes.TrueFalse ? configJSON.YouMatterTexts.ChoiceArr : attributes.choices;
                const buckets = element.attributes.buckets;
                return <QuestionCard
                    index={index}
                    navigation={props.navigation}
                    id={props.id}
                    accordionState={states.accordionState}
                    handleChangeAccordion={handleChangeAccordion}
                    data-test-id='question'
                    handleAnswer={handleAnswer}
                    key={`Question-${element.id}-${index}`}
                    choices={choices}
                    buckets={buckets}
                    question={attributes?.text}
                    questionType={questionType}
                    questionId={attributes?.id}
                    selectedAnswers={states.programFormSubmitData}
                    questionIndex={index + 1}
                    handleDragDropChange={handleDragDropChange} />
            }) : noDataMsg
        }

        {states.prePostFormData.length > 0 && <Box className={classes.submitPaginationWrapper} >
            <Box className={classes.paginationWrapper}>
                <CommonPagination
                    page={states.page}
                    handlePage={handlePage}
                    pageCount={totalPages}
                />
            </Box>
            <Button data-test-id='submit' disabled={ansLength < states.prePostFormData?.length} onClick={handleSubmit} className={classes.submitForm}>{formType === configJSON.YouMatterTexts.PreProgramType ? configJSON.YouMatterTexts.SubmitPreProgram : configJSON.YouMatterTexts.SubmitPostProgram}</Button>
        </Box>}
        <CommonSnackBar
            data-test-id='snackbar'
            type="error"
            verticalPosition="top"
            horizontalPosition="center"
            open={states.snackbar}
            onClose={closeSnackbar}
            message={states.snackbarMsg}
            duration={4000}
        />
    </Box>
    // Customizable Area End
}
// Customizable Area Start
const getTitle = (type: string) => {
    if (type === configJSON.YouMatterTexts.PreProgramType) {
        return configJSON.YouMatterTexts.PreProgramForm
    } else {
        return configJSON.YouMatterTexts.PostProgramForm
    }
}
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export default class ProgramForm extends MPowerController {
    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        this.getAssignedCourses();
        this.getPrePostQuestions();
        await this.onGetCourseStatus() 
        const formType = this.props.navigation.getParam && this.props.navigation.getParam(configJSON.YouMatterTexts.MPowerFormType);
        this.setState({typePre:formType})
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<S>): void {
        if ((prevState.courseId !== this.state.courseId && !this.state.snackbar && this.state.courseId) || prevState.page !== this.state.page) {
            this.setState({ isPageChange: prevState.page !== this.state.page })
            this.getPrePostQuestions()
        }
    }

    // Customizable Area End
    render(): ReactNode {
        // Customizable Area Start
        const formType = this.props.navigation.getParam && this.props.navigation.getParam(configJSON.YouMatterTexts.MPowerFormType)    
        return <SidebarLayout {...this.props}>
            <PageContentLayout addPadding titleWrapperWidth={"100%"} goBack={this.goBack} onClearSelectedCourse={this.handleClearSelectedCourse} title={getTitle(formType)} handleSelectCourse={this.handleSelectCourse} courseList={this.state.courseList} courseId={this.state.courseId} >
                {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
                <ProgramFormPage
                    data-test-id='form'
                    formType={formType}
                    states={this.state}
                    handleAnswer={this.handleProgramFormQuestions}
                    handleSubmit={this.handleOpenDialog}
                    closeSnackbar={this.closeSnackBar}
                    totalPages={this.state.totalPages}
                    handlePage={this.handlePage}
                    handleChangeAccordion={this.handleChangeAccordion}
                    handleDragDropChange={this.handleDragDropChange}
                    navigation={this.props.navigation}
                    id={this.props.id}
                />
                <InfoModal
                    data-test-id='success'
                    info={this.state.modalType === configJSON.YouMatterTexts.Success ? configJSON.YouMatterTexts.SuccessMsg : `${configJSON.YouMatterTexts.ConfirmMsg} ${getTitle(formType)}?`}
                    modalType={this.state.modalType}
                    submit={() => this.submitPrePostForm(formType)}
                    open={this.state.openFormSubmitModal}
                    closeModalFunction={this.handleCloseFunction}
                    closeModal={this.handleCloseSubmitSuccessModal} />
                <InfoModal
                    data-test-id='sucessModalTestId'
                    info={configJSON.MPower.PreSubmission}
                    modalType="warning"
                    open={this.state.postModal}
                    closeModal={this.handleClosePostModals} />
            </PageContentLayout>
        </SidebarLayout>
        // Customizable Area End
    }
}
