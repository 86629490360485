import React, { useState } from "react";
import { ListItem, ListItemText, Collapse, List } from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { makeStyles, Theme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#f9f9f9"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  subNested: {
    paddingLeft: theme.spacing(8)
  }
}));

const MobileNavItems = (props: any) => {
  const classes = useStyles();
  const [openProgramsMenu, setOpenProgramsMenu] = React.useState(false);
  const [openSchoolProgramMenu, setOpenSchoolProgramMenu] = React.useState(
    false
  );

  const openPrograms = () => {
    setOpenProgramsMenu(!openProgramsMenu);
    setOpenSchoolProgramMenu(false);
  };

  const openSchoolPrograms = () => {
    setOpenSchoolProgramMenu(!openSchoolProgramMenu);
  };

  const navContactUS =()=>{
    props.handleClose(event);
  }
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem button component="a" href="/">
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem button component="a" href="/aboutus">
        <ListItemText primary="About Us" />
      </ListItem>
      <ListItem button onClick={openPrograms}>
        <ListItemText primary="Programs" />
        {openProgramsMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openProgramsMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            onClick={openSchoolPrograms}
          >
            <ListItemText primary="School Safety Program" />
            {openSchoolProgramMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSchoolProgramMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.subNested}
                component="a"
                href="/programs/bud"
              >
                <ListItemText primary="Bud [3 to 8 Years]" />
              </ListItem>
              <ListItem
                button
                className={classes.subNested}
                component="a"
                href="/programs/bloom"
              >
                <ListItemText primary="Bloom [8 to 11 Years]" />
              </ListItem>
              <ListItem
                button
                className={classes.subNested}
                component="a"
                href="/programs/blossom"
              >
                <ListItemText primary="Blossom [11 to 16 Years]" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            className={classes.nested}
            component="a"
            href="/programs"
          >
            <ListItemText primary="Digital Platform" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component="a"
            href="/programs/parent"
          >
            <ListItemText primary="Parents Program" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component="a"
            href="/programs/educator"
          >
            <ListItemText primary="Educators Program" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component="a"
            href="/programs/custom"
          >
            <ListItemText primary="Create Your Own Program" />
          </ListItem>
        </List>
      </Collapse>
      <ListItem button component="a" href="/resources">
        <ListItemText primary="Resources" />
      </ListItem>
      <ListItem button component="a" href="/partnership">
        <ListItemText primary="Partnership" />
      </ListItem>

      <ListItem button component="a" onClick={navContactUS} href="/#contact-us">
        <ListItemText primary="Contact Us" />
      </ListItem>
    </List>
  );
};

export default MobileNavItems;
