import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/moment";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker, KeyboardTimePicker
} from "@material-ui/pickers";
import { Typography, FormHelperText } from "@material-ui/core";
import { calendarDark, darkClockIcon } from "../../blocks/dashboard/src/assets";
import { usePopoverStyles } from '../../blocks/email-account-registration/src/CreateAccount.web';

const useStyles = makeStyles((theme) => ({
    datePicker: {
        '&.MuiFormControl-root': {
            minWidth: '100%'
        },
        "& .MuiInput-root": {
            margin: '3px 0',
        },
        "& .MuiInputAdornment-positionEnd": {
            marginRight: "8px"
        },
        "& .MuiInputBase-root": {
            flexDirection: "row",
            marginBottom: '20px'
        },
        "& .MuiInputBase-input": {
            padding: '0 0 0 18px',
            zIndex: 50
        },
        "& .MuiFormHelperText-root": {
            position: "relative",
            top: "0",
            "@media(max-width:818px)": {
                top: "0"
            }
        },
        "& .MuiInput-underline::before": {
            content: "",
            position: "absolute",
            height: "56px",
            bottom: "-18px",
            backgroundColor: "#FFFFFF",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "8px",
            [theme.breakpoints.down(600)]: {
                flexBasis: "80%"
            },
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            border: "1px solid #0056A3"
        },
        "& .MuiInput-underline::after": {
            content: "",
            backgroundColor: "#FFFFFF",
            position: "absolute",
            height: "56px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "8px",
            bottom: "-18px",
            transform: "scaleX(0)",
            transition: 'none'
        },
        "& .MuiInput-underline.Mui-focused::after": {
            border: "1px solid #0056A3"
        },
        "& .MuiInput-underline.Mui-error::after": {
            transform: "scaleX(1)",
            transition: 'none',
            border: "1px solid red"
        }
    },
    dayWithDotContainer: {
        "& button": {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "22px",
            textAlign: "center",
            color: "#3F526D",
            mixBlendMode: "normal"
        },
        "& button.MuiPickersDay-daySelected": {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "22px",
            textAlign: "center",
            color: "#FFFFFF",
            background:
                "linear-gradient(131deg, #B3DBFF -33.84%, #4B90CD 19.41%, #0156A2 95.54%)",
            opacity: "1 !important",
            borderRadius: "4%"
        },
        "& button.MuiPickersDay-hidden": {
            opacity: "0.3 !important"
        }
    },
    fieldLabel: {
        // color: '#3B3B3B',
        color:"red",
        fontSize: '20px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        marginBottom: '7px',
        '@media(max-width:600px)': {
            fontSize: "14px"
        }
    }
}))

const clockStyles = makeStyles({
    paper: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "27px",
        color: "#0056A3",
        border: "1px solid #0056A3",
        boxShadow: "0px 10px 16px rgba(165, 176, 194, 0.15)",
        borderRadius: "10px",
        '& .MuiPickersToolbar-toolbar': {
            background: "#0056A3",
        },
        '& .MuiPickersClockPointer-thumb': {
            border: '14px solid #0056A3'
        },
        '& .MuiPickersClockPointer-noPoint': {
            backgroundColor: '#0056A3'
        }
    }
})

const renderIcon = (name: string) => {
    if (name === 'calendar') {
        return <img src={calendarDark} alt="calendar" />
    } else {
        return <img src={darkClockIcon} alt='clock' />
    }
}

interface Props {
    name?: string;
    label?: string;
    type: string;
    disabled?: boolean;
    placeholder?: string;
    minDate?: string|Date|null;
    date: Date|null;
    handleDate: (date: Date|null) => void;
    isOpen:boolean;
    setIsOpen:(state:boolean) => void;
    isStartTime: boolean;
    setTime: (state: boolean) => void;
    isEndTime: boolean;
    setEndTime: (state: boolean) => void;
    error?: string | null;
    setError?:(state: string | null) => void;
}

const DatePicker = (props: Props) => {
    const classes = useStyles();
    const popoverClasses = usePopoverStyles();
    const clockClasses = clockStyles()
    const renderDayInPicker = (
        _date: string|number,
        _selectedDate: string|number,
        _dayInCurrentMonth: Object,
        dayComponent: Object
    ) => {
        return <div className={classes.dayWithDotContainer}>{dayComponent}</div>;
    };

    return <>
        {props.label && <Typography className={classes.fieldLabel}>{props.label}</Typography>}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {props.type === 'date' ?
                <KeyboardDatePicker
                    open={props.isOpen}
                    onOpen={() => props.setIsOpen(true)}
                    onClose={() => props.setIsOpen(false)}
                    className={classes.datePicker}
                    disableToolbar
                    variant="inline"
                    data-test-id='date'
                    format="DD/MM/YYYY"
                    margin="normal"
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    minDate={props.minDate}
                    PopoverProps={{ classes: popoverClasses }}
                    renderDay={renderDayInPicker}
                    keyboardIcon={renderIcon('calendar')}
                    value={props.date}
                    onChange={(event: Date) => {
                        props.handleDate(event);
                        props.setIsOpen(false);
                    }}
                /> :
                <>
                <KeyboardTimePicker
                    name={props.name}
                    open={props.placeholder === "Start Time*" ? props.isStartTime: props.isEndTime}
                    onOpen={() =>{props.placeholder === "Start Time*" ? props.setTime(true): props.setEndTime(true)} }
                    onClose={() => {props.placeholder === "Start Time*" ? props.setTime(false): props.setEndTime(false)}}
                    data-test-id='time'
                    className={classes.datePicker}
                    placeholder={props.placeholder}
                    margin="normal"
                    value={props.date}
                    PopoverProps={{ classes: clockClasses }}
                    keyboardIcon={renderIcon('clock')}
                    onChange={(event: Date) => {
                        props.handleDate(event);
                    }}
                />
                    {props.error && <FormHelperText data-test-id="errorText" error>{props.error}</FormHelperText>}
                </>
            }
        </MuiPickersUtilsProvider>
    </>
}
export default DatePicker;