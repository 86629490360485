export const messageContainerStyle = () => {
	return {
		alignSelf: "flex-start",
		marginBottom: "16px",
		paddingLeft: "16px",
		paddingRight: "16px",
		maxWidth: "100%",
		clear: "both",
		position: "relative",
		display: "flex",
		flexDirection: "column",
		flexShrink: "0",
	};
};

export const messageWrapperStyle = () => {
	return {
		width: "auto",
		flex: "1 1",
		alignSelf: "flex-start",
		display: "flex",
	};
};

export const messageThumbnailStyle = () => {
	return {
		width: "36px",
		height: "36px",
		margin: "10px 5px",
		float: "left",
		flexShrink: "0",
	};
};

export const messageDetailStyle = () => {
	return {
		flex: "1 1",
		display: "flex",
		flexDirection: "column",
	};
};

export const nameWrapperStyle = (avatar) => {
	const paddingValue = avatar
		? {
				padding: "3px 5px",
		  }
		: {};

	return {
		alignSelf: "flex-start",
		...paddingValue,
	};
};

export const nameStyle = (context) => {
	return {
		fontSize: "11px",
		color: `${context.theme.color.search}`,
	};
};

export const messageImgContainerStyle = (customWidth, customFlex) => {
	const defaultWidth = "auto";
	const defaultFlex = "1 1";
  
	const width = customWidth || defaultWidth;
	const flex = customFlex || defaultFlex;
  
	return {
	  width: width,
	  flex: flex,
	  alignSelf: "flex-start",
	  display: "flex",
	};
  };
  

export const messageImgWrapperStyle = (context) => {
	const mq = [...context.theme.breakPoints];

	return {
		display: "inline-block",
		alignSelf: "flex-start",
		maxWidth: "128px",
		height: "128px",
		cursor: "pointer",
		[`@media ${mq[1]}, ${mq[2]}`]: {
			maxWidth: "128px",
			height: "128px",
			padding: "2px 2px",
		},
	};
};

export const messageInfoWrapperStyle = () => {
	return {
		alignSelf: "flex-start",
		padding: "4px 8px",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		height: "25px",
	};
};

export const messageReactionsWrapperStyle = () => {
	return {
		display: "flex",
		alignSelf: "flex-start",
		width: "100%",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		minHeight: "36px",
	};
};
