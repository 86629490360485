export const rightArrow = require("../assets/rightArrow.png");
export const assessment = require("../assets/assessment.png");
export const card1 = require("../assets/card1.png");
export const card2 = require("../assets/card2.png");
export const card3 = require("../assets/card3.png");
export const card4 = require("../assets/card4.png");
export const work1 = require("../assets/work1.png");
export const work2 = require("../assets/work2.png");
export const work3 = require("../assets/work3.png");
export const work4 = require("../assets/work4.png");
export const feature1 = require("../assets/feature1.png");
export const feature2 = require("../assets/feature2.png");
export const feature3 = require("../assets/feature3.png");
export const feature4 = require("../assets/feature4.png");
export const teacher = require("../assets/teacher.png");
export const impact1 = require("../assets/impact1.png");
export const impact2 = require("../assets/impact2.png");
export const feedback1 = require("../assets/feedback1.png");
export const feedback2 = require("../assets/feedback2.png");
export const feedback3 = require("../assets/feedback3.png");
export const handImg = require("../assets/hand.png");
export const IconGroup = require("../assets/group_Group.png");
export const iimb = require("../assets/iimb.png");
export const ispcan = require("../assets/ispcan.png");
export const myBody = require("../assets/myBody.png");
export const weProtect = require("../assets/weProtect.png");
export const qort = require("../assets/qort.png");
export const bannerimg = require("../assets/bannerimg.png");
export const rightArrowIcon = require("../assets/rightArrowIcon.png");
export const hapchihelp = require("../assets/hapchihelp.png");
export const hapchihelpNew = require("../assets/hapchihelpNew.png");
export const banner2 = require("../assets/banner2.png");
export const banner3 = require("../assets/banner3.png");
export const sliderGIF = require("../assets/sliderGif.gif");
export const bannerBg = require("../assets/banner_bg.svg");
export const programMainBannerImage = require("../assets/image_program_main_banner.png");
export const programParentBannerImage = require("../assets/image_program_parent_banner.png");
export const programEducatorBannerImage = require("../assets/image_program_educator_banner.png");
export const programCustomBannerImage = require("../assets/image_program_custom_banner.png");
export const programMainChlidren = require("../assets/image_program_children.png");
export const programParentImage = require("../assets/image_program_parent.png");
export const programEducatorImage = require("../assets/image_program_educator.png");
export const programCustomImage = require("../assets/image_program_custom.png");
export const programBlossomBannerImage = require("../assets/image_program_blossom_banner.png");
export const programFeature1 = require("../assets/image_program_feature1.png");
export const programFeature2 = require("../assets/image_program_feature2.png");
export const programFeature3 = require("../assets/image_program_feature3.png");
export const programFeature4 = require("../assets/image_program_feature4.png");
export const programFeature5 = require("../assets/image_program_feature5.png");
export const programFeature6 = require("../assets/image_program_feature6.png");
export const programFeature7 = require("../assets/image_program_feature7.png");
export const programFeature8 = require("../assets/image_program_feature8.png");
export const programBloomBannerImage = require("../assets/image_program_bloom_banner.png");
export const programFeature9 = require("../assets/image_program_feature9.png");
export const programFeature10 = require("../assets/image_program_feature10.png");
export const programFeature11 = require("../assets/image_program_feature11.png");
export const programFeature12 = require("../assets/image_program_feature12.png");
export const programFeature13 = require("../assets/image_program_feature13.png");
export const programFeature14 = require("../assets/image_program_feature14.png");
export const programFeature15 = require("../assets/image_program_feature15.png");
export const programBudBannerImage = require("../assets/image_program_bud_banner.png");
export const programFeature16 = require("../assets/image_program_feature16.png");
export const programFeature17 = require("../assets/image_program_feature17.png");
export const programFeature18 = require("../assets/image_program_feature18.png");
export const programFeature19 = require("../assets/image_program_feature19.png");
export const programFeature20 = require("../assets/image_program_feature20.png");
export const aboutUsImage = require("../assets/about_us.png");
export const chrissyImage = require("../assets/image_Chrissy.png");
export const kanikaImage = require("../assets/image_Kanika-sharma.png");
export const nishitaImage = require("../assets/image_nishita_bakshi.png");
export const storyImage = require("../assets/image_story.png");
export const sukumarImage = require("../assets/image_Sukumar.png");
export const valuesImage = require("../assets/image_values.png");
export const visionImage = require("../assets/image_vision.png");
export const jyotiImage = require("../assets/image_DrJyothi.png");
export const missionImage = require("../assets/image_mission.png");
export const ourStoryBg = require("../assets/our_story.png");
export const storyLeft = require("../assets/story_left.png");
export const storyRight = require("../assets/story_right.png");
export const expertLeft = require("../assets/expert_left.png");
export const expertRight = require("../assets/expert_right.png");
export const teamBottomLeft = require("../assets/team_bottom_left.png");
export const teamBottomRight = require("../assets/team_bottom_right.png");
export const cardBack = require("../assets/card_back_bottom.png");
export const contentLeftBg = require("../assets/content_left_bg.png");
export const partnershipBannerImage = require("../assets/image_partnership_banner.png");
export const rightArrowImage = require("../assets/rightArrowIcon.png");
export const leftTop = require("../assets/left_top.png");
export const rightBottom = require("../assets/right_bottom.png");
export const activeStar = require("../assets/star_active.png");
export const inactiveStar = require("../assets/star_inactive.png");
export const bookImage = require("../assets/book.png");
export const bookDetail = require("../assets/book_detail.png");
export const safetyParentBanner1 = require("../assets/safety_parent_banner_age1.png");
export const safetyParentBanner2 = require("../assets/safety_parent_banner_age2.png");
export const safetyParentBanner3 = require("../assets/safety_parent_banner_age3.png");
export const safetyChildrenBanner = require("../assets/safety_children_banner.png");
export const safetySchoolBanner = require("../assets/safety_school_banner.png");
export const shopBannerImage = require("../assets/shop-banner.png");
export const bottomLeftShape = require("../assets/bottom_left.svg");
export const storyBottomRightShape = require("../assets/story_bottom_right.svg");
export const safetyChildChildren = require("../assets/safety_child_children.png");
export const safetyChildParent = require("../assets/safety_child_parent.png");
export const safetyChildSchool = require("../assets/safety_child_school.png");
