export const messageContainerStyle = () => {
	return {
		alignSelf: "flex-start",
		marginBottom: "16px",
		width: "100%",
		backgroundColor:"#FFF8EB",
		borderLeft:"5px solid #BD7403",
		clear: "both",
		position: "relative",
		display: "flex",
		flexDirection: "column",
		flexShrink: "0",
	};
};

export const messageWrapperStyle = () => {
	return {
		 width: "auto",
		
	};
};

export const messageImgWrapper = (context) => {
	const mq = [...context.theme.breakPoints];

	return {
		display: "inline-block",
		alignSelf: "flex-start",
		maxWidth: "100px",
		height: "100px",
		marginTop:"15px",
		marginLeft:"22px",
		img: {
			borderRadius: "8px",
			height: "100%",
		},
		[`@media ${mq[1]}, ${mq[2]}`]: {
			minWidth: "50px",
			maxWidth: "150px",
			height: "100px",
			padding: "2px 2px",
		},
	};
};

export const messageInfoWrapperStyle = () => {
	return {
		alignSelf: "flex-end",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		height: "25px",
		padding: "4px 8px",
	};
};

export const messageReactionsWrapperStyle = () => {
	return {
		display: "flex",
		alignSelf: "flex-end",
		width: "100%",
		flexWrap: "wrap",
		justifyContent: "flex-end",
		minHeight: "36px",
	};
};
