import React from "react";
import { Button } from "@material-ui/core";

const PrimaryButton = (props:any) => {
    
    return (
        <Button
            variant={props.variant}
            className={`${props.className} primary-btn-custom`}
            onClick={props.onClick}
        >{props.children}</Button>
    )
}

export default PrimaryButton;