import React from "react";
import { Box, Grid, Typography, Link } from "@material-ui/core";
import { makeStyles,Theme } from "@material-ui/core/styles";
interface Props {
    title: string;
    workImage: string;
    rightArrowIcon?: string;
    link?: string;
}
const useStyles = makeStyles<Theme, Props>((theme) => ({
    workBoxHeading: {
        fontSize: '24px',
        color: '#fff',
        fontWeight: 600,
        marginBottom: '6px',
    },
    workBoxContainer:{
        display: "inline-flex",
        cursor:"pointer"
    },
    workBoxParagraph: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
    },
    workBox_circle: {
        width: '65px',
        height: '65px',
        background: '#fbfbfb70',
        borderRadius: '100%',
        top: '-19px',
        right: '-20',
        position: 'absolute',
    },
    workBox: {

        position: 'relative',
        overflow: 'hidden',
        padding: '16px',
        borderRadius: '8px',
        background: 'linear-gradient(32deg, rgba(1,86,162,1) 0%, rgba(75,144,205,1) 56%, rgba(179,219,255,1) 100%)',
        [theme.breakpoints.down('sm')]: {
            textAlign:'center',
        }
    },
    workImage: {
        marginBottom: '15px',
        textAlign:'center',
       
    },
    boxImage: {
        marginLeft: '5px',
        display: 'flex',
        alignItems: 'center'
    },
    img1: {
        width:'100px',
        height:'100px',
        [theme.breakpoints.down('sm')]: {
            width:'150px',
            height:'150px',   
        }
    }

}))

const Collaborator = (props: Props) => {
    const classes = useStyles(props);
    return (
        <Grid item xs={12} sm={6} md={3}>
            <Box className={classes.workBox}>
                <Box className={classes.workBox_circle}></Box>
                <Box className={classes.workImage}>
                    <img src={props.workImage} className={classes.img1} />
                </Box>
                <Typography className={classes.workBoxHeading}>{props.title}</Typography>
                <Link className={classes.workBoxContainer} href={props.link} >
                <Typography className={classes.workBoxParagraph}>Learn More </Typography>
                <Box className={classes.boxImage}> <img src={props.rightArrowIcon} width="24px" height="24px" /></Box>
                </Link>
            </Box>
        </Grid>
    );
}

export default Collaborator;
