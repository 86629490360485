import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config.js");
// Customizable Area Start
// Customizable Area End
let config = require("../../../../framework/src/config");

// Customizable Area Start
export interface Props {
  navigation: any;
  id: string;
  history: any;
}
export interface S {
  firstRowIndex: number,
  lastRowIndex: number,
  impactReportList: any,
  needAnalysisList: any,
  ImpactYearFilter: any,
  ImpactcourseFilter: any
  ImpactlistYear: any,
  courseImpactTitle: any,
  pageNumber: any,
  totalReports: any,
  filterimpactCourseList: any;
  isLoading: boolean;
}
interface SS { }
// Customizable Area End
export default class SchoolReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  ImpactanalysisDataApiCallId: any = "";
  NeedanalysisDataApiCallId: any = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      isLoading: false,
      firstRowIndex: 0,
      lastRowIndex: 10,
      impactReportList: [],
      needAnalysisList: [],
      courseImpactTitle: [],
      ImpactlistYear: [],
      ImpactYearFilter: { title: "", id: 0 },
      ImpactcourseFilter: { title: "", id: 0 },
      filterimpactCourseList: [],
      pageNumber: 1,
      totalReports: 1,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getImactData();
    this.getNeedData()
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.handleApiResponse(apiRequestCallId, responseJson)
      }
    }
  }

  handleApiResponse = async (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {

      case this.ImpactanalysisDataApiCallId:
        if (responseJson) {
          const resCourse = responseJson.filters.mind_mastery_courses?.map((item: any) => item.title)
          this.setState({ impactReportList: responseJson.reports, totalReports: responseJson.count, filterimpactCourseList: ["Select Course", ...resCourse], courseImpactTitle: responseJson.filters.mind_mastery_courses, ImpactlistYear: ["Select Academic Year", ...responseJson.filters?.academic_years] })
        }
        break;
      case this.NeedanalysisDataApiCallId:
        if (responseJson) {
          this.setState({ needAnalysisList: responseJson.reports })
        }
    }
  }

  hanlePageChange = (page: any) => {
    this.setState({ pageNumber: page }, () => {
      let endPoint = `${configJSON.Report.programImpactEndPoint}?page=${this.state.pageNumber}`;
      if (!this.state.ImpactYearFilter.title?.toLowerCase().includes("select") && this.state.ImpactYearFilter.title !== "") {
        endPoint = endPoint + `&academic_year=${this.state.ImpactYearFilter?.title}`;
      }
      if (!this.state.ImpactcourseFilter.title?.toLowerCase().includes("select") && this.state.ImpactcourseFilter.title !== "") {
        const ImpactSelectId = this.state.courseImpactTitle?.filter((course: any) => {
          return course.title === this.state.ImpactcourseFilter.title;
        });
        endPoint = endPoint + `&mind_mastery_course_id=${ImpactSelectId[0]?.id}`
      }
      const header = {
        Authorization: this.getToken()
      };
      const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.ImpactanalysisDataApiCallId = apiRequest.messageId;
      this.schoolReaderApicall(
        apiRequest.messageId,
        configJSON.dashboardGetApiMethod,
        endPoint,
        header
      );
    });
  }

  filterEndpointprams = (id: any, callerName: string) => {
    if (callerName === configJSON.Report.courseFilter) {
      if (this.state.ImpactYearFilter.title?.toLowerCase().includes("select") || this.state.ImpactYearFilter.title === "") {
        return this.state.ImpactcourseFilter.title?.toLowerCase().includes("select") ? `bx_block_reports/program_impact_reports` : `bx_block_reports/program_impact_reports?mind_mastery_course_id=${id}`
      } else {
        return this.state.ImpactcourseFilter.title?.toLowerCase().includes("select") ? `bx_block_reports/program_impact_reports?academic_year=${this.state.ImpactYearFilter.title}` : `bx_block_reports/program_impact_reports?mind_mastery_course_id=${id}&academic_year=${this.state.ImpactYearFilter.title}`
      }
    }
    if (callerName === configJSON.Report.YearFilter) {
      return this.createYearFilterEndPoint();
    }
    return "";
  }

  createYearFilterEndPoint = () => {
    if (this.state.ImpactcourseFilter.title?.toLowerCase().includes("select") || this.state.ImpactcourseFilter?.title === "") {
      return this.state.ImpactYearFilter.title?.toLowerCase().includes("select") ? `bx_block_reports/program_impact_reports` : `bx_block_reports/program_impact_reports?academic_year=${this.state.ImpactYearFilter.title}`
    } else {
      const ImpactSelectId = this.state.courseImpactTitle?.filter((course: any) => {
        return course.title === this.state.ImpactcourseFilter.title;
      })
      return this.state.ImpactYearFilter.title?.toLowerCase().includes("select") ? `bx_block_reports/program_impact_reports?mind_mastery_course_id=${ImpactSelectId[0].id}` : `bx_block_reports/program_impact_reports?mind_mastery_course_id=${ImpactSelectId[0].id}&academic_year=${this.state.ImpactYearFilter.title}`
    }
  }

  handleFilterCourseChange = async (event: any) => {
    const ImpactSelectId = this.state.filterimpactCourseList?.filter((course: any) => {
      return course === event.target.value;
    })
    const CourseId = this.state.courseImpactTitle?.filter((course: any) => {
      return course.title === ImpactSelectId[0];
    })
    this.setState({
      ImpactcourseFilter: { title: ImpactSelectId[0] },
      pageNumber: 1
    }, () => {
      const header = {
        Authorization: this.getToken()
      };
      const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.ImpactanalysisDataApiCallId = apiRequest.messageId;
      this.schoolReaderApicall(
        apiRequest.messageId,
        configJSON.dashboardGetApiMethod,
        this.filterEndpointprams(CourseId[0]?.id, configJSON.Report.courseFilter),
        header
      );
    });
  }

  handleFilterYearChange = async (event: any) => {
    const ImpactSelectYear = this.state.ImpactlistYear?.filter((year: any) => {
      return year === event.target.value;
    })
    this.setState({
      ImpactYearFilter: { title: ImpactSelectYear[0] },
      pageNumber: 1
    }, () => {
      const header = {
        Authorization: this.getToken()
      };
      const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.ImpactanalysisDataApiCallId = apiRequest.messageId;
      this.schoolReaderApicall(
        apiRequest.messageId,
        configJSON.dashboardGetApiMethod,
        this.filterEndpointprams("", configJSON.Report.YearFilter),
        header
      );
    });
  }

  async schoolReaderApicall(
    uniqueAPICallId: string,
    method: string,
    endpoint: string,
    headers: any,
    body?: any
  ) {
    let CompleteFullURL = endpoint.indexOf("://") === -1 ? config.baseURL + "/" + endpoint : endpoint;
    let apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    )
    apiResponseMessage.addData(
      getName(MessageEnum.RestAPIResponceDataMessage), uniqueAPICallId)
    try {
      let response = await fetch(CompleteFullURL, {
        method: method.toUpperCase(),
        headers: headers,
        body: body
      });
      if (response.status === 401) {
        this.goTo("LoginForm");
      }
      let responseJson = await response.json();
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage), responseJson)
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      console.log("Api Error" + JSON.stringify(error));
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        "Please try again later."
      );
    }
    this.send(apiResponseMessage);
  }

  async getImactData() {
    const header = {
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.ImpactanalysisDataApiCallId = apiRequest.messageId;
    await this.schoolReaderApicall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.Report.getImactDataEndPoint,
      header
    );
  }

  async getNeedData() {
    const header = {
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.NeedanalysisDataApiCallId = apiRequest.messageId;
    await this.schoolReaderApicall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.Report.getNeedDataEndPoint,
      header
    );
  }

  goBack = () => {
    this.props.navigation?.goBack();
  };

  getToken = () => {
    return localStorage.getItem('user_token')
  }

  goTo = (module: string, params: Object = {}) => {
    this.props.navigation.navigate(module, { ...params });
  };
  // Customizable Area End
}
