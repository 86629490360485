import React from "react";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles: any = makeStyles(() => ({
    paginationWrapper: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row-reverse',
        '@media(max-width:600px)': {
            justifyContent: 'center'
        }
    },
    pagination: {
        '& .MuiPaginationItem-outlinedPrimary.Mui-selected': {
            color: 'white',
            fontFamily: "Poppins",
            border: '1px solid rgba(63, 81, 181, 0.5)',
            backgroundColor: '#0056A3'
        },
        '& button[aria-label="Go to previous page"]:not(.Mui-disabled)': {
            color: 'white',
            border: '1px solid rgba(63, 81, 181, 0.5)',
            backgroundColor: '#0056A3'
        },
        '& button[aria-label="Go to next page"]:not(.Mui-disabled)': {
            color: 'white',
            border: '1px solid rgba(63, 81, 181, 0.5)',
            backgroundColor: '#0056A3'
        }
    }
}));

interface Props {
    pageCount: number;
    page: number;
    handlePage: (page: number) => void
}

const CommonPagination = (props: Props) => {
    const classes = useStyles();
    const { handlePage, page, pageCount } = props;
    return <Box className={classes.paginationWrapper}>
        <Pagination
            className={classes.pagination}
            count={pageCount}
            color='primary'
            size="large"
            page={page}
            variant="outlined"
            data-test-id='pagination'
            shape="rounded"
            onChange={(_event: Object, page: number) => { handlePage(page) }} />
    </Box>
}
export default CommonPagination;