
import React from 'react'
// Customizable Area Start
import EditStudentReportController from "./EditStudentReportController.web"
import SidebarLayout from "../../SidebarLayout.web";
import { Grid, Typography, Button, Box, Snackbar } from "@material-ui/core";
import "./EditStudentReport.css";
import { selectedIcon, unSelectedIcon, EmptyStarIcon, FilledStarIcon } from "../../assets";
import PageContentLayout from '../../PageContentLayout.web';
import InfoModal from '../../../../../components/src/CommonInformationModal.web';
import CommonTextField from '../../../../../components/src/commonTextField.web';
import CommonSnackBar from '../../../../../components/src/commonSnackBar.web';
import Loader from '../../../../../components/src/Loader.web';
// Customizable Area End
const configJSON = require("../../config");


class EditStudentReport extends EditStudentReportController {
    // Customizable Area Start
    renderHeadingThemePage() {
        return <Grid container spacing={0}>
            {this.state.headingThemesArray.map((theme: any) => {
                return <Grid key={theme.id} xs={12} sm={6} lg={4} item >
                    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}
                        data-test-id="selectThemeWrapper"
                        className={this.state.selectedHeadingTheme === theme.id ? 'imageWrapperSelected' : 'imageWrapperUnselected'}
                        onClick={() => this.handleHeadingThemeClick(theme.id)}>
                        <Box className="themeImageWrapper" style={{}}>
                            <img
                                className="themeImageItem"
                                src={theme.attributes.image_url} alt="badge img" />
                        </Box>
                        <div style={webStyle.themeTitleWrapper}>
                            <p className='themeTitle'>{theme.attributes.name}</p>
                            {this.state.selectedHeadingTheme === theme.id ? <img className="selectIcon" src={selectedIcon} /> :
                                <img
                                    className="selectIcon"
                                    src={unSelectedIcon} />}
                        </div>
                    </Box>
                </Grid>
            })}
        </Grid>
    }

    renderEndingThemePage() {
        return <Grid container spacing={0}>
            {this.state.endingThemesArray.map((theme: any) => {
                return <Grid key={theme.id} xs={12} sm={6} lg={4} item >
                    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}
                        data-test-id="selectEndingThemeWrapper"
                        className={this.state.selectedEndingTheme === theme.id ? 'imageWrapperSelected' : 'imageWrapperUnselected'}
                        onClick={() => this.handleEndingThemeClick(theme.id)}>
                        <Box className="themeImageWrapper" style={{}}>
                            <img
                                className="themeImageItem"
                                src={theme.attributes.image_url} alt="badge img" />
                        </Box>
                        <div className="themeTitleWrapper" style={webStyle.themeTitleWrapper}>
                            <p className='themeTitle'>
                                {theme.attributes.name}
                            </p>
                            {this.state.selectedEndingTheme === theme.id ? <img className="selectIcon" src={selectedIcon} /> :
                                <img
                                    className="selectIcon"
                                    src={unSelectedIcon} />}
                        </div>
                    </Box>
                </Grid>
            })}
        </Grid>
    }

    renderSelectBadgePage() {
        return <Grid className="themeContainerWrapper" container >
            {this.state.badgesArray.map((badge: any) => {
                return (
                    <Grid key={badge.id} xs={12} sm={6} lg={4} item >
                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}
                            data-test-id="selectBadgeWrapper"
                            className={this.state.selectedBadge === badge.id ? 'imageWrapperSelected' : 'imageWrapperUnselected'}
                            onClick={() => this.handleSelectBadgeClick(badge.id)}>
                            <Box className="themeImageWrapper" style={{}}>
                                <img className="themeImageItem" src={badge.attributes.image_url} alt="badge img" />
                            </Box>
                            <div className="themeTitleWrapper" style={webStyle.themeTitleWrapper}>
                                <p className='themeTitle'>{badge.attributes.name}</p>
                                {this.state.selectedBadge === badge.id ? <img className="selectIcon" src={selectedIcon} /> :
                                    <img className="selectIcon" src={unSelectedIcon} />}
                            </div>
                        </Box>
                    </Grid>
                )
            })}
        </Grid>
    }

    renderStudentsReportForm() {
        return <>
            <Box data-test-id='box'>
                <Grid className='formContainer' spacing={2} container>
                    <Grid xs={12} item>
                        <Typography className="skillsFormHeading">Skills acquired</Typography>
                    </Grid>
                    <Grid style={{ marginBottom: '20px' }} container spacing={2}>
                        {this.state.ratings.map((skill: any) => {
                            if (skill !== undefined) {
                                return <Grid key={skill.name} xs={12} item>
                                    <Box className="skillsFormBody">
                                        <Box className="skillRatingContainer">
                                            <Typography className="skillNameText" component="span">
                                                {skill.name}
                                            </Typography>
                                            <Box>
                                                {this.renderRating(this.handleSkil1RatingChange, skill.name)}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            }
                        })}
                    </Grid>

                    <Grid xs={12} item>
                        <CommonTextField
                            multiline
                            minRows={1}
                            mb={'0px'}
                            name={configJSON.StudentsReport.AttendenceName}
                            label={configJSON.StudentsReport.Attendence}
                            value={this.state.attendence}
                            handleChange={this.handleAttendenceChange}
                            placeholder={configJSON.StudentsReport.WriteAttendenceHere}
                            data-test-id="AttendenceField"
                            error={this.state.errors.attendenceError}
                            helperText={this.state.errors.attendenceError && configJSON.StudentsReport.FieldNotEmpty}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <CommonTextField
                            multiline
                            minRows={1}
                            mb={'0px'}
                            name={configJSON.StudentsReport.AreasToFocusName}
                            label={configJSON.StudentsReport.AreasToFocus}
                            value={this.state.areasToFocus}
                            handleChange={this.handleAreasToFocusChange}
                            placeholder={configJSON.StudentsReport.WriteYourFeedbackHere}
                            data-test-id="FocusField"
                            error={this.state.errors.areasToFocusError}
                            helperText={this.state.errors.areasToFocusError && configJSON.StudentsReport.FieldNotEmpty}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <CommonTextField
                            multiline
                            minRows={13}
                            mb={'0px'}
                            name={configJSON.StudentsReport.AreasToFocusName}
                            label={configJSON.StudentsReport.FeedbackForStudents}
                            value={this.state.feedbackForStudents}
                            handleChange={this.handleFedbackChange}
                            placeholder={configJSON.StudentsReport.WriteYourFeedbackHere}
                            data-test-id="FeedbackField"
                            error={this.state.errors.stdentFeedbackError}
                            helperText={this.state.errors.stdentFeedbackError && configJSON.StudentsReport.FieldNotEmpty}
                        />
                    </Grid>
                </Grid>
            </Box>
            <InfoModal
                open={this.state.reportPublishedModal}
                closeModal={this.closeModal}
                modalType={configJSON.ModalTypes.Success}
                info={configJSON.StudentsReport.SuccessfulMsg}
                closeModalFunction={this.handleCloseFunction}
            />
        </>
    }

    renderRating = (onChange: any, skillName: string) => {
        const value = this.state.ratings.find((item: any) => {
            return item?.name === skillName;
        })?.rating;
        const handleRatingChange = (newValue: any) => {
            onChange(newValue, skillName);
        };

        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {Array.from({ length: 5 }, (_, index) => (
                    <img
                        key={index}
                        src={index < value ? FilledStarIcon : EmptyStarIcon}
                        alt={index < value ? 'Filled Star' : 'Empty Star'}
                        onClick={() => handleRatingChange(index + 1)}
                        className='ratingIcon'
                    />
                ))}
            </Box>
        );
    };

    renderNextButton() {
        return (
            <Button
                data-test-id="selectNextBtn"
                style={webStyle.nextButton}
                onClick={() => this.handleNextClick()}
                className='nextButton'
            >
                {this.state.currentPage === configJSON.StudentsReport.StudentsReport ? "Done" : "Next"}
            </Button>
        )
    }

    renderEditStudentReport() {
        return (
            <>
                <Box style={{ position: "relative", padding: "32px 24px 24px 24px" }}>
                    <Box style={{ marginBottom: '40px' }}>
                        {this.state.currentPage === configJSON.StudentsReport.SelectHeadingTheme && this.renderHeadingThemePage()}
                        {this.state.currentPage === configJSON.StudentsReport.SelectEndingTheme && this.renderEndingThemePage()}
                        {this.state.currentPage === configJSON.StudentsReport.StudentsReport && this.renderStudentsReportForm()}
                        {this.state.currentPage === configJSON.StudentsReport.SelectBadge && this.renderSelectBadgePage()}
                    </Box>
                    {this.renderNextButton()}
                    {this.state.openLoader ? <Loader loading={this.state.openLoader} /> : null}
                    <CommonSnackBar
                        type='error'
                        verticalPosition='top'
                        horizontalPosition='center'
                        open={this.state.snackBarIsOpenSr}
                        onClose={() => this.onSrSnackbarClose()}
                        message={`Error: ${this.state.snackBarMsgSr}`}
                        duration={4000}
                    />
                </Box>
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return <>
            <SidebarLayout {...this.props} >
                <PageContentLayout title={this.state.currentPage} goBack={this.goBack}>
                    {this.renderEditStudentReport()}
                </PageContentLayout>
            </SidebarLayout>
        </>
        // Customizable Area End
    }
}

export default EditStudentReport;

// Customizable Area Start
const webStyle = {
    themeImageSr: {
        height: "15.63vw",
        width: "20.486vw"
    },
    imageWrapperSr: {
        height: "70%",
        width: "70%",
        padding: "1.181vw",
        margin: "0.8vw",
        boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        cursor: "pointer"
    },
    titleContainer: {
        marginTop: "1.042vw",

    },

    nextButton: {
        padding: "0",
        background: "#0056A3",
        height: "48px",
        width: "175px",
        marginLeft: 'auto',
        display: 'block',
        fontSize: "16px",
        textTransform: 'none' as const,
        color: "fff",
        "&:hover": {
            background: "#010E36",
            opacity: 0.8
        }
    },
    themeTitleWrapper: {
        width: "90%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "4% 0"
    }
}
// Customizable Area End