import React, { ReactNode } from 'react'
import { Typography, TextField, InputAdornment } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";

interface StyleProps {
    labelFontColor?: string | undefined;
    labelFontWeight?: number | undefined;
    labelFontSize?: string;
    mb?: string | number;
    halfWidth?: boolean | string | number;
    multiline?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    fieldLabel: {
        color: (props) => props.labelFontColor || '#3B3B3B',
        fontFamily: 'Poppins',
        fontWeight: (props) => props.labelFontWeight || 600,
        marginBottom: '7px',
        [theme.breakpoints.up("sm")]: {
            fontSize: (props: StyleProps) => props.labelFontSize || '20px',
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
        },
    },
    inputField: {
        '&.MuiFormControl-root': {
            minWidth: (props: StyleProps) => props.halfWidth ? '40%' : '100%',
            marginBottom: (props: StyleProps) => props.mb || '24px',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2",
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #066CD2",
        },
        '& .MuiOutlinedInput-input': {
            padding: (props: StyleProps) => props.multiline ? '0 7px' : '0 14px 0 18px',
            height: '100%',
        },
        "& .MuiOutlinedInput-root": {
            width: "100%",
            height: (props: StyleProps) => props.multiline ? 'auto' : "56px",
            backgroundColor: 'white',
            borderRadius: '8px',
            marginLeft: 'auto',
            color: 'black',
            fontSize: '14px',
        },
        '& .MuiSelect-outlined.MuiSelect-outlined': {
            paddingRight: '2px',
        },
        '& .MuiFormHelperText-contained': {
            marginLeft: '0',
            marginTop: '5px',
        },
    },
}));



interface Props {
    labelFontColor?: string | undefined;
    labelFontWeight?: number | undefined;
    labelFontSize?: string;
    mb?: string | number;
    halfWidth?: boolean | string | number;
    multiline?: boolean;
    minRows?: number;
    label?: string;
    disabled?: boolean;
    name?: string;
    value?: string | number;
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error?: boolean;
    helperText?: string | React.ReactNode;
    placeholder?: string;
    type?: string;
    endAdornmentData?: React.ReactNode;
    startAdornment?: React.ReactNode;
    inputProps?: unknown;
}

const CommonTextField = (props: Props) => {
    const classes = useStyles(props)
    return <>
        {props.label && <Typography className={classes.fieldLabel}>{props.label}</Typography>}
        <TextField
            disabled={props.disabled}
            className={classes.inputField}
            onChange={props.handleChange}
            multiline={props.multiline}
            minRows={props.minRows}
            name={props.name}
            variant='outlined'
            data-test-id='input'
            type={props.type ? props.type : 'text'}
            value={props.value}
            error={props.error}
            helperText={props.helperText}
            placeholder={props.placeholder}
            inputProps={props.inputProps as undefined}
            InputProps={{
                startAdornment: props.startAdornment ?
                    <InputAdornment position="start">
                        {props.startAdornment}
                    </InputAdornment> : null,
                endAdornment: (
                    <InputAdornment position="end">
                        {props.endAdornmentData}
                    </InputAdornment>
                ),
            }}
        />
    </>
}
export default CommonTextField;