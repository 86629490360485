import React, { useState, useEffect } from "react";
// Customizable Area Start
import { arrowRight, backArrow, arrowDown, paper } from "./assets";
import {
  Box,
  Button,
  Typography,
  Switch,
  Select,
  MenuItem,
  ButtonGroup
} from "@material-ui/core";
import { makeStyles, styled , Theme} from "@material-ui/core/styles";
import moment from "moment";
import RefreshRounded from '@material-ui/icons/RefreshRounded';
// Customizable Area End
const configJSON = require("./config");

// Customizable Area Start
const useStyles = makeStyles<Theme, any>((theme: Theme) => ({
  pageLayoutWrapper: {
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "2px 10px 16px rgba(0, 0, 0, 0.0908271)",
    borderRadius: "12px",
    "@media(min-width:1440px)": {
      margin: "0 auto",
      // maxWidth: "1440px",
    },
  },
  viewSelect: {
    background: "rgba(255, 255, 255, 0.24)",
    mixBlendMode: "normal",
    borderRadius: "8px",
    width: "133px",
    height: "36px",
    marginRight: '16px',
    paddingLeft: "16px",
    color: "#FFFFFF",
    "@media(max-width:767px)": {
      marginBottom: '10px'
    },
    "&::after": {
      borderBottom: "none",
    },
    "&::hover": {
      boxShadow: "none",
      background: "rgba(255, 255, 255, 0.34)",
    },
  },
  topBar: {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: "center",
    background:
      "linear-gradient(-131deg, #B3DBFF -33.84%, #4B90CD 19.41%, #0156A2 95.54%)",
    borderRadius: "12px 12px 0px 0px",
    minHeight: "80px",
    padding: '0 24px',
    "@media(max-width:767px)": {
      padding: (props:{isProgramScheduler:string,addPadding : string}) => props.isProgramScheduler || props.addPadding ? '8px 24px' : '0 24px'
    }
  },
  backButton: {
    padding: "0",
    background: "#010E36",
    minHeight: "48px",
    minWidth: "48px",
    opacity: 0.5,
    borderRadius: "8px",
    margin: "0 24px 0 0",
    "&:hover": {
      background: "#010E36",
      opacity: 0.8,
    },
  },
  addEventButton: {
    padding: "0",
    height: "48px",
    width: "140px",
    background: "#FFFFFF40",
    borderRadius: "8px",
    color: "#FFFFFF",
    textTransform: "none",
    margin: "0 24px",
    "&:hover": {
      background: "#010E36",
      opacity: 0.8,
    },
  },

  pageTitle: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "33px",
    color: "white",
    fontFamily: "Poppins",
    "@media(max-width:575px)": {
      lineHeight: "22px",
      fontSize: "16px",
    },
  },
  backArrow: {
    height: "24px",
    width: "24px",
  },
  mindMasteryPageContent: {
    padding: "32px",
  },
  userIcon: {
    border: "1px solid #0056A3",
    filter: "drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.246121))",
    width: "64px",
    height: "64px",
  },
  dropDown: {
    '&.MuiFormControl-root': {
      minWidth: '100%'
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #066CD2",
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #066CD2",
    },
    "&.MuiOutlinedInput-root": {
      height: "36px",
      backgroundColor: "#ffffff4f",
      borderRadius: "8px",
      marginLeft: "auto",
      width: "220px",
      fontSize: "14px",
      color: "white",
      "@media(max-width:600px)": {
        marginTop: '5px'
      },
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingLeft: "8px",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: "2px",
    },
  },
  menuItems: {
    "&.MuiMenuItem-root": {
      display: "flex",
      width: "100%",
      minHeight: 'auto',
      justifyContent: "space-between",
    },
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#0056a314",
    },
    "&.MuiListItem-root:hover": {
      backgroundColor: "#0056a314",
    },
  },
  menu: {
    '& .MuiList-padding': {
      padding: '0'
    },
    "& .MuiPopover-paper": {
      border: "1px solid #066CD2",
      borderRadius: "8px",
      boxShadow: "0px 10px 16px rgba(165, 176, 194, 0.15)",
      maxHeight: '155px',
    },
    '& .MuiPopover-paper.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded': {
      overflow: 'hidden auto'
    }
  },
  prePostMenuItem: {
    "&.MuiMenuItem-root": {
      minHeight: 'auto',
      display: "flex",
      width: "100%",
      flexDirection: 'row-reverse',
      justifyContent: "space-between",
    },
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#0056a314",
    },
    "&.MuiListItem-root:hover": {
      backgroundColor: "#0056a314",
    },
  },
  paper: {
    marginTop: "35px",
  },
  placeholderPaper: {
    marginTop: "35px",
  },
  calendarSwitch: {
    flexDirection: "row",
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    '@media(max-width:767px)': {
      flexDirection: 'column'
    }
  },
  activeOption: {
    fontStyle: "normal",
    fontWeight: 700,
    fontamily: "Poppins",
    color: "#FFFFFF",
    fontSize: "14px",
    lineHeight: "21px",
    textAlign:'center'
  },
  inActiveOption: {
    fontamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#FFFFFF",
    mixBlendMode: "normal",
    opacity: 0.83,
    textAlign:'center'
  },
  dayChangerBox: {
    padding: '5px 0',
    width: 244,
    color: "#FFFFFF",
    borderRadius: "8px",
    background: "rgba(255, 255, 255, 0.24)",
    paddingLeft: 16,
    display: "flex",
    alignItems: "center",
    marginRight: '10px',
    '@media(max-width:410px)': {
      width: '100%'
    }
  },
  calendarActionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media(max-width:767px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  refreshButton: {
    textTransform: 'none',
    padding: 10,
    background: '#77abd9',
    border: '1px solid #0056A3',
    borderRadius: '8px',
    color: '#F1F1F1',
  },
  addMpowerYouMatterBtn: {
    "&.MuiButton-root": {
      height: '48px',
      width: '216px',
      color: 'white',
      marginLeft: 'auto',
      borderRadius: '8px',
      background: 'rgba(255, 255, 255, 0.249809)',
      border: '1px solid #0056A3',
      '@media(max-width:600px)': {
        width: 'max-content',
      }
    },
    "& .MuiButton-label": {
      fontSize: "14px",
      fontFamily: "Poppins",
      lineHeight: "21px",
      textTransform: "capitalize",
      fontWeight: 600,
    },
  },
  dashboardTopBtns: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "auto",
  },
  dropdownWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "auto",
    minWidth: "460px",
    "@media(max-width:767px)": {
      justifyContent: "space-evenly",
      width: "auto",
      minWidth: 'auto',
      height: "125px",
      flexDirection: "column",
    },
  },
  subtitle: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontWeight: 600
  },
  titleDropdownWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    width: props => props.titleWrapperWidth ? props.titleWrapperWidth : 'auto',
    '@media(max-width:600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  }
}));

const Android12Switch = styled(Switch)(() => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: "rgba(256, 256, 256, 0.33)",
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      left: 12,
    },
    "&:after": {
      right: 12,
    },
  },
  "& .Mui-checked": {
    color: "white", // Change this to your desired color when the switch is checked
  },
  "& .Mui-checked + .MuiSwitch-track": {
    backgroundColor: "rgba(256, 256, 256, 0.33)", // Change this to your desired color for the track when the switch is checked
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    margin: 2,
    height: 16,
    width: 16,
  },
}));

export const SelectMenuIcon = () => {
  return <img style={webStyles.arrowDownImage} src={arrowDown} alt="" />;
};

export const HiddenComponent = () => {
  return null;
};

const renderIcon = (icon: string) => {
  if (icon === "document") {
    return <img style={webStyles.iconStyle} src={paper} alt="" />;
  } else {
    return <img style={webStyles.iconStyle} src={arrowRight} alt="" />;
  }
};

const isProgramSchedulScreen = (isProgramScheduler: boolean, guestView: boolean) => {
  return isProgramScheduler && !guestView
}

const checkMatterText = (isYouMatter: boolean) => {
  return isYouMatter ? configJSON.PageContentLayoutTexts.AddNewYouMatter : configJSON.PageContentLayoutTexts.AddNewMPower;
}
// Customizable Area End

export const PageContentLayout = (props: any) => {
  // Customizable Area Start
  const classes = useStyles(props);
  const [menuItemvalue, setMenuItemValue] = React.useState("");
  const [currentDateString, setCurrentDateString] = useState("");
  const [userChange, setUserChange] = useState(0);

  const user = JSON.parse(`${localStorage.getItem(configJSON.PageContentLayoutTexts.UserDetails)}`);
  const role = user?.attributes?.role;

  const QuestionaireMenuList = [{ id: configJSON.PageContentLayoutTexts.PreProgram, value: configJSON.PageContentLayoutTexts.PreProgram, title: 'Pre Program', image: arrowRight }, { id: 'Post Program', value: configJSON.PageContentLayoutTexts.PostProgram, title: configJSON.PageContentLayoutTexts.PostProgram, image: arrowRight }]

  const leftDateChange = () => {
    setUserChange((userChange) => userChange - 1);
    props.onDateChange(false);
  };
  const rightDateChange = () => {
    setUserChange((userChange) => userChange + 1);
    props.onDateChange(true);
  };
  const toggleView = props.listView;

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    if (props.handleCalendarView) {
      props.handleCalendarView(event.target.value);
    }
    if (props.handleFormDropDown) {
      setMenuItemValue(event.target.value);
      props.handleFormDropDown(event.target.value);
    }
    if (props.handleSelectCourse) {
      props.handleSelectCourse(Number(event.target.value))
    }
  };

  useEffect(() => {
    const check = moment(props.calendarDate, "YYYY/MM/DD");
    const month = check.format("MMMM");
    const day = check.format("D");
    const year = check.format("YYYY");
    const isDayView = props.calendarView === "day" ? ` ${day}, ` : " ";
    setCurrentDateString(month + isDayView + year);
  }, [props.calendarDate, props.calendarView, userChange]);

  function getOptionStyles(isActive: any, isToggleView: any) {
    let optionStyle;

    if (isToggleView) {
      optionStyle = isActive ? classes.activeOption : classes.inActiveOption;
    } else {
      optionStyle = isActive ? classes.inActiveOption : classes.activeOption;
    }

    return optionStyle;
  }

  const path = window.location.pathname;
  const isYouMatter = path.includes(configJSON.PageContentLayoutTexts.YouMatter);
  const checkTo = getOptionStyles(false, toggleView);
  const checkToggle = getOptionStyles(true, toggleView);
  const checkToReport = configJSON.PageContentLayoutTexts.AddNewReport;
  const checkToMatter = checkMatterText(isYouMatter)
  const check = isProgramSchedulScreen(props.isProgramScheduler, props.guestView);

  const SubHeading = () => {
    if (props.subtitle) {
      return <Typography className={classes.subtitle}>{props.subtitle}</Typography>
    }
    else {
      return null;
    }
  }

  return (
    <>
      <Box className={classes.pageLayoutWrapper}>
        <Box className={classes.topBar}>
          {!props.guestView && !toggleView && props.isProgramScheduler ? (
            <Box className={classes.calendarActionWrapper}>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={props.calendarView}
                className={classes.viewSelect}
                label="Age"
                defaultValue={"month"}
                disableUnderline
                IconComponent={SelectMenuIcon}
                onChange={handleChange}
              >
                <MenuItem value={"day"}>Day</MenuItem>
                <MenuItem value={"week"}>Week</MenuItem>
                <MenuItem value={"month"}>Month</MenuItem>
              </Select>
              <Box className={classes.dayChangerBox}>
                {currentDateString}
                <ButtonGroup style={webStyles.btnGroup}>
                  <Button
                    style={webStyles.iconButton}
                    onClick={() => leftDateChange()}
                  >
                    <img style={webStyles.leftArrow} src={arrowDown} alt="" />
                  </Button>
                  <Button
                    style={webStyles.iconButton}
                    onClick={() => rightDateChange()}
                  >
                    <img style={webStyles.rightArrow} src={arrowDown} alt="" />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          ) : (
            <Box style={{ display: 'flex', columnGap: 20 }}>
              {props.goBack && <Button
                className={classes.backButton}
                data-test-id='backBtn'
                onClick={props.goBack}>
                <img className={classes.backArrow} src={backArrow} alt="backIcon" />
              </Button>}
              <Box className={classes.titleDropdownWrapper}>
                <Box flexDirection={'column'} style={{ marginRight: 'auto' }}>
                  <Typography className={classes.pageTitle}>
                    {props.title}
                  </Typography>
                  {SubHeading()}
                </Box>
              </Box>
              {role === "Trainer" && check && (
                <Button
                  className={classes.addEventButton}
                  onClick={() => {
                    props.showAddModal();
                  }}
                >
                  Add Event
                </Button>
              )}
            </Box>
          )}

          {check && (
            <Box className={classes.calendarSwitch}>
              <Typography className={checkTo}>Calendar View</Typography>
              <Android12Switch
                data-test-id='calendar-switch'
                checked={toggleView}
                onClick={() => props.toggleView()}
                value="active"
              />
              <Typography className={checkToggle}>List View</Typography>
            </Box>
          )}

          {(props.addMpowerYouMatter || props.needAnalysis) && (
            <Box className={classes.dashboardTopBtns}>
              {props.addMpowerYouMatter && (
                <Button
                  className={classes.addMpowerYouMatterBtn}
                  data-test-id="addMpowerYouMatter"
                  onClick={props.addMpowerYouMatter}
                >
                  {checkToMatter}
                </Button>
              )}
              {props.needAnalysis && (
                <Button
                  style={{ marginRight: 10 }}
                  className={classes.addMpowerYouMatterBtn}
                  data-test-id="needAnalysis"
                  onClick={props.needAnalysis}
                >
                  {checkToReport}
                </Button>
              )}
            </Box>
          )}


          {props.questionBankFilters && (
            <Box className={classes.dropdownWrapper}>
              <Button disabled className={classes.addMpowerYouMatterBtn}>
                {props.questionBankFilters?.courseName}
              </Button>
              <Button disabled className={classes.addMpowerYouMatterBtn}>
                {props.questionBankFilters?.sectionName}
              </Button>
            </Box>
          )}

          {props.addRepost && (
            <Button
              data-test-id="addReport"
              className={classes.addMpowerYouMatterBtn}
              onClick={props.addReader}
            >
              {configJSON.PageContentLayoutTexts.AddNewReport}
            </Button>
          )}
          <Box style={{ display: 'flex', columnGap: 10 }}>
            {props.handlePublishReport && <Button data-test-id='publish-report' style={{ textTransform: "none" }} className={classes.addMpowerYouMatterBtn} onClick={props.handlePublishReport} >{props.callingPage === "proCommonStudentReportHeadergramImpactReport" ? "Add New Report" : "Publish Report"}</Button>}
            {props.handleReportRefresh && <Button data-test-id='refresh-report' className={classes.refreshButton} size="small" onClick={props.handleReportRefresh}><RefreshRounded /></Button>}
          </Box>
        </Box>
        <>{props.children}</>
      </Box>
    </>
  );
  // Customizable Area End
};

export default PageContentLayout;

// Customizable Area Start
const webStyles = {
  iconStyle: { width: "24px", height: "24px" },
  arrowDownImage: {
    width: "16px",
    height: "16px",
    position: "absolute" as const,
    right: "16px",
    pointerEvents: "none" as const,
  },
  leftArrow: {
    width: "16px",
    height: "16px",
    position: "absolute" as const,
    right: "16px",
    transform: "rotate(90deg)",
  },
  rightArrow: {
    width: "16px",
    height: "16px",
    position: "absolute" as const,
    right: "16px",
    transform: "rotate(270deg)",
  },
  btnGroup: {
    display: "flex",
    marginLeft: "auto",
  },
  iconButton: {
    background: "none",
    height: 22,
    width: 32,
    opacity: "none",
    border: "none",
    "&::hover": {
      opacity: 0.5,
    },
  },
};
// Customizable Area End