import React from "react";
// Customizable Area Start
import SidebarLayout from "../../SidebarLayout.web";
import { Box, styled } from "@material-ui/core";
import MaterialDetailsController, {
  Props
} from "./MaterialDetailsController.web";
import PageContentLayout from "../../PageContentLayout.web";
//@ts-ignore
import { Document, Page , pdfjs} from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import FileViewer from 'react-file-viewer';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// Customizable Area End

export default class MaterialDetails extends MaterialDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { numPages } = this.state;
    return (
      // Customizable Area Start
      <>
        <SidebarLayout {...this.props}>
          <PageContentLayout
            className="boxHide"
            goBack={this.goBack}
            data-test-id="goBackTestId"
          >
            <IframeStyle>    
                <Box
                  className="pdfBoxStyle"
                >
                  {this.state.modifiedPdfUrl && 
                  <DocViewer
                    className="iframeStyle"
                    data-test-id="docViewer"
                    documents={[
                      {
                        uri: this.state.modifiedPdfUrl,
                      },
                    ]}
                    config={{
                      header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: true,
                      },
                    }}
                    pluginRenderers={DocViewerRenderers}
                  />
                  }
                   <Box className="boxHides"></Box>
                </Box>
              <Box
                data-test-id="disableStyle"
                className="boxStyle"
                onContextMenu={this.disableContextMenu}
              />
            </IframeStyle>
          </PageContentLayout>
        </SidebarLayout>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const IframeStyle = styled(Box)({
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  position:"relative",
  "& .file-viewer-loader":{
    display:" none !important"
  },
  "& .boxHides": {
    position: "absolute",
    height: "20px",
    bottom: "0px",
    width: "93%",
    background: "white",
  },
  "& .documentStyle": {
    height: "97vh",
    overflowX: "auto",
    overflow:"auto",
    maxWidth: "100%", 
    "& .file-viewer-loader":{
      display:" none !important"
    },
    "& .pdf-loading":{
      display:"none"
    },
  },
  "& .documentStyles": {
    height: "97vh",
    overflowX: "auto",
    maxWidth: "93%", 
  },
  "& .pdfBoxStyle":{
      width: "93%",
      height: "100vh",
      '& canvas': {
        margin: '0 auto'
      }
  },
  "& .fileViewer":{
    maxHeight: "90vh",  
    maxWidth: "80vw", 
  },
  "& .iframeStyle": {
    width: "100%", 
    height: "100vh", 
    border: "none",
    "& .Embed.WACFrameWord": {
      boder: "none !important"
    },
  },
  "& #react-doc-viewer #pdf-download":{
    display: "none"
  },
  "& #WACStatusBarContainer #WordViewerStatusBar":{
    display: "none"
  }
});
// Customizable Area End
