import React from "react";
// Customizable Area Start
import { Typography, Box, Button, Grid, styled } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable, DroppableProvided, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import CloseIcon from "@material-ui/icons/Close";
import { BucketContent, BucketId, DragEndEvent, Option } from './DragDropQuestionController';
import DragDragQuestionController, { Props, configJSON } from "./DragDropQuestionController";
// Customizable Area End

export default class DragDropQuestion extends DragDragQuestionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.renderOption = this.renderOption.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
        this.renderBucketItem = this.renderBucketItem.bind(this);
        this.renderBuckets = this.renderBuckets.bind(this);
        // Customizable Area End
    }
    // Customizable Area Start
    renderOption(option: number, content: string) {
        const { isListView } = this.props;
        return (
            <Box className={`radioLabel radioLabelList-${isListView}`} data-test-id="box">
                <Box className="option">{this.getOptionName(option)}</Box>
                <Typography className="optionContent">{content}</Typography>
            </Box>
        );
    };

    renderOptions(option: Option, index: number) {
        return (
            <Draggable key={`option-${option.id}`} draggableId={`dragging-${option.id}`} index={index} isDragDisabled={option.is_selected} data-test-id="optionDraggableItem">
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <>
                        <Grid className={`optionsContainer ${option.is_selected ? "option-disabled" : ""}`} item xs={6} sm={6} md={6} lg={6} xl={6} innerRef={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Box className="optionsWrapper">
                                <Box className="optionFormLabel">
                                    {this.renderOption(index + 1, option.content)}
                                </Box>
                            </Box>
                        </Grid>
                        {snapshot.isDragging && (
                            <Grid className="optionsContainer isDragging" item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Box className="optionsWrapper">
                                    <Box className="optionFormLabel">
                                        {this.renderOption(index + 1, option.content)}
                                    </Box>
                                </Box>
                            </Grid>
                        )}
                    </>
                )}
            </Draggable>
        )
    }

    renderBucketItem(option: Option, index: number, bucketId: BucketId) {
        return (
            <Draggable key={`bucketItem-${option.id}`} draggableId={`draggingBucket-${option.id}`} index={index} data-test-id="bucketItemDraggable">
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                    return (
                        <>
                            <Grid key={`dropped-${option.id}`} item xs={12} className="droppedItems" innerRef={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <Typography className="droppedItemText">{option.content}</Typography>
                                <Button type="button" variant="text" className="droppedItemDelete" onClick={() => this.handleDelete(bucketId, option.id)} >
                                    <CloseIcon />
                                </Button>
                            </Grid>
                            {snapshot.isDragging && (
                                <Grid key={`dragging-${option.id}`} item xs={12} className="droppedItems">
                                    <Typography className="droppedItemText">{option.content}</Typography>
                                </Grid>
                            )}
                        </>
                    )
                }}
            </Draggable>
        )
    }

    renderBuckets() {
        const { buckets } = this.props.questionData;
        return (
            buckets && buckets.data.map((bucket: BucketContent) => (
                <Grid key={bucket.id} item xs={6}>
                    <Box className="bucketContainer" mb={2} display="flex" alignItems="center" sx={{ border: '2px solid #CBD5E1', borderRadius: '8px' }}>
                        <Droppable key={bucket.id} droppableId={bucket.attributes.is_correct ? 'correctBucketItems' : 'incorrectBucketItems'}>
                            {(provided: DroppableProvided) => (
                                <Grid container spacing={2} innerRef={provided.innerRef} className="choosedItems">
                                    {bucket.attributes.is_correct
                                        ? this.state.correctBucketItems.map((option: Option, index: number) => this.renderBucketItem(option, index, 'correctBucketItems'))
                                        : this.state.incorrectBucketItems.map((option: Option, index: number) => this.renderBucketItem(option, index, 'incorrectBucketItems'))
                                    }
                                </Grid>
                            )}
                        </Droppable>
                    </Box>
                    <Box textAlign="center">
                        <Typography className="bucketTitle">{bucket.attributes.content}</Typography>
                    </Box>
                </Grid>
            ))
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <DragDropContext onDragEnd={this.handleDragEnd}>
                <DragAndDropOptionsContainer className="dragAndDropOptionsContainer" container spacing={2}>
                    <Droppable droppableId="options" isDropDisabled={true} data-test-id="DragDropOptionsContainer">
                        {(provided: DroppableProvided) => (
                            <Grid container spacing={2} innerRef={provided.innerRef}>
                                {this.state.currentOptions.map(this.renderOptions)}
                            </Grid>
                        )}
                    </Droppable>
                </DragAndDropOptionsContainer>
                <DragAndDropBucketContainer data-test-id="drag_and_drop_buckets" mt={5} sx={{ flexGrow: 1 }} display="flex" flexDirection="column" justifyContent="center">
                    <Box textAlign="center" mb={5}>
                        <Typography className="bucketInfoText">{configJSON.youMatterInfoTexts.dragAndDropBucketInfoText}</Typography>
                    </Box>
                    <Grid container spacing={10}>
                        {this.renderBuckets()}
                    </Grid>
                </DragAndDropBucketContainer>
            </DragDropContext>
        );
        // Customizable Area End
    }

}
// Customizable Area Start
const DragAndDropOptionsContainer = styled(Grid)({
    '&.dragAndDropOptionsContainer': {
        '& .optionsContainer': {
            '& .optionsWrapper': {
                borderRadius: '8px',
                padding: '8px',
                '&:has(.Mui-checked)': {
                    border: "0.75px solid #0056A3",
                },
                boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
                border: "0.2px solid #e3e3e3",
                background: "#FFF",
                '& .optionFormLabel': {
                    '&.MuiFormControlLabel-root': {
                        width: "100%",
                        justifyContent: "space-between",
                        marginLeft: "0px",
                    },
                    '& .radioLabel': {
                        display: "flex",
                        alignItems: "center",
                        '& .option': {
                            minHeight: "44px",
                            minWidth: "44px",
                            borderRadius: "8px",
                            backgroundColor: "#0056A3",
                            color: "white",
                            fontSize: "16px",
                            fontWeight: 600,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "20px",
                            fontFamily: "Poppins",
                        },
                        '& .optionContent': {
                            fontSize: "16px",
                            overflow: "hidden",
                            fontWeight: 500,
                            lineHeight: "24px",
                            display: "-webkit-box",
                            textOverflow: "ellipsis",
                            wordBreak: "break-word",
                            "-webkitLineClamp": 1,
                            "-webkitBoxOrient": "vertical",
                            "&:hover": {
                                wordBreak: "break-word",
                                "-webkitBoxOrient": "inherit",
                            },
                        },
                        '&.radioLabelList-true': {
                            '& .option': {
                                minHeight: "48px",
                                minWidth: "48px",
                            }
                        }
                    }
                }
            },
            '&.option-disabled': {
                '& .optionsWrapper': {
                    background: "#E7E5E4",
                    '& .option': {
                        background: "#A8A29E !important"
                    }
                }
            },
        }
    },
});

const DragAndDropBucketContainer = styled(Box)({
    '& .bucketContainer': {
        minHeight: 200
    },
    '& .bucketInfoText': {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: 'normal',
        color: "#94A3B8",
    },
    '& .bucketTitle': {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: "24px"
    },
    '& .choosedItems': {
        display: 'flex',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: '24px 0',
        gap: 24,
        '& .droppedItems': {
            width: '100%',
            padding: '0 0 0 24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& .droppedItemDelete': {
                minWidth: '50px',
                padding: 0,
                textTransform: 'capitalize',
                color: '#0056a3'
            },
            '& .droppedItemText': {
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '24px',
                color: '#0057A4'
            }
        }
    }
})
// Customizable Area End