import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";
import { setStorageData } from "../../../../../framework/src/Utilities";

let config = require("../../../../../framework/src/config");
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config");

// Customizable Area Start
interface SectionType{
  id: number,
  title: string,
  value: number
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

interface ErrorResponse {
  errors: string[];
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area Start
  // Customizable Area End
}
export interface S {
  // Customizable Area Start
  studentReportData: any;
  user_token: any;
  publishSuccess: boolean;
  publishFailure: boolean;
  appliedFilters: any;
  isLoading: boolean;
  page: number;
  successMessage: string
  yearFilterOption: any;
  courseFilterOption: any;
  schoolFilterOption: any;
  gradeFilterOption: any;
  sectionFilterOption: Array<SectionType>;
  yearFilterValue: any;
  courseFilterValue: any;
  schoolFilterValue: any;
  gradeFilterValue: any;
  sectionFilterValue: string;
  validationErrors: {
    yearFilterValue: boolean,
    courseFilterValue: boolean,
    schoolFilterValue: boolean,
    gradeFilterValue: boolean,
    sectionFilterValue: boolean,
  },
  sucessGenerateReport:boolean,
  // Customizable Area End
}
interface SS { id: any }

export default class TrainerStudentReportController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  GetIndexDataApiCallId: any;
  PublishApiCallId: any;
  postGenerateReportCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleReportRefresh = this.handleReportRefresh.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    this.state = {
      studentReportData: [],
      user_token: "",
      publishSuccess: false,
      publishFailure: false,
      isLoading: false,
      appliedFilters: {},
      yearFilterValue: '',
      courseFilterValue: '',
      schoolFilterValue: '',
      gradeFilterValue: '',
      sectionFilterValue: '',
      yearFilterOption: [],
      courseFilterOption: [],
      schoolFilterOption: [],
      gradeFilterOption: [],
      sectionFilterOption: [],
      page: 1,
      successMessage: "",
      validationErrors: {
        yearFilterValue: false,
        courseFilterValue: false,
        schoolFilterValue: false,
        gradeFilterValue: false,
        sectionFilterValue: false,
      },
      sucessGenerateReport: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }


  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorMsg = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (errorMsg) {
        console.log(errorMsg);
      }
      if (responseJson && !responseJson.errors) {
        this.ApiCallSuuccess(responseJson, apiRequestCallId);

      } else if (responseJson && responseJson.errors) {
        this.apiFailure(responseJson,apiRequestCallId)
      }
    }
  }

  ApiCallSuuccess(responseJson: any, apiRequestCallId: any) {
    if (apiRequestCallId === this.GetIndexDataApiCallId) {
      this.setState({
        studentReportData: responseJson
      }, () => this.getYearFilterOptions())
    }
    if (apiRequestCallId === this.PublishApiCallId) {
      if (responseJson.message === configJSON.StudentsReport.OnlyWhenMsg) {
        this.setState({
          publishFailure: true
        })
      } else {
        this.setState({
          publishSuccess: true,
          successMessage: responseJson.message.includes("already") ? configJSON.StudentsReport.AlreadyPublished : configJSON.StudentsReport.PublishedForAll
        })
      }
    }
    if(apiRequestCallId === this.postGenerateReportCallId){
      this.setState({sucessGenerateReport:true});
    }
  }

  apiFailure(responseJson:ErrorResponse,apiRequestCallId:string){
    if(apiRequestCallId === this.postGenerateReportCallId){
      alert(responseJson.errors[0])
      this.showAlert('Error',responseJson.errors[0])
    }
  };

  goTo = async (module: string, params: Object = {}, studentData?: any,) => {
    this.props.navigation.navigate(module, { ...params });
    await setStorageData("student_data", JSON.stringify(studentData));
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  getToken = () => {
    return localStorage.getItem('user_token')
  }

  closeModal = (modalType: string) => {
    if (modalType === configJSON.StudentsReport.Failure) {
      this.setState({ publishFailure: false })
    }
    if (modalType === configJSON.StudentsReport.Success) {
      this.setState({ publishSuccess: false })
    }
  }

  handleDropdownChange = (event: React.ChangeEvent<{ value: any, name: any }>) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({...prevState,[name]: value,validationErrors: {...prevState.validationErrors,[`${name}`]: false,},
    }));
  }

  handleClearFilter = (name: string) => {
    this.setState({ ...this.state, [name]: '' })
  }

  handlePublishClick = () => {
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.PublishApiCallId = apiRequest.messageId;
    let endPoint = configJSON.postPublishEndPoint;
    endPoint = this.getEndPoint(`${endPoint}`);
    endPoint = endPoint.replace("&", "?")
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.httpPostMethod,
      endPoint,
      header,
    );
  }

  //Calculate Filter Options
  getYearFilterOptions() {
    const yearFilterOptions = this.state.studentReportData?.filters?.academic_year.map((el: any) => {
      return { title: el, value: el, id: el };
    }) || [];

    const courseFilterOptions = this.state.studentReportData?.filters?.courses.map((el: any) => {
      return { ...el, value: el.id, id: el.id };
    }) || [];

    const schoolFilterOptions = this.state.studentReportData?.filters?.schools.map((el: any) => {
      return { ...el, value: el.id, id: el.id };
    }) || [];

    const gradeFilterOptions = this.state.studentReportData?.filters?.grades.map((el: any) => {
      return { ...el, value: el.id, id: el.id };
    }) || [];

    const sectionFilterSection = this.state.studentReportData?.filters?.sections.map((section:SectionType) => {
      return {...section, value: section.id , id:section.id}
    }) || [];


    this.setState({
      yearFilterOption: yearFilterOptions,
      courseFilterOption: courseFilterOptions,
      schoolFilterOption: schoolFilterOptions,
      gradeFilterOption: gradeFilterOptions,
      sectionFilterOption: sectionFilterSection
    })

  }

  handlePageChange(page: any) {
    this.setState({ page: page })
  }

  handleReportRefresh() {
    window.location.reload();
  }

  getEndPoint = (endPoint: string) => {
    if (this.state.courseFilterValue) {
      endPoint = `${endPoint}&course_id=${this.state.courseFilterValue}`
    }
    if (this.state.schoolFilterValue) {
      endPoint = `${endPoint}&school_id=${this.state.schoolFilterValue}`
    }
    if (this.state.gradeFilterValue) {
      endPoint = `${endPoint}&grade_id=${this.state.gradeFilterValue}`
    }
    if (this.state.sectionFilterValue) {
      endPoint = `${endPoint}&section_id=${this.state.sectionFilterValue}`
    }
    return endPoint
  }

  getStudentReportIndex() {
    this.setState({ studentReportData: [] })
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.GetIndexDataApiCallId = apiRequest.messageId;
    let endPoint = `${configJSON.StudentsReport.GetReportsEndPoint}?page=${this.state.page}`
    if (this.state.yearFilterValue) {
      endPoint = `${endPoint}&academic_year=${this.state.yearFilterValue}`
    }
    endPoint = this.getEndPoint(endPoint);
    this.makeApiCall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      endPoint,
      header
    );
  }

  async makeApiCall(
    uniqueApiCallID: string,
    Method: string,
    endpoint: string,
    Headers: any,
    Body?: any,
  ) {
    let FullURL = endpoint.indexOf("://") === -1 ? config.baseURL + "/" + endpoint : endpoint;
    let apiResponseMessage = new Message(getName(MessageEnum.RestAPIResponceMessage));
    apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceDataMessage), uniqueApiCallID);
    try {
      this.setState({ isLoading: true })
      let response = await fetch(FullURL, { method: Method.toUpperCase(), headers: Headers, body: Body })
      if (response.status === 401) {
        localStorage.removeItem('userDetails')
        this.goTo('LoginForm')
      }
      this.setState({ isLoading: false })
      let responseJson = await response.json();
      if (responseJson.data || responseJson.message) {
        this.setState({ isLoading: false })
      }
      apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceSuccessMessage), responseJson);
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      console.log("Api Error" + JSON.stringify(error));
      apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceErrorMessage), "An error has occuured. Please try again later.");
    }
    this.send(apiResponseMessage);
  }

  apiEditCall = async (data: APIPayloadType) => {
    let token = await localStorage.getItem('user_token')
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      "Authorization": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  onPressSignInCallID = async () => {
    let apiData = {
      "mind_mastery_course_id": this.state.courseFilterValue,
      "school_id": this.state.schoolFilterValue,
      "grade_id": this.state.gradeFilterValue,
      "section_id": this.state.sectionFilterValue
    }
    this.postGenerateReportCallId = await this.apiEditCall({
      contentType: "application/json",
      method: configJSON.dashboardPostApiMethod,
      endPoint: configJSON.StudentsReport.postGenerateReportEndPoint,
      body: apiData,
    });
    this.setState({yearFilterValue: '', courseFilterValue: '', schoolFilterValue: '', gradeFilterValue: '', sectionFilterValue: ''})
  }

  handleGenerateReport = async() => {
    if(this.validateForm()){
      this.onPressSignInCallID();
    }
  }

  validateForm() {
    let updateErrorStatus = {
      yearFilterValue: false,
      courseFilterValue: false,
      schoolFilterValue: false,
      gradeFilterValue: false,
      sectionFilterValue: false,
    }
    if (this.state.yearFilterValue === "") {
      updateErrorStatus.yearFilterValue = true
    }
    if (this.state.courseFilterValue === "") {
      updateErrorStatus.courseFilterValue = true
    }
    if (this.state.schoolFilterValue === "") {
      updateErrorStatus.schoolFilterValue = true
    }
    if (this.state.gradeFilterValue === "") {
      updateErrorStatus.gradeFilterValue = true
    }
    if (this.state.sectionFilterValue === "") {
      updateErrorStatus.sectionFilterValue = true
    }
    
    this.setState({ validationErrors: { ...updateErrorStatus } });
    const valuesArray = Object.values(updateErrorStatus);
    const hasValidationError = valuesArray.filter((element: boolean) => {
      return element === true
    })
    if (hasValidationError.length < 1) {
      return true
    } else return false;
  } 
  // Customizable Area End
}
