"use strict";


const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";

// Customizable Area Start
if(baseURL.includes('stage')){
    exports.appID = "24752232b8825994";
    exports.region = "in";
    exports.authKey = "b1fcf534397fdf9cf7308bed18ed6aa1aee4254a";
}else if(baseURL.includes('dev')){
    exports.appID = "247492420f01ba91";
    exports.region = "in";
    exports.authKey = "9e0b3d0e96ce63079785921ac141f38b867827a0";
}else if(baseURL.includes('uat')){
    exports.appID = "2417624c29a764e1";
    exports.region = "EU";
    exports.authKey = "4062ffe62672f5e15ef3d021c026721095d77983";
}else{
    exports.appID = "24538507c22b5fa7";
    exports.region = "in";
    exports.authKey = "b947d8802d94b52b3384d4b4429da13b4c4df865";
}
// Customizable Area End
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
