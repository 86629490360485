import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  copyWrite: {
    background:
      " linear-gradient(70deg, rgba(1,86,162,1) -36.53%, rgba(75,144,205,1) 66%, rgba(179,219,255,1) 146%)",
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    padding: "8px",
    textAlign: "center",
  },
  cwText: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#fff",
  },
}));

function CopyRightFooter() {
  const classes = useStyles();

  return (
    <footer className={classes.copyWrite}>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        className={classes.cwText}
      >
          © 2024 Hapchi Pvt Ltd, All Rights Reserved
      </Typography>
    </footer>
  );
}

export default CopyRightFooter;
