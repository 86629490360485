import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Message } from "../../../../../framework/src/Message";


let config = require("../../../../../framework/src/config");
// Customizable Area Start
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    isLoading: boolean;
    reportData: any;
    dropDownList: any;
    dropDownFilterValue: any;
    page: number;
    totalPages: number;
    // Customizable Area End
}
interface SS { }

export default class ChildsReportController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getDropdownDataApiCallId: string = "";
    getChildReportApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage)
        ];

        this.state = {
            isLoading: false,
            reportData: [],
            dropDownList: [],
            dropDownFilterValue: '',
            page: 1,
            totalPages: 1
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if (apiRequestCallId && responseJson) {
                if (!responseJson.errors) {
                    if (apiRequestCallId === this.getDropdownDataApiCallId) {
                        this.setState({ dropDownList: responseJson.data })
                    } else if (apiRequestCallId === this.getChildReportApiCallId) {
                        this.setState({ reportData: responseJson.data, totalPages: responseJson.page_options?.total_pages })
                    }
                } else {
                    this.parseApiErrorResponse(responseJson);
                }
            }
            if (errorReponse) {
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
    }

    goTo = (module: string, params: Object = {}) => {
        if (this.props.navigation) {
            this.props.navigation.navigate(module, { ...params });
        }
    };

    getToken = () => {
        return localStorage.getItem("user_token");
    };

    handlePage = (page: number) => {
        this.setState({ page: page });
    }

    handleFilterChange = (event: React.ChangeEvent<{ value: any, name: any }>) => {
        const { value } = event.target;
        this.setState({ dropDownFilterValue: value })
    }

    handleClear = () => {
        this.setState({ dropDownFilterValue: '' })
    }

    getDropdownData() {
        const header = {
            "Content-Type": configJSON.programSchedulerContentType,
            Authorization: this.getToken()
        };
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getDropdownDataApiCallId = apiRequest.messageId;
        this.apiCall(
            apiRequest.messageId,
            configJSON.programSchedulerGetApiMethod,
            configJSON.YouMatterTexts.getChildrenApiEndPoint,
            header
        );
    }

    getReportData() {
        const header = {
            "Content-Type": configJSON.programSchedulerContentType,
            Authorization: this.getToken()
        };
        let endpoint = `${configJSON.YouMatterTexts.ParentShowReportEndPoint}?page=${this.state.page}`;
        if (typeof this.state.dropDownFilterValue !== 'string') {
            endpoint = `${configJSON.YouMatterTexts.ParentShowReportEndPoint}?id=${this.state.dropDownFilterValue}&page=${this.state.page}`
        }
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getChildReportApiCallId = apiRequest.messageId;
        this.apiCall(
            apiRequest.messageId,
            configJSON.programSchedulerGetApiMethod,
            endpoint,
            header
        );
    }

    async apiCall(
        apiCallId: string,
        method: string,
        endpoint: string,
        headers: any,
        body?: any
    ) {
        let fullURL = endpoint.indexOf("://") === -1 ? config.baseURL + "/" + endpoint : endpoint;
        let responseMessage = new Message(getName(MessageEnum.RestAPIResponceMessage));
        responseMessage.addData(getName(MessageEnum.RestAPIResponceDataMessage), apiCallId);
        try {
            this.setState({ isLoading: true });
            let response = await fetch(fullURL, {
                method: method.toUpperCase(), headers: headers, body: body
            });
            if (response.status === 401) { this.goTo("LoginForm"); }
            
            let responseJson = await response.json();
            if (responseJson) { this.setState({ isLoading: false }) }

            responseMessage.addData(getName(MessageEnum.RestAPIResponceSuccessMessage), responseJson);
            responseMessage.addData(getName(MessageEnum.RestAPIResponceSuccessMessage), responseJson);
        } catch (error) {
            runEngine.debugLog("RestApiClient Error", error);
            responseMessage.addData(getName(MessageEnum.RestAPIResponceErrorMessage), "An error has occuured. Please try again later.");
        }
        this.send(responseMessage);
    }
    // Customizable Area End
}
