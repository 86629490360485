export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const step1Active = require("../assets/step-1-active.png");
export const step2Active = require("../assets/step-2-active.png");
export const step2Inactive = require("../assets/step-2-inactive.png");
export const step3Active = require("../assets/step-3-active.png");
export const step3Inactive = require("../assets/step-3-inactive.png");
export const step4Active = require("../assets/step-4-active.png");
export const step4Inactive = require("../assets/step-4-inactive.png");
export const logo = require("../assets/hapchiMainLogo.svg");
export const left = require("../assets/topBarLeft.png");
export const right = require("../assets/topBarRight.png");
export const mailVerify = require("../assets/mail-verify.svg");
export const authentication = require("../assets/authentication.svg");
export const successfully = require("../assets/successfully.svg");
