import React, { useState } from "react";
interface Props {
  children: string;
  sliceLength: number;
  textColor?: string;
}
const ReadMore = ({ children, sliceLength, textColor = '#052B62' }: Props) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setIsReadMore(!isReadMore);
  };
  return (
    <span className="text">
      {isReadMore ? text.slice(0, sliceLength) : text}
      <span onClick={toggleReadMore} style={{ color: textColor, cursor: 'pointer' }}>
        {isReadMore ? "...Read more" : "...Show less"}
      </span>
    </span>
  );
};
export default ReadMore;
