import React from "react";
import { Box, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles,Theme } from "@material-ui/core/styles";

import GenericWebButton from "./GenericWebButton.web";

interface Props {
    background?: string;
    title: string;
    content: string;
    circleColor?: string;
    buttonBgColor:string;
    buttonTextColor:string;
    titleColor?:string;
    contentColor?:string;
    imageUrl?:string;
}
const useStyles = makeStyles<Theme, Props>((theme) => ({
    upper_circle: {
        backgroundColor: (props)=>props.circleColor ?? '#EFCC53',
        width: '36px !important',
        height: '50px',
        position: 'absolute',
        top: '-19px',
        left: '-23px',
        borderRadius: '100%',
    },
    buttom_circle: {
        backgroundColor: (props)=>props.circleColor ?? '#EFCC53',
        width: '36px !important',
        height: '50px',
        position: 'absolute',
        bottom: '-15px',
        right: '-24px',
        borderRadius: '100%',
    },
    topSpace: {
        marginTop: '45px',
        position: 'relative',
    },
    hapchiCard: {
        background: (props) => props.background ?? '#0A539E',
        padding: '10px !important ',
        textAlign: 'center',
        borderRadius: '12px',
        position: 'relative',
        overflow: 'hidden',
        maxHeight:'280px',
        height:'280px',
        [theme.breakpoints.down("sm")]: {
            maxWidth: '100% !important',
            
        }
    },
    circle: {
        width: '96px',
        height: '96px',
        borderRadius: '100%',
        margin: 'auto',
        background: '#fff',
    },
    content:{
        marginTop:'24px',
    },
    imgBox:{
        display:'flex',
        justifyContent:'center',
    },
    heading: {
        fontWeight: 700,
        color: (props) => props.titleColor ?? '#fff',
        margin: '8px 0',
        fontSize:'14px',
    },
    paragraph: {
        color: (props) => props.contentColor ?? '#fff',
        fontSize: '12px',
        fontWeight: 400,
        height:'84px',
        marginBottom: '22px',
    },
}));
const content = {
    padding: "0 30px" ,
    fontWeight: 500,
    height: "36px",
    // text:"Know more"
  }

const ReviewCard = (props: Props) => {
    const classes = useStyles(props);
    return <>
          <Box className={classes.hapchiCard}>
                <Box className={classes.upper_circle}></Box>
                <Box className={classes.buttom_circle}></Box>
                <Box className={classes.imgBox} >
                    <img src={props.imageUrl} width="96px"/>
                </Box>
                <Box className={classes.content}>
                    <Typography className={classes.heading}>{props.title}</Typography>
                    <Typography className={classes.paragraph}>{props.content}</Typography>
                    {/* <Box sx={{paddingBottom:'30px'}}>
                    <GenericWebButton {...content} color={props.buttonTextColor} background={props.buttonBgColor} />
                    </Box> */}
                </Box>
            </Box>
        
    </>
}

export default ReviewCard;