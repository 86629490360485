import React from 'react';
// Customizable Area Start
import DashboardController from "./DashboardController";
import { Grid, Box, Button, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { mpowerIcon, profile, sandClockIcon } from './assets';
import { ArrowBackIosRounded, ArrowForwardIosRounded } from '@material-ui/icons';
import ReadMore from '../../../components/src/ReadMore';
// Customizable Area End
const configJSON = require("./config.js");
// Customizable Area Start
const useStyles = makeStyles<Theme, any>((theme: Theme) => ({
    userInfoWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    role: {
        color: '#3b3b3b80',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
    profileImage: {
        height: '120px',
        width: '120px',
        borderRadius: "50%",
        marginBottom: '8px',
        '@media(max-width:767px)': {
            height: '90px',
            width: '90px',
        }
    },
    userName: {
        color: '#0056A3',
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: 500,
        '@media(max-width:767px)': {
            fontSize: '18px',
        },
        '@media(max-width:600px)': {
            fontSize: '16px'
        }
    },
    descriptionText: {
        color: '#3b3b3bcc',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
    },
    profileImgWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '8px'
    },
    userNameWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 5px 0 16px',
        '@media(max-width:767px)': {
            fontSize: '14px'
        }
    },
    topCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '8px',
        padding: '16px',
        background: '#FDFDFD',
        border: '0.2px solid #e2e2e2',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
        '@media(max-width:500px)': {
            flexDirection: 'column',
        }
    },
    noOfProgram: {
        color: '#0056A3',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 500,
        textAlign: 'center',
    },
    programNumber: {
        color: '#0056A3',
        fontFamily: 'Poppins',
        fontSize: '68px',
        fontWeight: 600,
    },
    noOfProgramWrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '10px',
        height: '100%',
        borderRadius: '8px',
        background: '#FDFDFD',
        border: '0.2px solid #e2e2e2',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
        '@media(max-width:500px)': {
            padding: '10px 0',
            width: '100%',
            marginTop: '20px'
        }
    },
    onGoingProgramWrapper: {
        padding: '24px',
        borderRadius: '8px',
        background: '#FDFDFD',
        border: '0.2px solid #e2e2e2',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
    },
    leftRightBtn: {
        "&.MuiButton-root": {
            width: '32px',
            height: '32px',
            borderRadius: '8px',
            backgroundColor: '#0056A3',
            minWidth: 'auto'
        },
        '& .MuiButton-label': {
            color: 'white',
            fontFamily: "Poppins",
        },
        '&.MuiButton-root.Mui-disabled': {
            backgroundColor: '#0056a380'
        }
    },
    onGoingProgramTitle: {
        color: '#0056A3',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: 600,
        '@media(max-width:767px)': {
            fontSize: '18px'
        }
    },
    onGoingProgramTopActionBtnWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: props => props.navigationMb ? props.navigationMb : '30px'
    },
    leftRightActionBtnWrapper: {
        display: 'flex',
        width: '80px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    courseCard: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: props => props.courseCardMb ? props.courseCardMb : '30px',
        minHeight: '142px',
        '@media(max-width:767px)': {
            flexDirection: 'column'
        }
    },
    courseImgWrapper: {
        marginRight: '20px',
        '@media(max-width:767px)': {
            marginRight: "0"
        }
    },
    courseImg: {
        width: '260px',
        borderRadius: '8px',
        cursor: 'pointer',
        '@media(max-width:767px)': {
            marginBottom: '20px',
            width: '100%'
        }
    },
    courseDescription: {
        color: '#3B3B3B',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400
    },
    courseInfoWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    infoBar: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        background: "#FFF",
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
        padding: "10px 16px",
        border: '0.2px solid #e2e2e2',
        '@media(max-width:767px)': {
            justifyContent: 'center',
        }
    },
    titleValueWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '@media(max-width:767px)': {
            gap: 10,
            flexDirection: 'column',
            width: 'auto',
            marginLeft: '10px'
        }
    },
    infoTitle: {
        color: '#3B3B3B',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        '@media(max-width:767px)': {
            fontSize: '14px',
        }
    },
    infoIcon: {
        width: '24px'
    },
    iconInfoWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tableWrapper: {
        overflowX: 'auto',
        maxHeight:"294px",
        maxWidth: 'calc(100vw - 438px)',
        '@media(max-width:960px)': {
            maxWidth: 'calc(100vw - 177px)',
        },
        '@media(max-width:767px)': {
            maxWidth: 'calc(100vw - 180px)',
        },
        '@media(max-width:600px)': {
            maxWidth: 'calc(100vw - 134px)',
        }
    },
    location: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        '@media(max-width:767px)': {
            fontSize: '14px',
        }
    },
    childBtnWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    childBtnActive: {
        "&.MuiButton-root": {
            backgroundColor: '#0056A3',
            border: '1px solid #0056A3',
            color: 'white',
            marginRight: '10px',
            marginBottom: '20px'
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            fontSize: '14px',
            fontWeight: "500",
            lineHeight: '24px',
            color: "white",
            fontFamily: "Poppins",
        }
    },
    childBtn: {
        "&.MuiButton-root": {
            marginRight: '10px',
            border: '1px solid #0056A3',
            backgroundColor: 'white',
            marginBottom: '20px'
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            fontSize: '14px',
            fontWeight: "500",
            lineHeight: '24px',
            color: "#0056A3",
            fontFamily: "Poppins",
        }
    },
    emailKey: {
        color: '#3b3b3b',
        fontFamily: 'Poppins',
        fontSize: '18px',
        fontWeight: 600,
        display: 'inline',
        '@media(max-width:767px)': {
            fontSize: '16px'
        },
        '@media(max-width:500px)': {
            fontSize: '14px'
        }
    },
    emailWrapper: {
        marginBottom: '20px',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    email: {
        color: '#3B3B3B',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: 400,
        '@media(max-width:500px)': {
            fontSize: '12px'
        }
    },
    tableHead: {
        '&.MuiGrid-container': {
            borderRadius: '8px',
            background: '#0056A3',
            fontFamily: 'Poppins',
            color: 'white',
            fontSize: '15px',
            fontWeight: 600,
            justifyContent: 'space-between',
            lineHeight: '27px',
            marginBottom: '16px',
            padding: '15px 24px',
            '@media(max-width:960px)': {
                minWidth: '767px'
            },
        }
    },
    tableRow: {
        '&.MuiGrid-container': {
            borderRadius: '8px',
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
            justifyContent: 'space-between',
            border: '1px solid #e3e3e3',
            padding: '12px 24px',
            marginBottom: '8px',
            '@media(max-width:960px)': {
                minWidth: '767px'
            },
        }
    },
    headerCell: {
        '&.MuiGrid-item': {
            margin: 'auto',
            fontSize: '15px',
            padding: '0 10px',
        }
    },
}))

export const renderIcon = (name: string) => {
    if (name === 'clock') {
        return sandClockIcon;
    } else {
        return mpowerIcon;
    }
}


interface CourseNavigationProps {
    title: string;
    isRightDisabled: boolean;
    isLeftDisabled: boolean;
    mb?: string;
    handleLeftRightNavigation: (type: string) => void;
}

export const CourseNavigation = (props: CourseNavigationProps) => {
    const classes = useStyles({ navigationMb: props.mb });
    const { title, isLeftDisabled, isRightDisabled, handleLeftRightNavigation } = props;
    return <Box className={classes.onGoingProgramTopActionBtnWrapper} >
        <Typography className={classes.onGoingProgramTitle}>{title}</Typography>
        <Box className={classes.leftRightActionBtnWrapper}>
            <Button className={classes.leftRightBtn} data-test-id='left' disabled={isLeftDisabled} onClick={() => handleLeftRightNavigation('dec')} > <ArrowBackIosRounded /></Button>
            <Button className={classes.leftRightBtn} data-test-id='right' disabled={isRightDisabled} onClick={() => handleLeftRightNavigation('inc')}> <ArrowForwardIosRounded /> </Button>
        </Box>
    </Box>
}

export const InfoBar = (props: any) => {
    const classes = useStyles({});
    if(props?.isSchool){
        return null;
    }
    return <Box className={classes.infoBar} data-test-id='box' >
        {props.icon && <img className={classes.infoIcon} src={props.icon} alt="" />}
        <Box className={classes.titleValueWrapper}>
            <Box className={classes.iconInfoWrapper}>
                <Typography className={classes.infoTitle} style={{ marginBottom: '0' }} >{props.infoTitle}</Typography>
            </Box>
            {props.city && <Typography className={classes.location}>{props.city}</Typography>}
            <Typography className={classes.infoTitle}>{props.value}</Typography>
        </Box>
    </Box>
}

interface DashboardCourseCardProps {
    courseThumbnail: string;
    courseTitle: any;
    courseDescription: string;
    onClick?: () => void;
    mb?: string;
}

export const DashboardCourseCard = (props: DashboardCourseCardProps) => {
    const classes = useStyles({ courseCardMb: props.mb });
    const { courseThumbnail, courseTitle, courseDescription } = props;
    return <Box className={classes.courseCard} data-test-id='box' onClick={props.onClick}>
        <Box className={classes.courseImgWrapper}>
            <img className={classes.courseImg} src={courseThumbnail} alt="" />
        </Box>
        <Box className={classes.courseInfoWrapper}>
            <Typography className={classes.onGoingProgramTitle} style={{ cursor: 'pointer' }} >{courseTitle}</Typography>
            {courseDescription && <Typography className={classes.courseDescription}>
                {courseDescription?.length < 150 ? courseDescription : <ReadMore sliceLength={150} textColor='#3B3B3B'>{courseDescription}</ReadMore>}
            </Typography>}
        </Box>
    </Box>
}

interface ProgramList {
    id: string;
    type: string;
    attributes: {
        course_name: string;
        course_description: string;
        notes: string;
        school_name: string;
        grade: string;
        city: string;
        program_duration: string;
        program_progress: any;
        status: string;
        date: string;
        thumbnail_url: string;
    };
}

interface School {
    id: string;
    type: string;
    attributes: {
        school_name: string;
        full_name: string;
        image_url: string;
    };
}

interface SchoolData {
    school: School;
    ongoing_programs_count: number;
}


interface ParentData {
    id: string;
    type: string;
    attributes: {
        name: string;
        image_url: string;
    };
}

interface SchoolDashboardPageProps {
    states: any;
    handleLeftRightBtn: (type: string) => void;
    handlePage: (page: number) => void;
    handleNavigationInSchoolParent: () => void;
}

export const SchoolDashboardPage = (props: SchoolDashboardPageProps) => {
    const classes = useStyles({});
    const user = JSON.parse(`${localStorage.getItem(configJSON.Dashboard.UserDetails)}`)
    const profileImage = user?.attributes?.image_url;
    const profilePic = profileImage || profile;
    const tableHeader = ["Course Name", "Grade", "Date", "Status"];
    const programList: ProgramList[] = props.states.onGoingProgramsList;
    const programs = props.states.onGoingPrograms;
    const attributes = programs?.[props.states.ongoingProgramIndex]?.attributes;
    const schoolData: SchoolData = props.states.schoolDetails;
    const schoolAttributes = schoolData.school?.attributes;
    let role = user?.attributes?.role;
    const isSchool = role === configJSON.UserRole.School;
    let name = schoolAttributes?.full_name;
    const childrens = props.states.parentsChildrenOnPrograms?.map((element: any, index: any) => { return { ...element.attributes, index: index + 1 } });
    const currentChild = childrens?.[props.states.page - 1];
    const childrenEmail = currentChild?.email;
    const childrenPrograms = currentChild?.mind_mastery_courses;
    const childProgramAttributes = childrenPrograms?.[props.states.ongoingProgramIndex]?.attributes;
    let courseThumbnail = attributes?.thumbnail_url;
    let courseTitle = attributes?.course_name;
    let courseDescription = attributes?.course_description;
    let schoolName = attributes?.school_name;
    let city = attributes?.city;
    let section = attributes?.grade;
    let performanceBarName = configJSON.Dashboard.ProgramDuration;
    let performanceBarValue = `${attributes?.program_duration} Min`;
    let secondCondtionforDisable = programs?.length === 0;
    let compareCondtionForRightButton = programs?.length - 1;
    let noPrograms = configJSON.Dashboard.NoOngoingProgramsFound;
    let programCardTitle = configJSON.Dashboard.OngoingPrograms;
    if (!isSchool) {
        name = (props.states.parentDetails as ParentData).attributes?.name;
        courseThumbnail = childProgramAttributes?.thumbnail;
        courseTitle = childProgramAttributes?.title;
        courseDescription = childProgramAttributes?.description;
        schoolName = currentChild?.school_name;
        city = currentChild?.school_city;
        section = currentChild?.section_name;
        performanceBarName = configJSON.Dashboard.MPower;
        performanceBarValue = `${currentChild?.mpower_checks_percentage} %`;
        secondCondtionforDisable = childrenPrograms?.length === 0;
        compareCondtionForRightButton = childrenPrograms?.length - 1;
        noPrograms = configJSON.Dashboard.NoChildrenActivity;
        programCardTitle = configJSON.Dashboard.ChildrensActivity;
        role = configJSON.Dashboard.Parent;
    }

    const isLeftDisabled = props.states.ongoingProgramIndex === 0 || secondCondtionforDisable;
    const isRightDisabled = props.states.ongoingProgramIndex === compareCondtionForRightButton || secondCondtionforDisable;
    return <>
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box className={classes.topCard}>
                        <Box className={classes.userInfoWrapper}>
                            <Box className={classes.profileImgWrapper}>
                                <img className={classes.profileImage} src={profilePic} alt="" />
                                <Typography className={classes.role}>{role}</Typography>
                            </Box>
                            <Box className={classes.userNameWrapper}>
                                <Typography className={classes.userName}>Hello, {name} </Typography>
                                {isSchool && <Typography className={classes.descriptionText}>{schoolAttributes?.school_name}</Typography>}
                            </Box>
                        </Box>
                        {isSchool && <Box className={classes.noOfProgramWrapper}>
                            <Typography className={classes.noOfProgram}>{configJSON.Dashboard.NumberOfPrograms}</Typography>
                            <Typography className={classes.programNumber}>{schoolData?.ongoing_programs_count}</Typography>
                        </Box>}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box className={classes.onGoingProgramWrapper}>
                        <CourseNavigation
                            mb={'16px'}
                            data-test-id='course-navigation'
                            title={programCardTitle}
                            handleLeftRightNavigation={props.handleLeftRightBtn}
                            isLeftDisabled={isLeftDisabled}
                            isRightDisabled={isRightDisabled}
                        />
                        {!isSchool && <>
                            <Box className={classes.childBtnWrapper}>
                                {childrens?.map((element: any) => {
                                    return <Button
                                        key={element}
                                        data-test-id='childBtn'
                                        onClick={() => props.handlePage(element.index)}
                                        className={childrens[element.index - 1].index === props.states.page ? classes.childBtnActive : classes.childBtn}>
                                        {childrens[element.index - 1].name}
                                    </Button>
                                })}
                            </Box>
                            <Box className={classes.emailWrapper}>
                                <Typography className={classes.emailKey}>Email ID : </Typography> &nbsp; <span className={classes.email}>{childrenEmail}</span>
                            </Box>
                        </>}
                        {!secondCondtionforDisable ? <>
                            <DashboardCourseCard data-test-id='dashboard-course-card' onClick={props.handleNavigationInSchoolParent} courseTitle={courseTitle} courseThumbnail={courseThumbnail} courseDescription={courseDescription} />
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <InfoBar
                                        infoTitle={schoolName}
                                        value={`Section: ${section}`}
                                        city={city}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InfoBar
                                        isSchool={isSchool}
                                        icon={renderIcon(isSchool ? 'clock' : 'mpower')}
                                        infoTitle={performanceBarName}
                                        value={performanceBarValue} />
                                </Grid>
                            </Grid>
                        </> : noPrograms}
                    </Box>
                </Grid>
                {isSchool && <Grid item xs={12}>
                    <Box className={classes.onGoingProgramWrapper}>
                        <Typography className={classes.onGoingProgramTitle} style={{ marginBottom: '16px' }}>{configJSON.Dashboard.OngoingProgramsList}</Typography>
                        <Box className={classes.tableWrapper}>
                            <Grid container className={classes.tableHead}>
                                {tableHeader.map((element: any) => (<Grid key={element} item xs={true} className={classes.headerCell} >{element}</Grid>))}
                            </Grid>
                            <Grid>
                                {programList?.length > 0 ? programList.map((row: any) => {
                                    const attributes = row.attributes;
                                    return <Grid key={row.id} container className={classes.tableRow}>
                                        <Grid item xs={true} className={classes.headerCell}>{attributes.course_name}</Grid>
                                        <Grid item xs={true} className={classes.headerCell}>{attributes.grade}</Grid>
                                        <Grid item xs={true} className={classes.headerCell}>{attributes.date}</Grid>
                                        <Grid item xs={true} className={classes.headerCell}>{attributes.status}</Grid>
                                    </Grid>
                                }) : configJSON.Dashboard.NoScheduledUpcomingOrOngoingPrograms}
                            </Grid>
                        </Box>
                    </Box>
                </Grid>}
            </Grid>
        </Box>
    </>
}
// Customizable Area End
export default class SchoolDashboard extends DashboardController {
    // Customizable Area Start
    async componentDidMount() {
        const user = JSON.parse(`${localStorage.getItem('userDetails')}`)
        if (user?.attributes?.role === 'School') {
            this.getSchoolDetails();
            this.getSchoolOngoingPrograms();
            this.getSchoolOnGoingProgramsList();
        } else {
            this.getParentDetails();
            this.getChildrensOnGoingPrograms();
        }
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        return <>
            <SchoolDashboardPage
                handleNavigationInSchoolParent={this.handleNavigationInSchoolParent}
                data-test-id='school-page'
                states={this.state}
                handlePage={this.handlePage}
                handleLeftRightBtn={this.handleLeftRightNavigation}
            />
        </>
        // Customizable Area End
    }
}